import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { notify } from "../Components/CustomNotifications";
import { space } from "../Config/theme";
import { OutboundTemplate } from "../Containers/TemplateSettings";
import styles from "../Containers/styles/TemplateSettingsStyle";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { channelTypes } from "../Utils/Types";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";

const api = Api.create();
const outboundChannels = [channelTypes.LINKEDIN, channelTypes.EMAIL];

type AddEditOutboundTemplateProps = {
  publicationId: string;
  selectedTemplate?: OutboundTemplate;
  closeDialog: (reload?: boolean) => void;
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

type AddEditOutboundTemplateState = {
  selectedTemplateId: string;
  role: string;
  template: string;
  outboundLabel: string;
  templateChannel: channelTypes.LINKEDIN | channelTypes.EMAIL;
  templatesLoading: boolean;
};

class AddEditOutboundTemplate extends React.Component<
  AddEditOutboundTemplateProps,
  AddEditOutboundTemplateState
> {
  constructor(props: AddEditOutboundTemplateProps) {
    super(props);

    this.state = {
      selectedTemplateId: props.selectedTemplate?._id || "",
      role: props.selectedTemplate?.role || "",
      template: props.selectedTemplate?.template || "",
      outboundLabel: props.selectedTemplate?.label || "",
      templateChannel: props.selectedTemplate?.channel || channelTypes.LINKEDIN,
      templatesLoading: false
    };
  }

  onRoleChange = (e: any) => {
    this.setState({ role: e.target.value });
  };

  onTemplateChange = (e: any) => {
    this.setState({ template: e.target.value });
  };

  onOutboundLabelChange = (e: any) => {
    this.setState({ outboundLabel: e.target.value });
  };

  onTemplateChannelChange = (e: any) => {
    this.setState({ templateChannel: e.target.value });
  };

  saveOrUpdateTemplate = () => {
    const {
      role,
      template,
      outboundLabel,
      templateChannel,
      selectedTemplateId
    } = this.state;

    if (!outboundLabel) {
      notify.show("Please enter label", "error");
      return;
    }

    if (!role) {
      notify.show("Please enter role", "error");
      return;
    }
    if (!template?.trim() || !templateChannel) {
      notify.show("Please enter template and channel", "error");
      return;
    }

    if (selectedTemplateId) {
      let editedTemplate: OutboundTemplate = {
        _id: selectedTemplateId,
        template: template?.trim(),
        channel: templateChannel,
        role,
        label: outboundLabel
      };

      this.saveTemplate(editedTemplate);
    } else {
      let newTemplate: OutboundTemplate = {
        template: template?.trim(),
        channel: templateChannel,
        role,
        label: outboundLabel
      };

      this.saveTemplate(newTemplate);
    }
  };

  saveTemplate = (template: OutboundTemplate) => {
    const { publicationId } = this.props;

    this.setState({ templatesLoading: true });

    api.saveOutboundTemplate(publicationId, template, (res) => {
      this.setState({ templatesLoading: false });

      if (res.status !== 200) {
        notify.show(
          res.status === 400 ? res.data : "Error saving outbound template",
          "error"
        );
      } else {
        this.props.closeDialog(true);
      }
    });
  };

  render() {
    const {
      role,
      template,
      selectedTemplateId,
      outboundLabel,
      templateChannel,
      templatesLoading
    } = this.state;

    return (
      <StyledDialog
        open={true}
        title={selectedTemplateId ? "Update Template" : "Add Template"}
        body={
          <div style={{ padding: `0 ${space.LARGE}` }}>
            <div style={{ marginBottom: space.MEDIUM }}>
              <Typography
                variant="bodym"
                paragraph
                style={{ marginBottom: space.SMALL }}
              >
                Label
              </Typography>

              <StyledInput
                autoFocus
                type="text"
                size="large"
                name="label"
                fullWidth
                placeholder="Product Introduction"
                value={outboundLabel}
                onChange={this.onOutboundLabelChange}
              />
            </div>

            <div style={{ marginBottom: space.MEDIUM }}>
              <Typography
                variant="bodym"
                paragraph
                style={{ marginBottom: space.SMALL }}
              >
                Role
              </Typography>

              <StyledInput
                type="text"
                size="large"
                name="label"
                fullWidth
                placeholder="COO"
                value={role}
                onChange={this.onRoleChange}
              />
            </div>

            <div style={{ marginBottom: space.MEDIUM }}>
              <Grid
                container
                direction="column"
                style={{ marginBottom: space.SMALL }}
              >
                <Grid item xs={12}>
                  <Typography variant="bodym">Template</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="bodys"
                    style={{
                      color: colors.fontSecondary
                    }}
                  >
                    {`Supports variables {{first_name}}, {{company}}, {{observation}}`}
                  </Typography>
                </Grid>
              </Grid>
              <StyledInput
                type="textarea"
                multiline={true}
                rows={6}
                size="large"
                name="template"
                fullWidth
                value={template}
                onChange={this.onTemplateChange}
              />
            </div>

            <div style={{ marginBottom: space.MEDIUM }}>
              <Typography
                variant="bodym"
                paragraph
                style={{ marginBottom: space.SMALL }}
              >
                Channel
              </Typography>

              <StyledSelect
                name="channel"
                fullWidth
                value={templateChannel}
                size="medium"
                onChange={this.onTemplateChannelChange}
              >
                {Object.values(outboundChannels).map((channel, index) => {
                  return (
                    <StyledMenuItem value={channel} key={channel}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography variant="bodym">{channel}</Typography>
                        </Grid>
                      </Grid>
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </div>
          </div>
        }
        successButtonName={selectedTemplateId ? "Update" : "Add"}
        successCallback={this.saveOrUpdateTemplate}
        showSuccessButtonLoading={templatesLoading}
        cancelCallback={() => this.props.closeDialog()}
      />
    );
  }
}

export default withRouter(withStyles(styles)(AddEditOutboundTemplate));
