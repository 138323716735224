// @ts-nocheck
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import qs from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InserthotoIcon from "@mui/icons-material/InsertPhoto";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import { ReactComponent as Like } from "../Images/linkedin_like.svg";
import { ReactComponent as Love } from "../Images/linkedin_love.svg";
import { ReactComponent as Funny } from "../Images/linkedin_funny.svg";
import { ReactComponent as Insight } from "../Images/linkedin_insight.svg";
import { ReactComponent as Support } from "../Images/linkedin_support.svg";
import { ReactComponent as Celebrate } from "../Images/linkedin_celebrate.svg";
import { uuid } from "uuidv4";
import {
  Alert,
  Autocomplete,
  Avatar,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Link,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Tooltip,
  FormControl,
  ListItemIcon,
  Typography
} from "@mui/material";
import firebase from "firebase/app";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";
import validator from "validator";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import { notify } from "../Components/CustomNotifications";
import LinkedInAutoComplete from "../Components/LinkedInAutoComplete";
import ImagePicker from "../Components/ImagePicker";
import Loading from "../Components/Loading";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import {
  convertLinkedInPostTextToPreviewText,
  getShortVersionOfText,
  getTextLengthOfHTML,
  handleSingular,
  truncateStringAndAddEllipses
} from "../Utils/UserUtils";
import { uploadBlobImage, uploadFile, uploadMp4Video } from "../Utils/Storage";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  datePickerDefault,
  LINKEDIN_CHAR_LIMIT,
  LINKEDIN_COMMENT_CHAR_LIMIT,
  mediaFileType,
  pageAccess,
  postStatus,
  socialMediaType,
  socialMediaStatus,
  generateSocialPostStatus,
  URL_REPLACE_TEXT,
  LinkedInPermissionEnums,
  linkedInReactions,
  channelTypes,
  linkedinAutoLikesPostTimeTypes
} from "../Utils/Types";
import {
  formatNumber,
  getQueryParamsFromURL,
  getScheduleDateFromURL,
  getSiteURL,
  getVideoFileMetaData,
  hideOtherChatWidgets,
  isAccessDisabled,
  showOtherChatWidgets
} from "../Utils/UserUtils";
import styles from "./styles/LinkedInShareStyle";
import StyledSwitch from "../design/components/StyledSwitch";
import CustomFlatPicker from "../Components/CustomFlatPicker";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledUserChip from "../design/components/StyledUserChip";
import images from "../Themes/Images";
import LinkedInPreview from "../Components/LinkedInPreview";
import InfoIcon from "../Components/InfoIcon";
import { space } from "../Config/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AIInstructionsDialog from "../Components/AIInstructionsDialog";
import readingTime from "reading-time";
import { features, hasAccess } from "../Utils/AccessUtils";
import LinkedInInputBox from "../Components/LinkedInInputBox";
import StyledAvatar from "../design/components/StyledAvatar";
import AIGeneratePost, { Sample } from "../Components/AIGeneratePost";
import AutoLikePostTimingSelect from "../Components/AutoLikePostTimingSelect";
import LinkedInVideoToPostConfirmDialog from "../Components/LinkedInVideoToPostConfirmDialog";
import { withRouter } from "react-router";
import StyledAccordion from "../design/components/StyledAccordion";
import LinkedInTemplateDropdown from "../Components/LinkedInTemplateDropdown";
import LinkedInPostDraftTimer from "../design/components/LinkedInPostDraftTimer";
import { renderCallMetaData } from "./SocialSellingDemo";
const _ = require("lodash");

const Page404 = React.lazy(() => import("../Containers/Page404"));

const api = API.create();

export const AUTOCOMPLETE_ALL_OPTION_TEXT = "All";

const DEFAULT_INDEX = -1;

export type LinkedInAccount = {
  userId: string;
  name: string;
  connected: boolean;
};

type account = {
  userId: string;
  name: string;
  email: string;
  profilePicture: string;
};

interface ReferenceContent {
  url: string;
  content: string;
}

export interface SalesCallInsightsData {
  body: string;
  referenceTemplate: string;
  description: string;
  relatedContentPrompt: string;
  insightQuestion: string;
  repAnswer: string;
  referenceContent: ReferenceContent[];
}

const linkedInContentType = {
  POST_BODY: "body",
  POST_COMMENT: "comments",
  POST_AUTOCOMMENT_TEMPLATE: "autoCommentTemplate"
};
const POST_TO = "account";
const PUBLISH_TYPE = "publishType";
const publishTypes = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later",
  NEXT_AVAILABLE: "next_available"
};

const NEWLINE_REGEX = /\n/g;
const BR_TAG = "<br>";
const URL_REGEX = /(https?:\/\/[^\s]+)/g;
/**  Used to match HTML tags in a text. Using this to cleanup body in checkForLinks function.
 * < and >: These angle brackets are literal characters that match themselves. They represent the opening and closing tags in HTML.
 * [^>]: This is a negated character class that matches any character except the > character. The ^ inside the square brackets means negation.
 * +: This quantifier matches one or more occurrences of the preceding pattern, which in this case is [^>]. It ensures that we match one or more characters that are not >.
 */
const HTML_REGEX = /<[^>]+>/g;

const reactionTypes = [
  { name: linkedInReactions.LIKE, icon: <Like /> },
  { name: linkedInReactions.EMPATHY, icon: <Love /> },
  { name: linkedInReactions.INTEREST, icon: <Insight /> },
  { name: linkedInReactions.APPRECIATION, icon: <Support /> },
  { name: linkedInReactions.PRAISE, icon: <Celebrate /> },
  { name: linkedInReactions.ENTERTAINMENT, icon: <Funny /> }
];

const supportedExtensions = [
  mediaFileType.PDF,
  mediaFileType.DOC,
  mediaFileType.DOCX,
  mediaFileType.PPT,
  mediaFileType.PPTX
];
class LinkedInShare extends React.Component {
  static contextType = PageAccessContext;
  checkForLinksDebounce: any;
  fetchSocialPost: any;
  fetchSocialPostFromLinkInterval: any;
  imagePickerRef: any;
  saveLinkedInDetailsDebounce: any;
  isUnmounted: any;
  constructor(props: any) {
    super(props);
    let searchString = window.location.search;
    let { backpage, agencyView } = getQueryParamsFromURL(
      searchString,
      "linkedin/drafts"
    );

    let qsParse = qs.parse(props.location.search);
    this.state = {
      id:
        props.match?.params?.linkedinid ||
        props.match?.params?.id ||
        props.postId,
      post: props.post || null,
      externalApproverId: props.match?.params?.userId || null,
      publication: null,
      slug: null,
      linkedInHandle: null,
      account: null,
      loading: true,
      scheduledOn: getScheduleDateFromURL(searchString, null),
      postInitialized: false,
      linkedInPostId: null,
      linkedInPostStatus: null,
      showDeleteConfirmDialog: false,
      linkedInPosts: [],
      dedicatedPost: false,
      showDateTimePicker: false,
      backpage,
      agencyView,
      uploading: false,
      draftsCount: 0,
      linkedInPostApprovers: [],
      teammembers: [],
      showApproverDialog: false,
      approvalRequested: false,
      isApprover: false,
      showMentionInfo: false,
      hostName: null,
      updateBody: false,
      imageUploadComplete: false,
      isCollapsed: false,
      socialPostText: "",
      prevSocialPostText: [],
      showCopyConfirmation: false,
      copyIndex: DEFAULT_INDEX,
      scheduledPostDates: [],
      sourceLink: qsParse.url || "",
      instructions: "",
      showInstructionDialog: false,
      instructionDialogIndex: 0,
      uploadingThumbnailForVideo: false,
      showConfirmScheduleDateDialog: false,
      selectedScheduledDate: null,
      isBlogDestinationAssinged: false,
      isReactionMenuOpen: false,
      showAIGeneratePostDialog: false,
      freeInputSource: "",
      openGenerateCampaignDialog: false,
      numberOfPostsForCampaign: 3,
      accountsForCampaign: [],
      accountsForLike: [],
      accounts: [],
      isMultiAccountPost: false,
      groups: [],
      generatePostIndex: null,
      showPostFromVideoDialog: false,
      sendAutoRequest: false,
      salesCallInsightsData: {},
      loadingUrlMetaData: false,
      generatingLinkedInPost: false,
      socialPost: null,
      linkedinPostSuggestedTemplateIds: [],
      clientTimezone: ""
    };
    this.imagePickerRef = React.createRef();
    this.reactionRef = null;
    this.fetchSocialPost = [];
    this.fetchSocialPostFromLinkInterval = [];
    this.isUnmounted = false;
  }

  componentDidMount = () => {
    this.load();
    this.saveLinkedInDetailsDebounce = _.debounce(
      this.saveLinkedInDetails,
      1000
    );
    this.approvePostDebounce = _.debounce(this.approvePost, 1000);
    this.checkForLinksDebounce = _.debounce(this.checkForLinks, 1000);
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillUnmount() {
    this.isUnmounted = true;
    showOtherChatWidgets();
    this.fetchSocialPost?.forEach((interval: any) => {
      this.clearIntervalToFetchSocialPost(interval);
    });
    this.fetchSocialPostFromLinkInterval?.forEach((interval: any) => {
      this.clearFetchSocialPostFromLinkInterval(interval);
    });
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      let searchString = window.location.search;

      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          post: this.props.post || null,
          publication: null,
          slug: null,
          linkedInHandle: null,
          account: null,
          loading: true,
          scheduledOn: getScheduleDateFromURL(searchString, null),
          postInitialized: false,
          linkedInPostId: null,
          linkedInPostStatus: null,
          showDeleteConfirmDialog: false,
          linkedInPosts: [],
          dedicatedPost: false,
          showDateTimePicker: false,
          hostName: null,
          instructions: "",
          showInstructionDialog: false
        },
        this.load
      );
    }
  }

  // Function to check for links in LinkedInPosts
  checkForLinks = (index = null) => {
    let { linkedInPosts } = this.state;

    // If linkedInPosts array is empty or the specified index is invalid, return
    if (!linkedInPosts.length && !linkedInPosts[index]) {
      return;
    }

    // Extract the body text from the specified LinkedInPost and replace HTML tags with a space
    let bodyText = linkedInPosts[index]?.body.replace(HTML_REGEX, " ");

    // Find matches for URLs in the body text
    let matches = bodyText?.match(URL_REGEX);

    // If no matches found, reset embedUrl and includeEmbed properties of the LinkedInPost
    if (!matches) {
      linkedInPosts[index].embedUrl = "";
      linkedInPosts[index].includeEmbed = false;
      return;
    }

    // Get the last URL from the matches array
    let lastUrl = matches.pop();

    // If embedUrl is already set to the last URL, return
    if (linkedInPosts[index].embedUrl === lastUrl) {
      return;
    }

    this.setState({ loadingUrlMetaData: true, openImagePicker: false }, () => {
      // Retrieve metadata for the last URL using an API call
      api.getMetadataFromURL(lastUrl, (res: any) => {
        // If the API call is not successful, return
        if (
          res.status !== 200 ||
          (res.data?.metadata?.title || "").toLowerCase() === "not found"
        ) {
          linkedInPosts[index].embedUrl = "";
          linkedInPosts[index].includeEmbed = false;
          this.setState({ linkedInPosts, loadingUrlMetaData: false });
          return;
        }

        // Update the LinkedInPost's embedUrl, embed, and readTime properties
        // enable includeEmbed property
        linkedInPosts[index]["embedUrl"] = lastUrl;
        linkedInPosts[index].includeEmbed = true;
        linkedInPosts[index].embed = res.data.metadata;
        linkedInPosts[index].embed.readTime =
          Math.ceil(readingTime(bodyText).minutes) + " min read";

        // Update the state with the modified LinkedInPosts and save the details asynchronously
        this.setState(
          { linkedInPosts, loadingUrlMetaData: false, openImagePicker: false },
          () => {
            this.saveLinkedInDetailsDebounce(index);
          }
        );
      });
    });
  };

  clearFetchSocialPostFromLinkInterval = (index: any) => {
    clearInterval(this.fetchSocialPostFromLinkInterval[index]);
    this.fetchSocialPostFromLinkInterval[index] = undefined;
  };

  setIntervalToFetchSocialPostFromLink = (index: any) => {
    if (this.fetchSocialPostFromLinkInterval[index]) {
      return;
    }

    this.fetchSocialPostFromLinkInterval[index] = setInterval(() => {
      this.getSocialPostFromLink(index, false);
    }, 5000);

    setTimeout(() => {
      if (this.fetchSocialPostFromLinkInterval?.[index]) {
        try {
          let { linkedInPosts } = this.state;
          notify.show(
            "Failed to generate LinkedIn post. Please try again.",
            "error"
          );
          if (
            !this.isUnmounted &&
            linkedInPosts?.[index]?.["fetchingSocialPostFromLink"]
          ) {
            linkedInPosts[index]["fetchingSocialPostFromLink"] = false;
            this.setState({ linkedInPosts });
          }
        } catch (error) {}
      }
      this.clearFetchSocialPostFromLinkInterval(index);
    }, 15 * 60 * 1000); // clear the interval after 15 minutes if the response is not received
  };

  // regenerate defines whether to regenerate the social post or just check the status
  getSocialPostFromLink = (index: any, regenerate = true) => {
    let {
      sourceLink,
      publication,
      linkedInPosts,
      prevSocialPostText,
      socialPost,
      generatingLinkedInPost
    } = this.state;

    let { postId } = this.props;

    if (
      (linkedInPosts[index].fetchingSocialPostFromLink ||
        generatingLinkedInPost) &&
      regenerate
    ) {
      return;
    }

    linkedInPosts[index].fetchingSocialPostFromLink = true;
    this.setState({ linkedInPosts, generatingLinkedInPost: true }, () => {
      api.generateSocialPostFromLink(
        publication._id,
        sourceLink,
        regenerate,
        socialMediaType.LINKED_IN,
        postId || "",
        linkedInPosts[index]?.account || "",
        [], //
        (res: any) => {
          if (res.status === 200) {
            let { socialPost } = res.data;

            // Create interval to fetch the social post from link
            if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
              this.setIntervalToFetchSocialPostFromLink(index);
              return;
            }

            linkedInPosts[index]["fetchingSocialPostFromLink"] = false;
            this.clearFetchSocialPostFromLinkInterval(index);

            let body =
              socialPost?.generatedPosts?.[0]?.linkedinPost ||
              socialPost?.body ||
              "";

            if (!body) {
              this.setState({ linkedInPosts });
              notify.show(
                "Oops, we couldn't generate the social post for you. Please try again later.",
                "error"
              );
              this.setState({
                generatingLinkedInPost: false
              });
              return;
            }

            socialPost.generatedPosts?.map((post) => {
              post["newSample"] = post.sample;
            });

            prevSocialPostText.push(linkedInPosts[index]?.body || "");
            this.setState({
              prevSocialPostText,
              socialPostText: body,
              freeInputSource: "",
              linkedInPosts,
              socialPost,
              generatingLinkedInPost: false
            });

            this.updateLinkedinPostContent(index, body);
            setTimeout(() => {
              if (this.state.sendAutoRequest) {
                this.setApprovers(false);
              }
            }, 2000);
          } else {
            notify.show(
              res.status === 400 ? res.data : "Something went wrong",
              "error"
            );
            linkedInPosts[index]["fetchingSocialPostFromLink"] = false;
            this.setState({
              linkedInPosts,
              sendAutoRequest: false,
              generatingLinkedInPost: false
            });
            this.clearFetchSocialPostFromLinkInterval(index);
          }
        }
      );
    });
  };

  regenerateLinkedInPost = (
    index: number,
    generatedPostId: string,
    socialPostId: string,
    sample: string
  ) => {
    let { instructions, socialPost } = this.state;

    let generatedPostIndex = socialPost?.generatedPosts?.findIndex(
      (post) => post._id === generatedPostId
    );
    if (generatedPostIndex !== -1) {
      socialPost.generatedPosts[generatedPostIndex]["loading"] = true;
    }
    this.setState({ socialPost }, () => {
      api.regenerateLinkedInPost(
        generatedPostId,
        socialPostId,
        sample,
        instructions,
        (res) => {
          let { socialPost } = this.state;

          let generatedPostIndex = socialPost?.generatedPosts?.findIndex(
            (post) => post._id === generatedPostId
          );
          if (generatedPostIndex === -1) {
            return;
          }

          if (res.status === 200) {
            let { generatedPost } = res.data;

            socialPost.generatedPosts[generatedPostIndex]["linkedinPost"] =
              generatedPost.linkedinPost;
            socialPost.generatedPosts[generatedPostIndex]["sample"] =
              generatedPost.sample || "";
            socialPost.generatedPosts[generatedPostIndex]["loading"] = false;

            this.setState(
              {
                socialPost
              },
              () => {
                this.updateLinkedinPostContent(
                  index,
                  socialPost.generatedPosts[generatedPostIndex].linkedinPost
                );
              }
            );
          } else {
            notify.show(
              res.status === 400 ? res.data : "Something went wrong. Try again."
            );
            socialPost.generatedPosts[generatedPostIndex]["loading"] = false;
            this.setState({ socialPost });
          }
        }
      );
    });
  };

  onGeneratedPostSampleChange = (
    generatedPostId: string,
    sampleId: string,
    samples: string
  ) => {
    let { socialPost } = this.state;
    let sample =
      samples?.find((eachSample: Sample) => eachSample._id === sampleId)
        ?.sample || "";

    for (let post of socialPost.generatedPosts || []) {
      if (post._id === generatedPostId) {
        post.newSample = sample;
        break;
      }
    }

    this.setState({
      socialPost
    });
  };

  onSourceLinkSubmit = (index: any) => {
    let { sourceLink, linkedInPosts } = this.state;
    if (linkedInPosts[index].fetchingSocialPostFromLink) {
      return;
    }

    if (!validator.isURL(sourceLink)) {
      notify.show("Please provide valid URL", "error");
      return;
    }

    this.getSocialPostFromLink(index);
  };

  load = () => {
    let qsParse = qs.parse(this.props.location.search);
    let { id, socialPostText, externalApproverId, sourceLink } = this.state;
    api.getLinkedInDetails(
      id,
      socialPostText,
      externalApproverId,
      (res: any) => {
        if (res.status === 200) {
          let {
            post,
            publication,
            linkedInHandle,
            linkedInPosts,
            dedicatedPost,
            draftsCount,
            contentApprovers,
            teammembers,
            clientId,
            hostName,
            groups,
            clientTimezone
          } = res.data;

          let accountsForLike = (linkedInHandle.accounts || [])?.filter(
            (account: any) => {
              return account.permissions?.includes(
                LinkedInPermissionEnums.LIKE
              );
            }
          );
          let accounts = linkedInHandle.accounts || [];
          linkedInHandle.accounts = (linkedInHandle.accounts || [])?.filter(
            (account: any) => {
              return account.permissions?.includes(
                LinkedInPermissionEnums.POST
              );
            }
          );

          let postUrl = getSiteURL(
            publication,
            true,
            "/" + post.slug,
            post.destination
          );
          if (linkedInPosts.length === 0) {
            linkedInPosts.push(
              this.addLinkedInPostRow(post, postUrl, publication)
            );
          } else {
            linkedInPosts = this.syncAutocomplete(
              linkedInPosts,
              linkedInHandle
            );
            this.syncLinkedInImageToCoverPhoto(linkedInPosts);
          }

          let isBlogDestinationAssinged =
            publication?.externalSite?.blogs?.length === 1 ||
            (post.destination?.id ? true : false);
          let isApprover =
            contentApprovers?.some((approver: any) => {
              return approver?.client?._id === clientId;
            }) || false;

          linkedInPosts = linkedInPosts.map((linkedInPost: any, index: any) => {
            // Convert the comments to an array if it is a string
            // Why: We used to support only one comment per post (in string format)
            // Now: We support multiple comments per post (in array format)
            // Migration: Already done, but keeping this code for backward compatibility
            let comment =
              linkedInPost?.comments?.[0] ?? linkedInPost?.comments ?? "";
            if (typeof comment === "string") {
              linkedInPost.comments = [
                {
                  comment,
                  userId: linkedInPost?.account || "",
                  commentPreview: comment
                }
              ];
            }

            linkedInPost["autoLikePostTiming"] =
              linkedInPost.autoLikePostTiming ||
              linkedinAutoLikesPostTimeTypes.IN_THIRTY_MINUTES;

            linkedInPost["socialPostLoading"] = false;
            if (linkedInPost?.embed) {
              linkedInPost["embedUrl"] = linkedInPost?.embed?.website;
            }
            linkedInPost.linkedInPostRef = React.createRef();
            linkedInPost.body = linkedInPost.htmlText;
            return linkedInPost;
          });

          ({ linkedInPosts, contentApprovers } = this.autoFillByOrgAndUrl({
            linkedInPosts,
            qsParse,
            teammembers,
            contentApprovers,
            linkedInHandle
          }));

          this.setState(
            {
              post,
              slug: postUrl,
              linkedInHandle,
              loading: false,
              publication,
              linkedInPosts,
              dedicatedPost,
              draftsCount,
              linkedInPostApprovers: contentApprovers,
              teammembers,
              approvalRequested: linkedInPosts[0]?.approvers?.length
                ? true
                : false,
              isApprover,
              hostName,
              instructions: publication?.linkedInCustomPrompt || "",
              isBlogDestinationAssinged,
              accountsForLike,
              accounts,
              groups,
              clientTimezone,
              salesCallInsightsData:
                linkedInPosts?.[0]?.salesCallInsightsData || {},
              sendAutoRequest:
                qsParse.url &&
                sourceLink &&
                contentApprovers?.some((approver) => !approver.requested)
            },
            () => {
              this.getSuggestedTemplatesForCallData();

              //check if account is connected to linkedin

              this.state.linkedInPosts?.forEach((linkedInPost: any) => {
                this.checkAccountConnected(linkedInPost.account);
              });

              // If query param have url then generate post from url
              if (qsParse.url && sourceLink) {
                this.getSocialPostFromLink(
                  0 //index
                );
              } else if (
                this.state.sendAutoRequest &&
                linkedInPosts?.[0]?.body
              ) {
                // If body exists and approver request pending then send approve request
                this.setApprovers(false);
              }

              // Save linkedin post data
              if (qsParse.org || qsParse.url) {
                this.saveLinkedInDetails(0);
              }

              // Clear query params
              if (qsParse.org || qsParse.url) {
                this.props.history.push({ search: "" });
              }
            }
          );
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
          this.setState({
            loading: false,
            error: true,
            errorMessage: res.data
          });
        }
      }
    );
  };

  getSuggestedTemplatesForCallData = async () => {
    const { publication, salesCallInsightsData, linkedInPosts } = this.state;

    if (
      !salesCallInsightsData?.body ||
      salesCallInsightsData?.suggestedSampleIds?.length
    ) {
      return;
    }

    const bodyData = {
      publicationId: publication?._id,
      postContent: salesCallInsightsData.body,
      saveSuggestedTemplates: true,
      callId: linkedInPosts[0]?.salesCallData?.callId,
      insightId: salesCallInsightsData._id
    };

    api.suggestLinkedinTemplatesFromPost(bodyData, (res) => {
      if (res.status === 200 && res.data?.success) {
        const { salesCallInsightsData } = this.state;

        const suggestedSampleIds = res.data.suggestedTemplates;
        if (!suggestedSampleIds?.length || !salesCallInsightsData) return;

        salesCallInsightsData.suggestedSampleIds = suggestedSampleIds;

        this.setState({ salesCallInsightsData });
      }
    });
  };

  autoFillByOrgAndUrl({
    linkedInPosts,
    qsParse,
    teammembers,
    contentApprovers,
    linkedInHandle
  }: any) {
    try {
      if (!linkedInPosts?.[0]) {
        return linkedInPosts;
      }

      let account = qsParse.org
        ? linkedInHandle?.accounts?.find((eachAccount) =>
            eachAccount?.orgIds?.includes(qsParse.org)
          )
        : "";

      let selectedOrganization = qsParse.org
        ? linkedInHandle?.organizations?.find(
            (eachAccount) => eachAccount?.userId === qsParse.org
          )
        : "";

      // Auto select account
      if (qsParse.org && account && !linkedInPosts[0].account) {
        linkedInPosts[0].account = qsParse.org;
      }

      // Create automate likes
      if (
        selectedOrganization &&
        !linkedInPosts[0]?.automateLikeAccounts?.length
      ) {
        linkedInPosts[0].automateLikeReaction = linkedInReactions.LIKE;
        linkedInPosts[0].automateLikeAccounts = [
          {
            group: null,
            name: selectedOrganization.name,
            type: "",
            userId: selectedOrganization.userId
          }
        ];
      }

      // Create auto comment request
      if (account && !linkedInPosts[0]?.accountsToAutoComment?.length) {
        linkedInPosts[0].autoCommentTemplate =
          "<p>This is what a request for a comment will look like - try it out</p>";
        linkedInPosts[0].accountsToAutoComment = [
          {
            group: null,
            name: account.name || "",
            type: "",
            userId: account.userId
          }
        ];
      }

      // crate auto comment
      if (
        qsParse.url &&
        selectedOrganization &&
        !linkedInPosts[0]?.comments?.length
      ) {
        linkedInPosts[0].comments = [
          {
            comment: `<p>Read the full case study here: ${qsParse.url}</p>`,
            commentPreview: `<p>Read the full case study here: ${qsParse.url}</p>`,
            htmlComment: `<p>Read the full case study here: ${qsParse.url}</p>`,
            userId: selectedOrganization.userId
          }
        ];
      }

      // Auto request
      if (account && !contentApprovers?.length) {
        let teammate = teammembers?.find(
          (team) => team?.client?.email === account.email
        );

        if (teammate) {
          contentApprovers = [teammate];
        } else {
          contentApprovers = [
            {
              userId: account.userId,
              client: {
                name: account.name || "",
                email: account.email || "",
                profilePic: account.profilePicture || ""
              }
            }
          ];
        }
      }

      return { linkedInPosts, contentApprovers };
    } catch (error) {
      console.log(error);
      return { linkedInPosts, contentApprovers };
    }
  }

  convertAtMentionToOrganization(
    content: any,
    linkedInPostIndex: any,
    commentIndex = 0
  ) {
    let { linkedInPosts } = this.state;
    let { body, comments } = linkedInPosts[linkedInPostIndex];
    let contentChanged = body;
    let comment = comments[commentIndex]?.comment || "";
    if (content === linkedInContentType.POST_COMMENT) {
      contentChanged = comment;
    }

    if (!contentChanged.includes("@")) {
      if (content === linkedInContentType.POST_BODY) {
        linkedInPosts[linkedInPostIndex]["bodyPreview"] = body;
      } else {
        linkedInPosts[linkedInPostIndex]["comments"][
          commentIndex
        ].commentPreview = comment;
      }
      this.setState(
        { linkedInPosts },
        this.saveLinkedInDetailsDebounce(linkedInPostIndex)
      );
      return;
    }

    contentChanged = convertLinkedInPostTextToPreviewText(contentChanged);

    if (content === linkedInContentType.POST_BODY) {
      linkedInPosts[linkedInPostIndex]["bodyPreview"] = contentChanged;
    } else {
      linkedInPosts[linkedInPostIndex]["comments"][
        commentIndex
      ].commentPreview = contentChanged;
    }

    this.setState(
      { linkedInPosts },
      this.saveLinkedInDetailsDebounce(linkedInPostIndex)
    );
  }

  //To sync cover image updates
  syncLinkedInImageToCoverPhoto = (linkedInPosts: any) => {
    let { showPostCoverPhoto, isReviewScreen, coverImage, thumbnail } =
      this.props;
    if (!isReviewScreen) {
      return;
    }
    if (!showPostCoverPhoto) {
      //To clear image, if it has already been attached to the post
      coverImage = null;
      thumbnail = null;
    }
    linkedInPosts.forEach((linkedInPost: any) => {
      //Update cover photo only if the image is not uploaded by user
      if (
        linkedInPost.status === socialMediaStatus.DRAFT &&
        linkedInPost.mediaFile?.imageUploadedByUser === false &&
        linkedInPost.mediaFile?.url !== thumbnail
      ) {
        if (thumbnail || coverImage) {
          linkedInPost.mediaFile = {
            url: thumbnail || coverImage,
            type: mediaFileType.IMAGE,
            imageUploadedByUser: false
          };
        } else {
          linkedInPost.mediaFile = null;
        }
      }
    });
  };

  getDescriptionModules = () => {
    return this.modules;
  };

  modules = {
    toolbar: [],
    clipboard: {
      matchVisual: false //use this option to stop adding unwanted newline while editing or reloading. here reloading happens by rendering (link - https://github.com/quilljs/quill/issues/2905)
    }
  };

  addLinkedInPostRow = (post = null, postUrl: any, publication: any) => {
    let utm = nanoid(10);

    let { showPostCoverPhoto, isReviewScreen, thumbnail, coverImage } =
      this.props;

    let { socialPostText } = this.state;
    let mediaFile = null;
    if (isReviewScreen && showPostCoverPhoto && (thumbnail || coverImage)) {
      mediaFile = {
        url: thumbnail || coverImage,
        type: mediaFileType.IMAGE,
        imageUploadedByUser: false
      };
    }
    let newLinkedInPost = {
      _id: null,
      body: socialPostText || this.getLinkedInBodyText(post),
      comments: [
        {
          comment: this.getLinkedInCommentText(post),
          userId: "",
          commentPreview: this.getLinkedInCommentText(post)
        }
      ],

      mediaFile: mediaFile,

      scheduledOn: this.state.scheduledOn || null,
      account: null,
      accounts: [],
      publishType: this.state.scheduledOn
        ? publishTypes.LATER
        : post?._id
        ? publishTypes.WITH_POST
        : publishTypes.NOW,
      status: socialMediaStatus.DRAFT,
      approvers: [],
      automateLikeAccounts: [],
      accountsToAutoComment: [],
      autoCommentTemplate: "",
      isAllAccountsSelectedForAutoLikes: false,
      isAllAccountsSelectedForAutoComments: false,
      includeEmbed: false,
      embed: null,
      embedUrl: "",
      approvedBy: null,
      utm,
      isDirty: false,
      linkedInPostRef: React.createRef()
    };

    return newLinkedInPost;
  };

  getLinkedInBodyText = (post: any) => {
    let text = "";
    if (post?.title) {
      text = `<p>Read a link to our full article in the comments below.</p>`;
    }
    return text;
  };

  getLinkedInCommentText = (post: any) => {
    let text = "";
    if (post?.title) {
      text = `Read the full post here: ${URL_REPLACE_TEXT}`;
    }
    return text;
  };

  saveLinkedInDetails = (linkedInPostIndex = null) => {
    let { id, linkedInPosts, externalApproverId } = this.state;

    if (!linkedInPostIndex && linkedInPostIndex !== 0) {
      return;
    }

    let savingPost = linkedInPosts[linkedInPostIndex];
    api.saveLinkedInShareDetails(
      id,
      savingPost._id,
      savingPost.account,
      savingPost.body,
      savingPost.publishType,
      savingPost.scheduledOn,
      savingPost.comments || [],
      savingPost.mediaFile,
      savingPost.utm,
      savingPost.includeCoverPhoto,
      savingPost.bodyPreview,
      [], // savingPost.commentsPreview, TODO: remove this field from DB and each function call, after proper migration
      savingPost.automateLikeAccounts,
      savingPost.automateLikeReaction,
      savingPost.accountsToAutoComment,
      savingPost.autoCommentTemplate,
      savingPost.includeEmbed,
      savingPost.embed,
      savingPost.samples || [],
      savingPost.approvalRequiredForComments || false,
      savingPost.autoLikePostTiming ||
        linkedinAutoLikesPostTimeTypes.IN_THIRTY_MINUTES,
      externalApproverId,
      savingPost.accounts,
      savingPost.approvers,
      (res: any) => {
        if (res.status === 200) {
          linkedInPosts[linkedInPostIndex]._id = res.data;
          this.setState({ linkedInPosts });
        }
        if (res.status === 400) {
          notify.show("Something went wrong. Please try again.", "error");
        }
      }
    );
  };

  //check if selected Account is connected and show error if not connected
  checkAccountConnected = (accountId: any) => {
    if (!accountId) return;

    let { linkedInHandle } = this.state;
    let allAccounts = [
      ...linkedInHandle?.accounts,
      ...linkedInHandle?.organizations
    ];
    let selectedAccount = allAccounts.find(
      (account) => account.userId === accountId
    );
    if (!selectedAccount?.connected) {
      notify.show("Please reconnect LinkedIn from Settings.", "error");
      return false;
    }
    return true;
  };

  toggleUpdateBody = (isCollapsed: any) => {
    this.setState({ updateBody: false, isCollapsed });
  };

  toggleImageUploadComplete = () => {
    this.setState({ imageUploadComplete: false });
  };

  changePostBody = (value: any, index: any) => {
    let { linkedInPosts } = this.state;
    this.setState({ updateBody: true });
    if (value.length <= LINKEDIN_CHAR_LIMIT) {
      linkedInPosts[index][linkedInContentType.POST_BODY] = value;
      linkedInPosts[index]["isDirty"] = true;
    }

    this.setState({ linkedInPosts }, () => {
      this.convertAtMentionToOrganization(linkedInContentType.POST_BODY, index);
    });
  };

  handleContentChange = (event: any, index: any) => {
    let { linkedInPosts } = this.state;
    let { isReviewScreen } = this.props;
    let { name, value } = event.target;

    if (name === POST_TO) {
      linkedInPosts[index]["account"] = value;
      let isValid = this.checkAccountConnected(value);
      if (isValid) {
        linkedInPosts[index]?.comments?.forEach((comment: any) => {
          if (!comment?.userId) {
            comment.userId = value;
          }
        });
      }
    }

    if (name === linkedInContentType.POST_BODY) {
      //stop AI post generation if user types
      linkedInPosts[index].socialPostLoading = false;
      this.setState({ updateBody: true, linkedInPosts });
      let postLength = getTextLengthOfHTML(value);
      if (postLength <= LINKEDIN_CHAR_LIMIT) {
        linkedInPosts[index][linkedInContentType.POST_BODY] = value;
      } else {
        notify.show(
          `Character limit of ${LINKEDIN_CHAR_LIMIT} exceeded! Please limit your input to ${LINKEDIN_CHAR_LIMIT} characters.`,
          "error"
        );
      }
      if (
        !isReviewScreen &&
        !linkedInPosts[index]?.post &&
        !linkedInPosts[index].includeCoverPhoto &&
        !linkedInPosts[index].mediaFile?.url &&
        (linkedInPosts[index].includeEmbed || !linkedInPosts[index].embedUrl)
      ) {
        this.checkForLinksDebounce(index);
      }
    }

    if (name === linkedInContentType.POST_COMMENT) {
      // bypass: already handled in the this.getCommentBox
    }

    if (name === linkedInContentType.POST_AUTOCOMMENT_TEMPLATE) {
      linkedInPosts[index][linkedInContentType.POST_AUTOCOMMENT_TEMPLATE] =
        value;
    }

    this.setState({ linkedInPosts }, () => {
      if (name === linkedInContentType.POST_BODY) {
        this.convertAtMentionToOrganization(name, index);
      } else {
        this.saveLinkedInDetails(index);
      }
    });
  };

  handleLinkedInConnect = () => {
    let { post, publication, scheduledOn } = this.state;
    if (post?._id) {
      api.connectToLinkedIn(publication._id, "review", post._id);
    } else {
      //ScheduledOn param is used for connecting to linkedIn from calendar
      //It prepopulates the publish time in social share screen for linkedIn post after integration
      api.connectToLinkedIn(publication._id, "linkedin", null, {
        scheduledOn: scheduledOn
      });
    }
  };
  onCopy = () => {
    notify.show("Copied to clipboard", "success");
  };

  getScheduleDate = (scheduledDate: any) => {
    let { clientTimezone } = this.state;

    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(
        scheduledDate,
        clientTimezone
      );
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  handlePublishTypeChange = (event: any, index: any) => {
    let { linkedInPosts } = this.state;
    if (event.target.value === publishTypes.LATER) {
      // handled by onClick - handlePublishLater
      return;
    } else {
      linkedInPosts[index].scheduledOn = null;
      linkedInPosts[index].publishType = event.target.value;
    }
    this.setState({ linkedInPosts, linkedInPostIndex: index }, () => {
      this.saveLinkedInDetailsDebounce(index);
    });
  };

  getScheduledPostDates = () => {
    let { linkedInPosts, publication, linkedInPostIndex } = this.state;
    api.getScheduledPostDates(
      publication._id,
      linkedInPosts[linkedInPostIndex]?._id,
      linkedInPosts[linkedInPostIndex]?.account,
      socialMediaType.LINKED_IN,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            { scheduledPostDates: res.data?.scheduledPostDates || [] },
            () => this.setState({ showDateTimePicker: true })
          );
        }
      }
    );
  };

  handlePublishLater = (index: any) => {
    let { linkedInPosts } = this.state;
    this.setState({ linkedInPostIndex: index }, () =>
      this.getScheduledPostDates(index)
    );
  };

  onScheduleDateChange = (date: any, confirm = true) => {
    let { post } = this.state;
    if (confirm && post?.status === postStatus.PUBLISHED) {
      this.setState({
        showConfirmScheduleDateDialog: true,
        selectedScheduledDate: date
      });
      return;
    }

    let { linkedInPosts, linkedInPostIndex } = this.state;
    let currentDate = new Date();
    if (date > currentDate) {
      linkedInPosts[linkedInPostIndex].scheduledOn = date;
      linkedInPosts[linkedInPostIndex].publishType = publishTypes.LATER;
      this.setState(
        {
          linkedInPosts,
          showConfirmScheduleDateDialog: false,
          selectedScheduledDate: null
        },
        () => {
          this.saveLinkedInDetailsDebounce(linkedInPostIndex);
        }
      );
    }
  };

  toggleImagePicker = (index = null) => {
    let { openImagePicker } = this.state;
    this.setState({
      openImagePicker: !openImagePicker,
      linkedInPostIndex: index
    });
  };

  toggleLinkedinEmbed = (index = null) => {
    let { isReviewScreen } = this.props;

    let { linkedInPosts } = this.state;
    if (isReviewScreen || linkedInPosts[index]?.post) {
      linkedInPosts[index].includeCoverPhoto =
        !linkedInPosts[index].includeCoverPhoto;
    } else {
      linkedInPosts[index].includeEmbed = !linkedInPosts[index].includeEmbed;
    }
    this.setState({ linkedInPosts }, () => {
      this.saveLinkedInDetailsDebounce(index);
    });
  };

  onImageSelected = (imageUrl: any) => {
    let { linkedInPosts, linkedInPostIndex, uploadingThumbnailForVideo } =
      this.state;

    if (
      uploadingThumbnailForVideo &&
      linkedInPosts[linkedInPostIndex]?.mediaFile?.type === mediaFileType.VIDEO
    ) {
      linkedInPosts[linkedInPostIndex].mediaFile.thumbnailForVideo = imageUrl;
    } else {
      linkedInPosts[linkedInPostIndex].mediaFile = {
        url: imageUrl,
        type: mediaFileType.IMAGE,
        imageUploadedByUser: true
      };
    }
    this.setState(
      {
        linkedInPosts,
        imageUploadComplete: true,
        uploadingThumbnailForVideo: false
      },
      () => {
        this.toggleImagePicker();
        this.saveLinkedInDetailsDebounce(linkedInPostIndex);
      }
    );
  };

  onSelectFile = (file: any) => {
    this.setState({ uploading: true }, async () => {
      //Handle image upload
      if (file.type.includes("image")) {
        this.onSelectImages(file);
        return;
      }

      //Handle document upload
      let extension = file.name?.split(".")?.pop()?.toLowerCase();
      if (supportedExtensions.includes(extension)) {
        this.onSelectDocument(file, extension);
        return;
      }

      this.onSelectVideo(file);
    });
  };

  onSelectDocument = async (file: any, extension) => {
    try {
      const DOCUMENT_SIZE_LIMIT = 100 * 1024 * 1024; //100MB Limit for document
      if (file.size > DOCUMENT_SIZE_LIMIT) {
        this.setState({ uploading: false });
        notify.show("File size exceeds the maximum limit of 100 MB", "error");
        return;
      }
      let mediaURL = await uploadFile(file, this.state.publication.domain);

      if (!mediaURL) {
        notify.show("Unable to upload your document", "error");
        this.setState({ uploading: false });
        return;
      }
      let { linkedInPosts, linkedInPostIndex } = this.state;
      linkedInPosts[linkedInPostIndex].mediaFile = {
        url: mediaURL,
        type: extension,
        name: file.name
      };
      this.setState({ linkedInPosts, uploading: false }, () => {
        this.toggleImagePicker();
        this.saveLinkedInDetailsDebounce(linkedInPostIndex);
      });
    } catch (error) {}
  };

  onSelectImages = (file: any) => {
    const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB Limit for image

    if (!file.type.includes("image")) {
      this.setState({ uploading: false });
      notify.show("Only images are allowed", "error");
    }

    if (file.size > IMAGE_SIZE_LIMIT) {
      this.setState({ uploading: false });
      notify.show("Upload an image or a gif that's smaller than 5 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.state.publication.domain
        );
      }
      if (!imageUrl) {
        this.setState({ uploading: false });
        return;
      }

      let { linkedInPosts, linkedInPostIndex, uploadingThumbnailForVideo } =
        this.state;
      if (
        uploadingThumbnailForVideo &&
        linkedInPosts[linkedInPostIndex]?.mediaFile?.type ===
          mediaFileType.VIDEO
      ) {
        linkedInPosts[linkedInPostIndex].mediaFile.thumbnailForVideo = imageUrl;
      } else {
        linkedInPosts[linkedInPostIndex].mediaFile = {
          url: imageUrl,
          type: mediaFileType.IMAGE,
          imageUploadedByUser: true
        };
      }

      this.setState(
        {
          linkedInPosts,
          uploading: false,
          imageUploadComplete: true,
          uploadingThumbnailForVideo: false
        },
        () => {
          this.toggleImagePicker();
          this.saveLinkedInDetailsDebounce(linkedInPostIndex);
        }
      );
    });
    reader.readAsDataURL(file);
  };

  onSelectVideo = async (file: any) => {
    try {
      const MAX_DURATION = 30 * 60; //30 mins
      const MIN_DURATION = 1; //Seconds
      const VIDEO_SIZE_LIMIT = 200 * 1024 * 1024; //200MB

      let { duration } = await getVideoFileMetaData(file);
      if (duration < MIN_DURATION || duration > MAX_DURATION) {
        notify.show(
          "Your video needs to be between 1 second and 140 seconds long",
          "error"
        );
        this.setState({
          uploading: false
        });
        return;
      }

      if (file.size > VIDEO_SIZE_LIMIT) {
        this.setState({ uploading: false });
        notify.show("Upload a video that's smaller than 200 MB", "error");
        return;
      }

      let imageUrl = await uploadMp4Video(file, this.state.publication.domain);

      if (!imageUrl) {
        notify.show("Unable to upload your video", "error");
        this.setState({ uploading: false });
        return;
      }

      let { linkedInPosts, linkedInPostIndex } = this.state;
      linkedInPosts[linkedInPostIndex].mediaFile = {
        url: imageUrl,
        type: mediaFileType.VIDEO
      };
      this.setState({ linkedInPosts, uploading: false }, () => {
        this.toggleImagePicker();
        this.saveLinkedInDetailsDebounce(linkedInPostIndex);
        this.showGeneratePostFromVideoDialog(true, linkedInPostIndex);
      });
    } catch (error) {}
  };

  showGeneratePostFromVideoDialog = (
    showPostFromVideoDialog: boolean = false,
    generatePostIndex: number | null = null
  ) => {
    this.setState({
      showPostFromVideoDialog,
      generatePostIndex
    });
  };

  removePostImage = (index: any) => {
    let { linkedInPosts } = this.state;
    if (linkedInPosts[index].status === socialMediaStatus.COMPLETED) {
      return;
    }
    linkedInPosts[index].mediaFile = null;
    this.setState({ linkedInPosts });
  };

  handlePostToLinkedIn = () => {
    let { postInitialized } = this.state;
    if (postInitialized) {
      return;
    }

    this.setState({ postInitialized: true }, () => {
      let { linkedInPosts, publication, post } = this.state;
      let valid = true;

      for (let linkedInPost of linkedInPosts) {
        if (!linkedInPost.account) {
          valid = false;
          notify.show("Select LinkedIn account to share", "error");
          break;
        }
        if (!linkedInPost.body) {
          valid = false;
          notify.show("Write something to share", "error");
          break;
        }
      }

      if (!valid) {
        this.setState({
          postInitialized: false
        });
        return;
      }

      api.shareToLinkedIn(
        publication._id,
        post?._id || null,
        this.sanitizeLinkedInPosts(linkedInPosts),
        (res: any) => {
          if (res.status === 200) {
            let linkedInPostResponses = res.data;
            let hasScheduled = false;
            let approvalRequested = false;
            let postApiFailed = false;
            for (let linkedInPostResponse of linkedInPostResponses) {
              if (!linkedInPostResponse) {
                postApiFailed = true;
              }
              if (
                linkedInPostResponse?.status === socialMediaStatus.IN_REVIEW &&
                !approvalRequested
              ) {
                approvalRequested = true;
              }
              if (linkedInPostResponse?.scheduledOn && !hasScheduled) {
                hasScheduled = true;
              }
              if (linkedInPostResponse?.url) {
                setTimeout(window.open(linkedInPostResponse.url), 2000);
              }
            }

            if (approvalRequested) {
              notify.show("Notifying approvers about the request.", "success");

              this.props.history.push(
                `/${publication._id}/create/linkedin?tab=in_review`
              );
              return;
            }

            if (hasScheduled) {
              notify.show("Your LinkedIn post was scheduled", "success");
            }

            let haveVideoPost = linkedInPosts?.some(
              (linkedinPost: any) =>
                linkedinPost?.mediaFile?.type === mediaFileType.VIDEO
            );

            if (haveVideoPost) {
              notify.show(
                "We're sharing your post. Please check your LinkedIn in a minute.",
                "success"
              );
            }

            if (postApiFailed) {
              notify.show("Oops! LinkedIn wouldn't accept the post", "error");
            }

            this.gotoBackPageORClearFields(linkedInPostResponses);
          }
          if (res.status === 400) {
            this.setState({
              postInitialized: false
            });
            notify.show("Oops! Something went wrong", "error");
          }
        }
      );
    });
  };

  getLinkedInProfile(accountId: any) {
    let { linkedInHandle } = this.state;
    let selectedAccount = linkedInHandle?.accounts.find((account: any) => {
      return account.userId === accountId;
    });
    if (selectedAccount?.userId) {
      return selectedAccount;
    }
    selectedAccount = linkedInHandle?.organizations.find((account: any) => {
      return account.userId === accountId;
    });
    return selectedAccount;
  }

  gotoBackPageORClearFields = (linkedInPostResponses: any) => {
    let { publication, backpage, dedicatedPost, agencyView } = this.state;

    let { isReviewScreen } = this.props;

    let scheduledPosts = linkedInPostResponses.filter(
      (post: any) => post?.scheduledOn
    );

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    if (scheduledPosts[0]?._id && !isReviewScreen) {
      let toUrl = `/${publication._id}/create/calendar/?mediaId=${
        scheduledPosts[0]._id
      }&createdOn=${new Date(scheduledPosts[0].scheduledOn).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (dedicatedPost) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ postInitialized: false }, this.load);
  };

  handlePostToAnotherAccount = () => {
    let { linkedInPosts, post, slug, publication } = this.state;
    if (linkedInPosts.length > 0 && linkedInPosts[0]) {
      let lastPostIndex = linkedInPosts.length - 1; //Fetch details from last post
      let utm = nanoid(10);
      let linkedInPost = {
        ..._.cloneDeep(linkedInPosts[lastPostIndex]),
        _id: null,
        account: null,
        accounts: [],
        status: socialMediaStatus.DRAFT,
        utm: utm,
        linkedInPostRef: React.createRef(),
        autoLikePostTiming: linkedinAutoLikesPostTimeTypes.IN_THIRTY_MINUTES
      };
      linkedInPosts.push(linkedInPost);
    } else {
      linkedInPosts.push(this.addLinkedInPostRow(post, slug, publication));
    }
    this.setState({ linkedInPosts });
  };

  toggleDeleteLinkedInPostConfirmDialog = (index: any) => {
    let { showDeleteConfirmDialog } = this.state;
    this.setState({
      showDeleteConfirmDialog: !showDeleteConfirmDialog,
      linkedInPostIndex: index
    });
  };

  handleDialogClose = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      linkedInPostIndex: null
    });
  };

  deleteLinkedInPost = () => {
    let {
      publication,

      linkedInPosts,

      linkedInPostIndex,

      dedicatedPost,

      backpage
    } = this.state;
    let deletedRow = linkedInPosts.splice(linkedInPostIndex, 1);

    if (!deletedRow[0]?._id) {
      this.setState({
        showDeleteConfirmDialog: false
      });
      return;
    }

    api.deleteLinkedInPost(publication._id, deletedRow[0]._id, (res: any) => {
      if (res.status === 200) {
        this.setState({
          linkedInPosts,
          showDeleteConfirmDialog: false,
          linkedInPostIndex: null
        });
        if (dedicatedPost) {
          this.props.history.push(`/${publication._id}/${backpage}`);
        }
      }
      if (res.status === 400) {
        notify.show(
          "We couldn't delete your post. Contact support@letterdrop.com",
          "error"
        );
      }
    });
  };

  handleImagePickerClose = () => {
    if (this.state.uploading) {
      return;
    }

    if (this.imagePickerRef?.checkImageUploading?.()) {
      return;
    }
    this.setState({
      openImagePicker: false,
      uploadingThumbnailForVideo: false
    });
  };

  showApprove = () => {
    let { showApproverDialog } = this.state;
    this.setState({ showApproverDialog: !showApproverDialog });
  };

  onApproverChange = (e: any, values: any) => {
    this.setState({ linkedInPostApprovers: values });
  };

  onAutocompleteChangeForCampaign = (values: Array<LinkedInAccount>) => {
    this.setState({
      accountsForCampaign: values
    });
  };

  onAutocompleteChange = (values: any, index: any) => {
    let { linkedInPosts } = this.state;

    linkedInPosts[index].automateLikeAccounts = values;
    this.setState(
      {
        linkedInPosts
      },
      this.saveLinkedInDetailsDebounce(index)
    );
  };

  onAutocompleteChangeForAccounts = (values: any, index: number) => {
    let { linkedInPosts } = this.state;
    let isMultiAccountPost = false;
    values = values.map((value: any) => value.userId);
    linkedInPosts[index].accounts = values;

    if (values.length > 1) {
      isMultiAccountPost = true;
    }

    if (values.length > 0) {
      linkedInPosts[index]["account"] = values[0];
      let isValid = this.checkAccountConnected(values[0]);
      if (isValid) {
        linkedInPosts[index]?.comments?.forEach((comment: any) => {
          if (!comment?.userId) {
            comment.userId = values[0];
          }
        });
      }
    }

    this.setState(
      {
        linkedInPosts,
        isMultiAccountPost
      },
      this.saveLinkedInDetails(index)
    );
  };

  onAutoCommentAutocompleteChange = (values: any, index: any) => {
    let { linkedInPosts } = this.state;
    linkedInPosts[index].accountsToAutoComment = values;

    this.setState(
      {
        linkedInPosts
      },
      this.saveLinkedInDetailsDebounce(index)
    );
  };

  syncAutocomplete = (linkedInPosts: any, linkedInHandle: any) => {
    let accounts = [
      ...linkedInHandle.accounts,
      ...linkedInHandle.organizations
    ];
    return linkedInPosts.map((linkedInPost: any) => {
      //sync autolike autocomplete
      linkedInPost.isAllAccountsSelectedForAutoLikes =
        linkedInPost.automateLikeAccounts.length === accounts.length;
      //sync autoComment autocomplete
      linkedInPost.isAllAccountsSelectedForAutoComments =
        linkedInPost.accountsToAutoComment.length ===
        linkedInHandle?.accounts?.length;

      return linkedInPost;
    });
  };

  syncAccounts = (linkedInPosts: any, linkedInHandle: any) => {
    let accounts = [
      ...linkedInHandle.accounts,
      ...linkedInHandle.organizations
    ];
    return linkedInPosts.map((linkedInPost: any) => {
      linkedInPost.accounts = linkedInPost.accounts.map(
        (accountUserId: any) => {
          let selectedAccount = accounts.find(
            (account: any) => account.userId === accountUserId
          );
          return selectedAccount;
        }
      );
      linkedInPost.accounts = linkedInPost.accounts?.filter((account: any) => {
        return account;
      });
      return linkedInPost;
    });
  };

  getAccountsForSingleLinkedinPost = (
    linkedInPost: any,
    linkedInHandle: any
  ) => {
    if (!linkedInPost || !linkedInPost.accounts) return [];

    const allAccounts = [
      ...(linkedInHandle?.accounts || []),
      ...(linkedInHandle?.organizations || [])
    ];

    const accounts = linkedInPost.accounts
      .map((accountUserId: any) => {
        const selectedAccount = allAccounts.find(
          (account: any) => account.userId === accountUserId
        );
        return selectedAccount;
      })
      .filter((account: any) => {
        return account;
      });

    return accounts;
  };

  setApprovers = (redirectAfterRequest: boolean = true) => {
    let { linkedInPosts, linkedInPostApprovers } = this.state;

    if (linkedInPostApprovers.length === 0) {
      notify.show(
        "Select someone to approve your post. We'll send them an email",
        "error"
      );
      return;
    }

    linkedInPosts.forEach((linkedInPost: any) => {
      linkedInPost.approvers = linkedInPostApprovers;
    });

    this.setState(
      {
        linkedInPosts,
        showApproverDialog: false,
        approvalRequested: true,
        sendAutoRequest: false
      },
      () => this.requestLinkedInReview(redirectAfterRequest)
    );
  };

  setApproversForMultiAccount = (redirectAfterRequest: boolean = true) => {
    let { linkedInHandle } = this.state;
    let accounts = [
      ...linkedInHandle.accounts,
      ...linkedInHandle.organizations
    ];
    this.setState(
      {
        showApproverDialog: false,
        approvalRequested: true,
        sendAutoRequest: false
      },
      () =>
        this.requestLinkedInReviewMultipleAccount(
          accounts,
          this.getApproversForMultipleAccount(),
          redirectAfterRequest
        )
    );
  };

  doesApprovalRequested = (incomingApprover: any) => {
    let { linkedInPosts } = this.state;
    return linkedInPosts[0]?.approvers?.some((selectedApprover: any) => {
      if (selectedApprover._id) {
        return selectedApprover._id === incomingApprover._id;
      }
      if (selectedApprover.userId) {
        return selectedApprover.userId === incomingApprover.userId;
      }
      return false;
    });
  };

  approvePost = () => {
    let { publication, linkedInPosts, externalApproverId } = this.state;

    let valid = true;
    for (let linkedInPost of linkedInPosts) {
      (linkedInPost?.comments || [])?.forEach((comment: any) => {
        if (comment.comment && !comment.userId) {
          valid = false;
          notify.show("Select a user to comment", "error");
          return;
        }
      });
      if (!valid) {
        break;
      }

      if (
        linkedInPost.status !== socialMediaStatus.COMPLETED &&
        linkedInPost.scheduledOn &&
        moment().isAfter(linkedInPost.scheduledOn)
      ) {
        valid = false;
        notify.show("You're trying to schedule this in the past", "error");
        break;
      }
    }

    if (!valid) {
      return;
    }

    this.setState({ postInitialized: true }, () => {
      api.approveLinkedInPost(
        publication._id,
        this.sanitizeLinkedInPosts(linkedInPosts),
        (res: any) => {
          if (res.status === 200) {
            if (!externalApproverId) {
              this.props.history.push(`/${publication._id}/create/linkedin`);
            } else {
              this.setState({
                postInitialized: false
              });
              notify.show("Approved", "success");
            }
          } else {
            this.setState({
              postInitialized: false
            });
            notify.show("Oops, something went wrong.", "error");
          }
        }
      );
    });
  };

  sanitizeLinkedInPosts = (linkedInPosts: any) => {
    let { externalApproverId } = this.state;
    return linkedInPosts.map((linkedInPost: any) => {
      delete linkedInPost.linkedInPostRef;
      linkedInPost["externalApproverId"] = externalApproverId;
      return linkedInPost;
    });
  };

  toggleShowMentionInfo = () => {
    let { showMentionInfo } = this.state;
    this.setState({ showMentionInfo: !showMentionInfo });
  };

  getApproversName = (linkedInPost) => {
    if (linkedInPost?.approvedBy) {
      return `Approved by  ${
        linkedInPost.approvedBy.name || linkedInPost.approvedBy.email || ""
      } `;
    }

    if (linkedInPost?.approvedByExternal?.length) {
      return `Approved by  ${
        linkedInPost?.approvedByExternal[0].name ||
        linkedInPost?.approvedByExternal[0].email ||
        ""
      }${
        linkedInPost?.approvedByExternal?.length > 1
          ? ` and ${
              linkedInPost?.approvedByExternal?.length - 1
            } other${handleSingular(
              linkedInPost?.approvedByExternal?.length - 1
            )}`
          : ""
      }`;
    }
  };

  generateActionButtons = () => {
    let {
      isApprover,
      postInitialized,
      linkedInPosts,
      approvalRequested,
      post,
      publication,
      externalApproverId
    } = this.state;
    let accountAndTextAdded = true;
    let disablePublish = false;

    for (let linkedInPost of linkedInPosts) {
      if (linkedInPost?.accounts?.length > 1) {
        //if multiple accounts are selected, then we can't publish. publish by approver only
        disablePublish = true;
        break;
      }
      if (!linkedInPost.account) {
        accountAndTextAdded = false;
        break;
      }
    }

    // If the post is a video post and it has been published, then we can't publish it again
    if (
      linkedInPosts[0]?.status === socialMediaStatus.COMPLETED &&
      linkedInPosts[0]?.mediaFile?.type === mediaFileType.VIDEO
    ) {
      disablePublish = true;
    }

    let buttonLabel = "Publish";
    if (
      linkedInPosts[0]?.publishType !== publishTypes.NOW &&
      linkedInPosts[0]?.status !== socialMediaStatus.COMPLETED
    ) {
      buttonLabel = "Schedule";
    }
    if (postInitialized) {
      buttonLabel = "Publishing...";
    } else if (
      linkedInPosts[0]?.approvedBy ||
      (isApprover && approvalRequested)
    ) {
      buttonLabel = `Approve and ${buttonLabel}`;
    }

    let isPublishAccessDisabled = externalApproverId
      ? false
      : isAccessDisabled(
          publication._id,
          this.context,
          pageAccess.PUBLISH_POST
        );

    let approved = Boolean(
      linkedInPosts[0]?.approvedBy ||
        linkedInPosts[0]?.approvedByExternal?.length ||
        false
    );
    let displayReviewDetails =
      !approved && linkedInPosts[0]?.approvalRequestedBy?.name;
    let cannotPublish = approvalRequested && !isApprover && !externalApproverId;
    let showGetApprovalButtonPrimary = isPublishAccessDisabled && !approved; // if I can't publish, I'm forced to wait for a review
    let showGetApprovalButtonSecondary = !isPublishAccessDisabled && !approved; // if I can publish but have requested a review
    // If Linkedin post doesn't have an associated post, then it can be published directly
    let isAssociatedPostPublished = linkedInPosts[0]?.post
      ? post?.status === postStatus.PUBLISHED
      : true;
    return (
      <div>
        {approved && (
          <Typography variant="bodys" style={{ marginRight: space.SMALL }}>
            {this.getApproversName(linkedInPosts[0])}
          </Typography>
        )}
        {displayReviewDetails && (
          <Typography variant="bodys" style={{ marginRight: space.SMALL }}>
            Review requested by {linkedInPosts[0]?.approvalRequestedBy?.name}
          </Typography>
        )}

        {showGetApprovalButtonSecondary && !externalApproverId && (
          <Tooltip
            title={!accountAndTextAdded ? "Select an account first" : ""}
          >
            <span>
              <StyledButton
                variant="textsecondary"
                onClick={this.showApprove}
                style={{ marginRight: space.SMALL }}
                disabled={!accountAndTextAdded}
              >
                {approvalRequested ? "Change Approver" : "Get Approved"}
              </StyledButton>
            </span>
          </Tooltip>
        )}

        {showGetApprovalButtonPrimary && !externalApproverId && (
          <Tooltip
            title={!accountAndTextAdded ? "Select an account first" : ""}
          >
            <span>
              <StyledButton
                onClick={this.showApprove}
                disabled={!accountAndTextAdded}
              >
                {approvalRequested ? "Change Approver" : "Get Approved"}
              </StyledButton>
            </span>
          </Tooltip>
        )}

        {!isPublishAccessDisabled && accountAndTextAdded ? (
          <Tooltip
            title={
              !isAssociatedPostPublished
                ? "Can't publish until associated blog post is live"
                : cannotPublish
                ? "Post is not approved"
                : ""
            }
          >
            {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
            {/* use tooltip on disabled button */}

            <span>
              {this.generatePublishButton(
                !isAssociatedPostPublished || cannotPublish || disablePublish,
                buttonLabel
              )}
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              !accountAndTextAdded
                ? "Select an account first"
                : "You cannot publish this post"
            }
          >
            <span>{this.generatePublishButton(true, buttonLabel)}</span>
          </Tooltip>
        )}
      </div>
    );
  };

  generatePublishButton(disabled, buttonLabel) {
    let { postInitialized } = this.state;
    return (
      <StyledButton
        onClick={this.approvePostDebounce}
        variant={"primary"}
        startIcon={postInitialized ? "" : <SendIcon size="small" />}
        disabled={disabled} // Can't publish until associated blog post is published
      >
        {postInitialized && (
          <CircularProgress
            size={20}
            color="inherit"
            style={{ marginRight: 10 }}
          />
        )}
        {buttonLabel}
      </StyledButton>
    );
  }

  requestLinkedInReview = (redirectAfterRequest: boolean) => {
    let { linkedInPosts, publication } = this.state;
    let { isReviewScreen = false } = this.props;
    api.requestLinkedInReview(
      publication._id,
      this.sanitizeLinkedInPosts(linkedInPosts),
      (res: any) => {
        if (res.status === 200) {
          notify.show("Notified approvers about the request.", "success");
          if (!isReviewScreen && redirectAfterRequest) {
            this.props.history.push(
              `/${publication._id}/create/linkedin?tab=in_review`
            );
          }
        }
        if (res.status === 400) {
          notify.show(
            "Problem while notifying approvers. Please try again.",
            "error"
          );
        }
      }
    );
  };
  requestLinkedInReviewMultipleAccount = (
    accounts,
    approvers,
    redirectAfterRequest: boolean
  ) => {
    let { linkedInPosts, publication } = this.state;
    let { isReviewScreen = false } = this.props;
    api.requestLinkedInReviewMultipleAccount(
      publication._id,
      this.sanitizeLinkedInPosts(linkedInPosts),
      accounts,
      approvers,
      (res: any) => {
        if (res.status === 200) {
          notify.show("Notified approvers about the request.", "success");
          if (!isReviewScreen && redirectAfterRequest) {
            this.props.history.push(
              `/${publication._id}/create/linkedin?tab=in_review`
            );
          }
        }
        if (res.status === 400) {
          notify.show(
            "Problem while notifying approvers. Please try again.",
            "error"
          );
        }
      }
    );
  };

  setIntervalToFetchSocialPost = (
    index: number,
    isFreeFormInput: boolean = false
  ) => {
    if (this.fetchSocialPost[index]) {
      return;
    }

    this.fetchSocialPost[index] = setInterval(() => {
      this.generateSocialPost(index, false, isFreeFormInput); // regenerate = false
    }, 5000);
  };

  clearIntervalToFetchSocialPost = (index: any) => {
    clearInterval(this.fetchSocialPost[index]);
    this.fetchSocialPost[index] = null;
  };

  showAIGeneratePostDialog = (generatePostIndex: number) => {
    this.setState({
      showAIGeneratePostDialog: true,
      generatePostIndex
    });
  };

  closeAIGeneratePostDialog = (index) => {
    this.setState({
      showAIGeneratePostDialog: false,
      generatePostIndex: null,
      generatingLinkedInPost: false
    });

    this.clearFetchSocialPostFromLinkInterval(index);
  };

  onAIPostGenerate = (index: number, value: string) => {
    if (value === "url") {
      this.onSourceLinkSubmit(index);
      return;
    } else {
      this.generateSocialPost(index, true, true);
    }

    this.closeAIGeneratePostDialog(index);
  };

  setTemplate = (inputField: string, value: string) => {
    this.setState({ [inputField]: value });
  };

  generateSocialPost = (
    index: number,
    regenerate: boolean = true,
    isFreeFormInput: boolean = false
  ) => {
    let {
      post,
      linkedInPosts,
      prevSocialPostText,
      freeInputSource,
      instructions,
      publication
    } = this.state;
    linkedInPosts[index].socialPostLoading = true;
    this.setState({
      linkedInPosts,
      socialPost: null
    });
    let socialPostId = isFreeFormInput ? linkedInPosts[index]?._id : post?._id;
    let socialPostSourceBody = isFreeFormInput
      ? freeInputSource
      : post?.htmlText;

    api.generateSocialPostFromBlog(
      publication._id,
      socialPostId,
      socialPostSourceBody,
      regenerate,
      socialMediaType.LINKED_IN,
      instructions,
      linkedInPosts[index]?.account || "",
      linkedInPosts[index]?.samples || [], // samples
      (res: any) => {
        if (res.status === 200) {
          let socialPost = res.data.socialPost;

          // If not completed, create a interval to fetch the social post
          if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
            this.setIntervalToFetchSocialPost(index, isFreeFormInput);
            return;
          }
          this.clearIntervalToFetchSocialPost(index);

          if (!socialPost.body) {
            linkedInPosts[index].socialPostLoading = false;
            this.setState({
              linkedInPosts
            });
            return;
          }

          prevSocialPostText.push(linkedInPosts[index]?.body || "");
          linkedInPosts[index].socialPostLoading = false;
          this.setState({
            prevSocialPostText,
            linkedInPosts
          });

          this.updateLinkedinPostContent(index, socialPost.body);
        } else {
          linkedInPosts[index].socialPostLoading = false;
          this.setState({
            linkedInPosts
          });
          this.clearIntervalToFetchSocialPost(index);
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Try again.",
            "error"
          );
        }
      }
    );
  };

  saveSamples = (index: any, samples: any) => {
    let { linkedInPosts } = this.state;
    linkedInPosts[index].samples = samples?.map((sample: any) => sample?._id);
    this.setState({ linkedInPosts }, () => {
      this.saveLinkedInDetailsDebounce(index);
    });
  };

  updateLinkedinPostContent = (index: any, content: any) => {
    let { linkedInPosts } = this.state;
    try {
      const quillRef =
        linkedInPosts[index]?.linkedInPostRef?.current?.getQuillRef?.();
      if (quillRef) {
        quillRef?.getEditor?.()?.setContents?.([]);
        quillRef
          ?.getEditor?.()
          ?.clipboard?.dangerouslyPasteHTML(
            content?.replace(NEWLINE_REGEX, BR_TAG)
          );
        notify.show("Editor content updated", "success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  copyEverywhere = () => {
    let { linkedInPosts, copyIndex } = this.state;
    this.setState({ imageUploadComplete: false });
    for (let index = 0; index < linkedInPosts.length; index++) {
      if (linkedInPosts[index].status !== socialMediaStatus.COMPLETED) {
        linkedInPosts[index].accountsToAutoComment =
          linkedInPosts[copyIndex].accountsToAutoComment;
        linkedInPosts[index].autoCommentTemplate =
          linkedInPosts[copyIndex].autoCommentTemplate;
        linkedInPosts[index].automateLikeAccounts =
          linkedInPosts[copyIndex].automateLikeAccounts;
        linkedInPosts[index].body = linkedInPosts[copyIndex].body;
        linkedInPosts[index].bodyPreview = linkedInPosts[copyIndex].bodyPreview;
        linkedInPosts[index].comments = linkedInPosts[copyIndex].comments;
        linkedInPosts[index].includeCoverPhoto =
          linkedInPosts[copyIndex].includeCoverPhoto;
        linkedInPosts[index].isAllAccountsSelectedForAutoComments =
          linkedInPosts[copyIndex].isAllAccountsSelectedForAutoComments;
        linkedInPosts[index].isAllAccountsSelectedForAutoLikes =
          linkedInPosts[copyIndex].isAllAccountsSelectedForAutoLikes;
        linkedInPosts[index].isDirty = true;
        linkedInPosts[index].embed = linkedInPosts[copyIndex].embed;
        linkedInPosts[index].includeEmbed =
          linkedInPosts[copyIndex].includeEmbed;
        linkedInPosts[index].mediaFile = linkedInPosts[copyIndex].mediaFile;
        linkedInPosts[index].publishType = linkedInPosts[copyIndex].publishType;
        linkedInPosts[index].scheduledOn = linkedInPosts[copyIndex].scheduledOn;

        this.setState({ linkedInPosts }, () => this.saveLinkedInDetails(index));
      }
    }
    this.setState({ imageUploadComplete: true }, () =>
      this.toggleShowCopyConfirmation(DEFAULT_INDEX)
    );
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  showInstruction = (showInstructionDialog: any, index = 0) => {
    this.setState({
      showInstructionDialog,
      instructionDialogIndex: index
    });
  };

  onSaveInstructions = (instructions: any) => {
    this.setState({
      instructions: instructions.trim(),
      showInstructionDialog: false
    });
  };

  addComment = (index: any) => {
    let { linkedInPosts } = this.state;
    let newComment = [
      {
        comment: "",
        userId: linkedInPosts[index]?.account || "",
        commentPreview: ""
      }
    ];
    linkedInPosts[index].comments = [
      ...linkedInPosts[index].comments,
      ...newComment
    ];

    this.setState({ linkedInPosts }, () => {
      this.saveLinkedInDetailsDebounce(index);
    });
  };

  deleteComment = (linkedinPostIndex: any, commentIndex: any) => {
    let { linkedInPosts } = this.state;
    let { comments } = linkedInPosts[linkedinPostIndex];
    comments.splice(commentIndex, 1);
    linkedInPosts[linkedinPostIndex].comments = comments;
    this.setState({ linkedInPosts }, () => {
      this.saveLinkedInDetailsDebounce(linkedinPostIndex);
    });
  };

  getApprovers = () => {
    let { teammembers, linkedInHandle } = this.state;

    let selectedLinkedinAccounts = [];
    linkedInHandle?.accounts?.forEach((linkedInPostAccount: account) => {
      if (
        teammembers.some(
          (teammate) =>
            teammate.client?.email === linkedInPostAccount.email ||
            teammate.client?.name === linkedInPostAccount.name
        )
      ) {
        return;
      }

      selectedLinkedinAccounts.push({
        userId: linkedInPostAccount.userId,
        client: {
          name: linkedInPostAccount.name || "",
          email: linkedInPostAccount.email,
          profilePic: linkedInPostAccount.profilePicture
        }
      });
    });

    return [...teammembers, ...selectedLinkedinAccounts];
  };

  getApproversForMultipleAccount = () => {
    let { teammembers, linkedInPosts, linkedInHandle } = this.state;

    let selectedLinkedinAccounts = [];
    linkedInPosts.forEach(
      ({ accounts: linkedInPostAccounts }: { accounts: any }) => {
        let accounts = linkedInHandle?.accounts?.filter((account) =>
          linkedInPostAccounts.includes(account.userId)
        );

        accounts = accounts?.filter((account) => {
          return !teammembers.some(
            (teammate) =>
              teammate.client?.email === account.email ||
              teammate.client?.name === account.name
          );
        });

        if (!accounts?.length) {
          return;
        }
        for (let account of accounts) {
          selectedLinkedinAccounts.push({
            userId: account.userId,
            client: {
              name: account.name || "",
              email: account.email,
              profilePic: account.profilePicture
            }
          });
        }
      }
    );

    return [...teammembers, ...selectedLinkedinAccounts];
  };

  getCommentBox = (linkedinPostIndex: any, commentIndex: any) => {
    let { classes } = this.props;

    let { publication, linkedInHandle } = this.state;
    const linkedInPosts = [...this.state.linkedInPosts];
    let isPostPublished =
      linkedInPosts[linkedinPostIndex].status === socialMediaStatus.COMPLETED;

    let comment = {
      ...linkedInPosts[linkedinPostIndex]?.comments[commentIndex]
    };
    let accounts = [
      ...((linkedInHandle.accounts || []).filter((account: any) =>
        account.permissions.includes(LinkedInPermissionEnums.COMMENT)
      ) || []),
      ...(linkedInHandle.organizations || [])
    ];

    if (comment && !comment.userId) {
      comment.userId =
        linkedInPosts[linkedinPostIndex]?.account || accounts[0]?.userId || "";
    }

    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
          marginBottom: space.MEDIUM
        }}
      >
        <Grid>
          <Grid
            style={{
              display: "inline-block",
              transition: "background-color 0.2s ease-in-out"
            }}
          >
            <IconButton
              onClick={(event) => {
                comment.commentAnchorEl = event.currentTarget;
                let { comments } = linkedInPosts[linkedinPostIndex];
                comments[commentIndex] = comment;
                linkedInPosts[linkedinPostIndex].comments = comments;
                this.setState(
                  {
                    linkedInPosts
                  },
                  () => {
                    this.saveLinkedInDetailsDebounce(linkedinPostIndex);
                  }
                );
              }}
            >
              <StyledAvatar
                src={
                  accounts?.find((account) => {
                    return account.userId === comment?.userId;
                  })?.profilePicture
                }
                alt={
                  accounts?.find((account) => {
                    return account.userId === comment?.userId;
                  })?.name
                }
              />
            </IconButton>
          </Grid>

          <Menu
            PaperProps={{
              style: {
                borderRadius: "6px",
                marginTop: space.XXS,
                padding: space.SMALL
              }
            }}
            anchorEl={comment?.commentAnchorEl}
            keepMounted
            open={Boolean(comment?.commentAnchorEl)}
            onClose={() => {
              delete comment.commentAnchorEl;
              linkedInPosts[linkedinPostIndex].comments[commentIndex] = comment;
              this.setState(
                {
                  linkedInPosts
                },
                () => {
                  this.saveLinkedInDetailsDebounce(linkedinPostIndex);
                }
              );
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            {/* Loop through the accounts to create menu items */}
            {accounts
              ?.filter((account) => account.connected)
              ?.map((account) => (
                <MenuItem
                  sx={{
                    padding: space.SMALL,
                    borderRadius: "6px",
                    marginBottom: "3px"
                  }}
                  disabled={isPostPublished}
                  key={account.userId}
                  onClick={() => {
                    comment.userId = account.userId;
                    delete comment.commentAnchorEl;
                    linkedInPosts[linkedinPostIndex].comments[commentIndex] =
                      comment;
                    this.setState(
                      {
                        commentAnchorEl: null,
                        linkedInPosts
                      },
                      () => {
                        this.saveLinkedInDetailsDebounce(linkedinPostIndex);
                      }
                    );
                  }}
                >
                  <ListItemAvatar>
                    <StyledAvatar
                      src={account.profilePicture}
                      alt={account.name}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Typography variant="bodym">{account.name}</Typography>
                    }
                  />
                </MenuItem>
              ))}
          </Menu>
        </Grid>

        <Grid
          style={{
            width: "100%",
            marginLeft: space.SMALL
          }}
        >
          <LinkedInInputBox
            account={linkedInPosts[linkedinPostIndex].account}
            value={comment?.htmlComment || comment?.comment}
            publication={publication}
            readOnly={isPostPublished}
            onChange={(content: any) => {
              comment.htmlComment = content;
              comment.comment = content;
              linkedInPosts[linkedinPostIndex].comments[commentIndex] = comment;
              this.setState({ linkedInPosts }, () => {
                this.convertAtMentionToOrganization(
                  linkedInContentType.POST_COMMENT,
                  linkedinPostIndex,
                  commentIndex
                );
              });
            }}
            isValueProp
          />
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <StyledButton
              variant="textdestructive"
              style={{ marginTop: space.XXS }}
              disabled={isPostPublished}
              onClick={() =>
                this.deleteComment(linkedinPostIndex, commentIndex)
              }
            >
              Delete
            </StyledButton>

            <Typography className={classes.charCount}>
              {formatNumber(
                getTextLengthOfHTML(comment?.htmlComment || comment?.comment)
              )}{" "}
              / {formatNumber(LINKEDIN_COMMENT_CHAR_LIMIT)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  generateCampaign = () => {
    let { postId } = this.props;
    let { numberOfPostsForCampaign, accountsForCampaign, publication } =
      this.state;

    let campaignId = uuid();
    api.generateLinkedInCampaign(
      publication._id,
      postId,
      campaignId,
      numberOfPostsForCampaign,
      accountsForCampaign,
      (res: any) => {
        if (res.status === 200) {
          notify.show(
            "Your LinkedIn posts will appear here in a few minutes",
            "success"
          );
          this.setState({
            openGenerateCampaignDialog: false
          });
        } else {
          notify.show("Oops, something went wrong. Try again.", "error");
        }
      }
    );
  };

  toggleApprovalRequiredForComments = (isChecked: boolean, index: number) => {
    try {
      let { linkedInPosts } = this.state;
      linkedInPosts[index].approvalRequiredForComments = isChecked || false;
      this.setState({ linkedInPosts }, () =>
        this.saveLinkedInDetailsDebounce(index)
      );
    } catch (error) {}
  };

  onAutoLikeTimeConfigChange = (value: string, index: number) => {
    let { linkedInPosts } = this.state;
    linkedInPosts[index].autoLikePostTiming = value;
    this.setState(
      {
        linkedInPosts
      },
      () => this.saveLinkedInDetailsDebounce(index)
    );
  };

  generatePostFromVideo = (index: number, selectedSampleId: string) => {
    let { linkedInPosts } = this.state;

    linkedInPosts[index].samples = [selectedSampleId];

    this.setState(
      {
        sourceLink: linkedInPosts[index].mediaFile?.url,
        linkedInPosts,
        showPostFromVideoDialog: false,
        generatePostIndex: null
      },
      () => {
        this.getSocialPostFromLink(index);
      }
    );
  };

  saveSalesCallInsights = (salesCallData: any) => {
    let { publication, salesCallInsightsData, linkedInPosts } = this.state;

    linkedInPosts[0].fetchingSocialPostFromLink = true;
    api.updateSalesCallInsights(
      publication?._id,
      salesCallData?.callId,
      salesCallData?.socialInsightId,
      salesCallInsightsData,
      (res: any) => {
        if (res.status === 200) {
          let { body, referenceTemplate } = res.data;
          let { salesCallInsightsData } = this.state;
          let index = 0;
          this.updateLinkedinPostContent(index, body);
          salesCallInsightsData.referenceTemplate = referenceTemplate;
          linkedInPosts[0].fetchingSocialPostFromLink = false;
          this.setState({
            linkedInPosts,
            salesCallInsightsData,
            isInsightDataUpdated: false
          });
          notify.show(
            "Your LinkedIn post has been updated with the new insights.",
            "success"
          );
        } else {
          notify.show("Oops, something went wrong. Try again.", "error");
        }
      }
    );

    this.setState({
      isInsightDataUpdated: false
    });
  };

  renderSalesCallMetadata = (
    salesCallData: any,
    accountName: string,
    accountPicture: string
  ) => {
    let { publication, isInsightDataUpdated, salesCallInsightsData } =
      this.state;
    return (
      <Paper
        sx={{
          p: space.MEDIUM,
          mt: space.L
        }}
      >
        {renderCallMetaData({
          callMetaData: salesCallInsightsData,
          disableMaxHeight: true,
          showBackIcon: false,
          handleBackIconClick: () => {},
          handleSalesRepAnswerInput: (event) => {
            let repAnswer = event.target.value;
            if (!salesCallInsightsData) {
              return;
            }
            salesCallInsightsData.repAnswer = repAnswer;
            salesCallInsightsData.useRepAnswer = repAnswer ? true : false;
            this.setState({
              salesCallInsightsData,
              isInsightDataUpdated: true
            });
          },
          handleAiAnswerCheckbox: (event) => {
            if (!salesCallInsightsData) {
              return;
            }
            salesCallInsightsData.useAiAnswer = event.target.checked;
            this.setState({
              salesCallInsightsData,
              isInsightDataUpdated: true
            });
          },
          handleReferenceContentCheckbox: (event, index) => {
            if (!salesCallInsightsData?.referenceContent?.[index]) {
              return;
            }
            salesCallInsightsData.referenceContent[index].useReference =
              event.target.checked;
            this.setState({
              salesCallInsightsData,
              isInsightDataUpdated: true
            });
          }
        })}
        <Typography
          variant="h200"
          component="h4"
          mt={space.MEDIUM}
          mb={space.XS}
        >
          Template used
        </Typography>
        <LinkedInTemplateDropdown
          publicationId={publication._id}
          linkedInAccountName={accountName}
          linkedInAccountPicture={accountPicture}
          initialSelectedSample={salesCallInsightsData?.referenceTemplate}
          onChange={(event: any, samples: any) => {
            let sample = samples.find(
              (sample: any) => sample._id === event.target.value
            )?.sample;
            salesCallInsightsData.referenceTemplate = sample;
            salesCallInsightsData.useReferenceTemplate = sample ? true : false;

            this.setState({
              salesCallInsightsData,
              isInsightDataUpdated: true
            });
          }}
          suggestedSampleIds={salesCallInsightsData.suggestedSampleIds || []}
        />
        {isInsightDataUpdated && (
          <Grid
            style={{
              padding: space.SMALL,
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <StyledButton
              variant="primary"
              onClick={() => this.saveSalesCallInsights(salesCallData)}
            >
              Regenerate
            </StyledButton>
          </Grid>
        )}
      </Paper>
    );
  };

  render() {
    let {
      classes,
      isReviewScreen = false,
      showPostCoverPhoto,
      thumbnail,
      coverImage,
      postId
    } = this.props;

    let {
      post,
      publication,
      linkedInHandle,
      slug,
      loading,
      error,
      errorMessage,
      openImagePicker,
      showDeleteConfirmDialog,
      linkedInPosts,
      showDateTimePicker,
      linkedInPostIndex,
      uploading,
      draftsCount,
      linkedInPostApprovers,
      showApproverDialog,
      showMentionInfo,
      hostName,
      updateBody,
      uploadingThumbnailForVideo,
      imageUploadComplete,
      showCopyConfirmation,
      scheduledPostDates,
      sourceLink,
      showInstructionDialog,
      instructions,
      instructionDialogIndex,
      isReactionMenuOpen,
      showConfirmScheduleDateDialog,
      approvalRequested,
      selectedScheduledDate,
      isBlogDestinationAssinged,
      showAIGeneratePostDialog,
      generatingLinkedInPost,
      socialPost,
      freeInputSource,
      openGenerateCampaignDialog,
      numberOfPostsForCampaign,
      accountsForCampaign,
      accountsForLike,
      accounts,
      isMultiAccountPost,
      groups,
      externalApproverId,
      generatePostIndex,
      showPostFromVideoDialog,
      loadingUrlMetaData,
      linkedinPostSuggestedTemplateIds,
      clientTimezone
    } = this.state;
    let hasLinkedIn = linkedInHandle?.accounts.length > 0;
    let showAdditionalPublishType =
      post &&
      ((post.status && post.status !== postStatus.PUBLISHED) ||
        post.scheduledOn);

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    if (loading) {
      return <Loading />;
    }

    let haveAccessToEmployeeAdvocacy = externalApproverId
      ? true
      : publication?._id
      ? hasAccess(
          features.EMPLOYEE_ADVOCACY_FOR_LINKEDIN,
          this.context,
          publication._id
        )
      : false;

    if (!hasLinkedIn) {
      return (
        <Container
          style={{
            padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
          }}
        >
          {!isReviewScreen && (
            <>
              <Helmet>
                <title>
                  LinkedIn {publication && "- " + publication.name} | Letterdrop
                </title>
              </Helmet>
            </>
          )}

          <Paper
            className={clsx(
              isReviewScreen ? classes.paper : classes.reducedPaperMargin
            )}
          >
            <div className={classes.header}>
              <FontAwesomeIcon
                icon={faLinkedinIn}
                title={"linkedin"}
                className={classes.headerIcon}
              />

              <Typography variant="h500" className={classes.headerText}>
                Create a LinkedIn Post
              </Typography>
            </div>

            <Grid
              container
              direction="column"
              justifyContent="center"
              style={{ padding: "50px 20px" }}
            >
              <Grid item className={classes.marginAuto}>
                <img
                  src={ImagesList.socialMedia}
                  className={classes.connectAccountImage}
                  alt="Connect linkedin account"
                />
              </Grid>

              <Grid item className={classes.connectMessage}>
                <Typography>
                  Connect a LinkedIn account to start posting.
                </Typography>
              </Grid>

              <Grid item className={classes.marginAuto}>
                <StyledButton
                  style={{
                    background: colors.linkedin,
                    color: colors.white
                  }}
                  disabled={firebase.auth().currentUser ? false : true}
                  onClick={this.handleLinkedInConnect}
                  startIcon={
                    <FontAwesomeIcon icon={faLinkedinIn} title={"linkedin"} />
                  }
                >
                  Connect to LinkedIn
                </StyledButton>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      );
    }

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                LinkedIn {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            !isReviewScreen ? classes.reducedPaperMargin : classes.paper
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.header}>
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  title={"linkedin"}
                  className={classes.headerIcon}
                />

                <Typography variant="h500" className={classes.headerText}>
                  Create a LinkedIn Post
                </Typography>
              </div>

              <div className={classes.headerBar}>
                <div className={classes.draftDiv}>
                  {!externalApproverId && (
                    <StyledButton
                      className={classes.linkedInDraft}
                      variant="textsecondary"
                      size="small"
                      onClick={() => {
                        this.props.history.push(
                          `/${publication._id}/create/linkedin`
                        );
                      }}
                    >
                      Drafts{" "}
                      <Typography
                        variant="h100"
                        style={{
                          marginLeft: 5,
                          color: designColors.grayScale[80]
                        }}
                      >
                        {draftsCount}
                      </Typography>
                    </StyledButton>
                  )}
                </div>

                <div className={classes.postButtonSection}>
                  {this.generateActionButtons()}
                </div>
              </div>
              {post?.title && (
                <Alert
                  variant="outlined"
                  className={classes.alertAction}
                  action={
                    <StyledButton
                      variant="textprimary"
                      style={{ marginRight: space.SMALL }}
                      onClick={() => {
                        window.location.href = `/${publication._id}/posts/${post._id}`;
                      }}
                    >
                      Go to post
                    </StyledButton>
                  }
                  severity="info"
                >
                  This goes out with {post?.title}
                </Alert>
              )}
            </>
          )}

          {isReviewScreen && (
            <Grid
              style={{
                marginTop: space.SMALL,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <StyledButton
                variant="secondary"
                className={classes.copyButton}
                onClick={() => {
                  this.setState({ openGenerateCampaignDialog: true });
                }}
              >
                Generate campaign
              </StyledButton>
            </Grid>
          )}

          {linkedInPosts.map((linkedInPost: any, index: any) => {
            let postURLWithUTM =
              slug +
              "?utm=" +
              (linkedInPost.utm ? linkedInPost.utm : nanoid(10));

            return (
              <>
                {index !== 0 && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    xs={12}
                    style={{ padding: "10px 0px" }}
                  >
                    <Divider style={{ width: "100%" }} />
                  </Grid>
                )}

                <Grid
                  xs={12}
                  md={12}
                  container
                  direction="row"
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    xs={12}
                    md={12}
                    lg={6}
                    style={{
                      display: "block",
                      paddingRight: 55
                    }}
                  >
                    <Fragment key={index}>
                      <div container>
                        {linkedInPost.status === socialMediaStatus.COMPLETED &&
                          linkedInPost.mediaFile?.type !==
                            mediaFileType.VIDEO && (
                            <div className={classes.editableLabelDiv}>
                              <Typography className={classes.editableLabel}>
                                You can update this post on LinkedIn by
                                publishing again
                              </Typography>
                            </div>
                          )}
                        {linkedInPost.status === socialMediaStatus.COMPLETED &&
                          linkedInPost.mediaFile?.type ===
                            mediaFileType.VIDEO && (
                            <div className={classes.editableLabelDiv}>
                              <Typography className={classes.editableLabel}>
                                This post contains a video and can't be updated.
                              </Typography>
                            </div>
                          )}

                        {linkedInPost.status !== socialMediaStatus.COMPLETED &&
                          Boolean(linkedInPost.salesCallData) && (
                            <LinkedInPostDraftTimer />
                          )}

                        <Grid item className={classes.section}>
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            Account
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            Which user's feed or company page do you want to
                            post to?
                          </Typography>

                          <div>
                            <LinkedInAutoComplete
                              accounts={accounts}
                              organizations={linkedInHandle?.organizations}
                              groups={groups}
                              value={this.getAccountsForSingleLinkedinPost(
                                linkedInPost,
                                linkedInHandle
                              )}
                              disabled={
                                linkedInPost.status ===
                                socialMediaStatus.COMPLETED
                              }
                              onChange={this.onAutocompleteChangeForAccounts}
                              size="large"
                              optionIndex={index}
                            />
                          </div>
                        </Grid>

                        <Grid item className={classes.section}>
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            Body
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            You can
                            <span className={classes.mentionText}>
                              {" @mention   "}
                            </span>
                            any company using their LinkedIn identifier but only
                            people who've engaged with your content over 24 hrs
                            ago
                            <InfoIcon onClick={this.toggleShowMentionInfo} />
                          </Typography>

                          <div className={classes.urlContainer}>
                            <div
                              style={{
                                width: "100%"
                              }}
                            >
                              <LinkedInInputBox
                                account={linkedInPost.account}
                                ref={linkedInPost.linkedInPostRef}
                                value={
                                  linkedInPost.htmlText || linkedInPost.body
                                }
                                publication={publication}
                                readOnly={
                                  linkedInPost.status ===
                                    socialMediaStatus.COMPLETED &&
                                  linkedInPost.mediaFile?.type ===
                                    mediaFileType.VIDEO
                                }
                                characterLimit={LINKEDIN_CHAR_LIMIT}
                                placeholder="Begin Writing..."
                                onChange={(content: any) => {
                                  this.handleContentChange(
                                    {
                                      target: {
                                        name: linkedInContentType.POST_BODY,
                                        value: content
                                      }
                                    },
                                    index
                                  );
                                }}
                                isPostBody={true}
                                onKeyDown={(e: any) => {
                                  // if ctrl + z pressed
                                  if (e.ctrlKey && e.keyCode === 90) {
                                    let { prevSocialPostText } = this.state;
                                    if (prevSocialPostText.length) {
                                      let oldValue = prevSocialPostText.pop();
                                      this.setState({ prevSocialPostText });
                                      this.changePostBody(oldValue, index);
                                    }
                                  }
                                }}
                              />
                            </div>

                            <div>
                              {(linkedInPost?.socialPostLoading ||
                                linkedInPost?.fetchingSocialPostFromLink) && (
                                <CircularProgress
                                  style={{
                                    marginLeft: 20,
                                    marginRight: -60
                                  }}
                                  size={25}
                                />
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            {!externalApproverId && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <Tooltip
                                  title={
                                    linkedInPost.account
                                      ? ""
                                      : "Select an account first"
                                  }
                                >
                                  <div style={{ marginLeft: space.SMALL }}>
                                    <StyledButton
                                      className={classes.charCount}
                                      startIcon={<AutoFixHighOutlinedIcon />}
                                      variant="primary"
                                      size="small"
                                      disabled={
                                        linkedInPost.account ? false : true
                                      }
                                      onClick={() =>
                                        isReviewScreen
                                          ? this.generateSocialPost(index)
                                          : this.showAIGeneratePostDialog(index)
                                      }
                                    >
                                      Generate LinkedIn post
                                    </StyledButton>
                                  </div>
                                </Tooltip>

                                <StyledButton
                                  style={{
                                    marginLeft: space.SMALL,
                                    marginTop: space.SMALL
                                  }}
                                  className={classes.charCount}
                                  startIcon={<PeopleOutlineIcon />}
                                  variant="textsecondary"
                                  size="small"
                                  onClick={() =>
                                    this.showInstruction(true, index)
                                  }
                                >
                                  Personalize with template
                                </StyledButton>
                              </div>
                            )}

                            <div>
                              <Typography className={classes.charCount}>
                                {formatNumber(
                                  getTextLengthOfHTML(
                                    linkedInPost?.body || linkedInPost?.htmlText
                                  )
                                )}{" "}
                                / {formatNumber(LINKEDIN_CHAR_LIMIT)}
                              </Typography>
                            </div>
                          </div>

                          {linkedInPost.status !==
                            socialMediaStatus.COMPLETED && (
                            <>
                              <div style={{ display: "inline-block" }}>
                                <Tooltip
                                  title={
                                    linkedInPost.includeCoverPhoto ||
                                    linkedInPost.includeEmbed
                                      ? "You can't upload a picture if you embed the article"
                                      : "Upload a picture"
                                  }
                                >
                                  <span>
                                    <IconButton
                                      size="medium"
                                      disabled={
                                        linkedInPost.includeCoverPhoto ||
                                        linkedInPost.includeEmbed ||
                                        loadingUrlMetaData
                                      }
                                      onClick={() => {
                                        if (
                                          linkedInPost.includeCoverPhoto ||
                                          linkedInPost.includeEmbed ||
                                          loadingUrlMetaData
                                        ) {
                                          return;
                                        }
                                        this.toggleImagePicker(index);
                                      }}
                                    >
                                      <ImageIcon
                                        className={classes.imagePickerIcon}
                                      />
                                    </IconButton>
                                  </span>
                                </Tooltip>

                                {isReviewScreen || linkedInPost?.post ? (
                                  <Tooltip
                                    title={
                                      linkedInPost?.mediaFile?.url
                                        ? "Remove the picture to embed article"
                                        : !isBlogDestinationAssinged
                                        ? "Choose a destination for your blog post to embed a link in your LinkedIn post"
                                        : ""
                                    }
                                  >
                                    <FormControlLabel
                                      control={<StyledSwitch size="small" />}
                                      style={{ marginLeft: 20 }}
                                      checked={linkedInPost.includeCoverPhoto}
                                      onChange={() =>
                                        this.toggleLinkedinEmbed(index)
                                      }
                                      variant="bodys"
                                      disabled={
                                        linkedInPost?.mediaFile?.url ||
                                        !isBlogDestinationAssinged
                                      }
                                      label="Embed article in LinkedIn post"
                                    />
                                  </Tooltip>
                                ) : linkedInPost?.embedUrl ? (
                                  <Tooltip
                                    title={
                                      linkedInPost?.mediaFile?.url
                                        ? "Remove the picture to embed article"
                                        : ""
                                    }
                                  >
                                    <FormControlLabel
                                      control={<StyledSwitch size="small" />}
                                      style={{
                                        marginLeft: space.MEDIUM
                                      }}
                                      variant="bodys"
                                      checked={linkedInPost.includeEmbed}
                                      disabled={linkedInPost?.mediaFile?.url}
                                      onChange={() =>
                                        this.toggleLinkedinEmbed(index)
                                      }
                                      label="Embed article in LinkedIn post"
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                          {linkedInPost.mediaFile && (
                            <div className={classes.threadImageContainer}>
                              {linkedInPost.mediaFile.type ===
                                mediaFileType.IMAGE && (
                                <img
                                  alt="post cover"
                                  src={linkedInPost.mediaFile.url}
                                  className={classes.threadImage}
                                  width={"auto"}
                                  height={250}
                                />
                              )}
                              {linkedInPost.mediaFile.type ===
                                mediaFileType.VIDEO && (
                                <>
                                  <video
                                    controls
                                    src={linkedInPost.mediaFile.url}
                                    className={classes.threadImage}
                                    poster={
                                      linkedInPost.mediaFile
                                        ?.thumbnailForVideo || ""
                                    }
                                  />

                                  <StyledButton
                                    disabled={
                                      linkedInPost?.status ===
                                      socialMediaStatus.COMPLETED
                                    }
                                    variant="textsecondary"
                                    onClick={() => {
                                      this.setState(
                                        { uploadingThumbnailForVideo: true },
                                        () => {
                                          this.toggleImagePicker(index);
                                        }
                                      );
                                    }}
                                    startIcon={<InserthotoIcon />}
                                  >
                                    Change thumbnail
                                  </StyledButton>
                                </>
                              )}
                              {supportedExtensions.includes(
                                linkedInPost.mediaFile.type
                              ) && (
                                <Grid container direction="column">
                                  <Grid item>
                                    <FontAwesomeIcon
                                      icon={faFile}
                                      style={{ height: 50, width: 50 }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="bodys">
                                      {truncateStringAndAddEllipses(
                                        linkedInPost.mediaFile.name,
                                        6
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                              <IconButton
                                className={classes.closeIcon}
                                size={
                                  supportedExtensions.includes(
                                    linkedInPost.mediaFile.type
                                  )
                                    ? "small"
                                    : "large"
                                }
                                disableRipple={
                                  linkedInPost.status ===
                                  socialMediaStatus.COMPLETED
                                }
                                onClick={() => this.removePostImage(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          )}
                        </Grid>

                        <Grid
                          item
                          className={classes.section}
                          style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            Comment
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            Add comments to increase distribution.{" "}
                            <Link
                              target="_blank"
                              href="https://growthrocks.com/blog/linkedin-abtest-link-in-comment/"
                            >
                              Include links in comments
                            </Link>
                            , not the body. The first comment is immediate. The
                            rest come in over 30 mins.
                          </Typography>
                          {linkedInPost?.comments?.map(
                            (comment: any, commentIndex: any) => {
                              return this.getCommentBox(index, commentIndex);
                            }
                          )}

                          {linkedInPost?.comments?.length > 0 && (
                            <StyledCheckbox
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                this.toggleApprovalRequiredForComments(
                                  event.target.checked,
                                  index
                                )
                              }
                              checked={
                                linkedInPost.approvalRequiredForComments ||
                                false
                              }
                              disabled={
                                linkedInPost?.status ===
                                socialMediaStatus.COMPLETED
                              }
                              label="Get Approval for comments"
                            />
                          )}

                          <StyledButton
                            disabled={
                              linkedInPost?.status ===
                              socialMediaStatus.COMPLETED
                            }
                            variant="textprimary"
                            onClick={() => this.addComment(index)}
                            startIcon={<AddCircleIcon />}
                            style={{
                              width: "fit-content",
                              marginTop: space.MEDIUM
                            }}
                          >
                            Add Comment
                          </StyledButton>
                        </Grid>

                        <Grid item className={classes.section}>
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            Automate likes from these accounts
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            These accounts will automatically like your post. No
                            input needed from them.
                          </Typography>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between"
                            }}
                          >
                            <LinkedInAutoComplete
                              accounts={accountsForLike}
                              organizations={linkedInHandle?.organizations}
                              groups={groups}
                              value={linkedInPost.automateLikeAccounts}
                              disabled={
                                linkedInPost.status ===
                                  socialMediaStatus.COMPLETED ||
                                !haveAccessToEmployeeAdvocacy
                              }
                              onChange={this.onAutocompleteChange}
                              size="large"
                              optionIndex={index}
                            />

                            <IconButton
                              onMouseOver={(event) => {
                                this.setState((prevState) => {
                                  return {
                                    isReactionMenuOpen:
                                      !prevState.isReactionMenuOpen
                                  };
                                });
                                this.reactionRef = event.currentTarget;
                              }}
                            >
                              {
                                (
                                  reactionTypes.find(
                                    (reaction) =>
                                      reaction.name ===
                                      linkedInPost.automateLikeReaction
                                  ) || reactionTypes[0]
                                ).icon
                              }
                            </IconButton>

                            <Menu
                              PaperProps={{
                                style: {
                                  borderRadius: "6px",
                                  marginTop: space.XXS,
                                  padding: space.SMALL
                                }
                              }}
                              anchorEl={this.reactionRef}
                              keepMounted
                              open={
                                isReactionMenuOpen &&
                                linkedInPost.status !==
                                  socialMediaStatus.COMPLETED
                              }
                              onClose={() => {
                                this.reactionRef = null;
                                this.setState({
                                  isReactionMenuOpen: false
                                });
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                              }}
                            >
                              {reactionTypes.map((reaction) => (
                                <MenuItem
                                  key={reaction.name}
                                  onClick={() => {
                                    linkedInPost.automateLikeReaction =
                                      reaction.name;
                                    this.setState(
                                      {
                                        linkedInPosts,
                                        isReactionMenuOpen: false
                                      },
                                      () =>
                                        this.saveLinkedInDetailsDebounce(index)
                                    );
                                  }}
                                >
                                  <ListItemIcon
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      minWidth: "20px"
                                    }}
                                  >
                                    {reaction.icon}
                                  </ListItemIcon>
                                </MenuItem>
                              ))}
                            </Menu>
                          </Grid>

                          <AutoLikePostTimingSelect
                            value={linkedInPost.autoLikePostTiming || ""}
                            containerStyle={{
                              marginTop: space.LARGE
                            }}
                            onAutoLikeTimeConfigChange={(value: string) =>
                              this.onAutoLikeTimeConfigChange(value, index)
                            }
                          />
                        </Grid>

                        <Grid item className={classes.section}>
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            Request a comment or repost from these accounts
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            They'll receive an email and Slack notification to
                            comment on or repost this LinkedIn post
                          </Typography>

                          <LinkedInAutoComplete
                            accounts={linkedInHandle?.accounts}
                            groups={groups}
                            value={linkedInPost.accountsToAutoComment}
                            disabled={
                              linkedInPost.status ===
                                socialMediaStatus.COMPLETED ||
                              !haveAccessToEmployeeAdvocacy
                            }
                            onChange={this.onAutoCommentAutocompleteChange}
                            optionIndex={index}
                          />
                        </Grid>

                        <Grid item className={classes.section}>
                          <Typography className={classes.sectionHelper}>
                            Any instructions for your teammates on what to
                            comment on?
                          </Typography>

                          <LinkedInInputBox
                            account={linkedInPosts[index].account}
                            value={linkedInPost.autoCommentTemplate || ""}
                            publication={publication}
                            readOnly={
                              linkedInPost.status ===
                                socialMediaStatus.COMPLETED ||
                              !haveAccessToEmployeeAdvocacy
                            }
                            onChange={(content: any) => {
                              linkedInPosts[index][
                                linkedInContentType.POST_AUTOCOMMENT_TEMPLATE
                              ] = content;
                              this.setState({ linkedInPosts }, () => {
                                this.saveLinkedInDetails(index);
                              });
                            }}
                            isValueProp
                          />
                        </Grid>

                        {post.slug && (
                          <Grid item className={classes.section}>
                            <Typography
                              variant="bodyl"
                              className={classes.sectionHeading}
                            >
                              Link to your post
                            </Typography>

                            <Typography className={classes.sectionHelper}>
                              Copy this link into the comments of your post, or
                              use {URL_REPLACE_TEXT}, and we'll replace it with
                              the final URL.
                            </Typography>

                            <InputBase
                              className={classes.postUrl}
                              value={postURLWithUTM}
                              multiline={true}
                              contentEditable={false}
                              onFocus={(event) => event.target.select()}
                              fullWidth
                              endAdornment={
                                <InputAdornment position="end">
                                  <CopyToClipboard
                                    onCopy={() =>
                                      notify.show(
                                        "Copied to clipboard",
                                        "success"
                                      )
                                    }
                                    text={
                                      slug +
                                      "?utm=" +
                                      (linkedInPost.utm || nanoid(10))
                                    }
                                  >
                                    <IconButton size="large">
                                      <FileCopyOutlinedIcon />
                                    </IconButton>
                                  </CopyToClipboard>
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        )}

                        <Grid item className={classes.section}>
                          <Typography
                            variant="bodyl"
                            className={classes.sectionHeading}
                          >
                            When
                          </Typography>

                          <Typography className={classes.sectionHelper}>
                            Schedule when your LinkedIn post should go out
                          </Typography>

                          <div style={{ width: "100%" }}>
                            <FormControl
                              variant="outlined"
                              size="small"
                              className={classes.scheduleDropDown}
                              fullWidth
                            >
                              <StyledSelect
                                name={PUBLISH_TYPE}
                                value={
                                  !isReviewScreen &&
                                  linkedInPost.publishType ===
                                    publishTypes.WITH_POST
                                    ? publishTypes.NOW
                                    : linkedInPost.publishType
                                }
                                onChange={(event: any) =>
                                  this.handlePublishTypeChange(event, index)
                                }
                                size="large"
                                disabled={
                                  linkedInPost.status ===
                                  socialMediaStatus.COMPLETED
                                }
                              >
                                {!isReviewScreen && (
                                  <StyledMenuItem value={publishTypes.NOW}>
                                    Now
                                  </StyledMenuItem>
                                )}

                                <StyledMenuItem
                                  value={publishTypes.LATER}
                                  onClick={() => this.handlePublishLater(index)}
                                >
                                  {linkedInPost.publishType ===
                                    publishTypes.LATER &&
                                  linkedInPost.scheduledOn
                                    ? this.getScheduleDate(
                                        linkedInPost.scheduledOn
                                      )
                                    : "Later"}
                                </StyledMenuItem>
                                {isReviewScreen &&
                                  post?.status !== postStatus.PUBLISHED && (
                                    <StyledMenuItem
                                      value={publishTypes.WITH_POST}
                                    >
                                      Same as the blog post
                                    </StyledMenuItem>
                                  )}

                                {(showAdditionalPublishType ||
                                  linkedInPost.publishType ===
                                    publishTypes.TEN_MINUTES_LATER) && (
                                  <StyledMenuItem
                                    value={publishTypes.TEN_MINUTES_LATER}
                                  >
                                    10 minutes later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  linkedInPost.publishType ===
                                    publishTypes.THIRTY_MINUTES_LATER) && (
                                  <StyledMenuItem
                                    value={publishTypes.THIRTY_MINUTES_LATER}
                                  >
                                    30 minutes later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  linkedInPost.publishType ===
                                    publishTypes.HOUR_LATER) && (
                                  <StyledMenuItem
                                    value={publishTypes.HOUR_LATER}
                                  >
                                    1 hour later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  linkedInPost.publishType ===
                                    publishTypes.DAY_LATER) && (
                                  <StyledMenuItem
                                    value={publishTypes.DAY_LATER}
                                  >
                                    A day later
                                  </StyledMenuItem>
                                )}

                                {linkedInPost.publishType ===
                                  publishTypes.NEXT_AVAILABLE && (
                                  <StyledMenuItem
                                    value={publishTypes.NEXT_AVAILABLE}
                                  >
                                    {linkedInPost.scheduledOn
                                      ? this.getScheduleDate(
                                          linkedInPost.scheduledOn
                                        )
                                      : "Next available day without a post"}
                                  </StyledMenuItem>
                                )}
                              </StyledSelect>
                            </FormControl>
                          </div>
                        </Grid>
                      </div>
                    </Fragment>
                  </Grid>

                  <Grid
                    item
                    direction="row"
                    justifyContent="center"
                    xs={12}
                    md={12}
                    lg={6}
                    style={{
                      display: "block"
                    }}
                  >
                    <div
                      container
                      style={{ width: "100%", paddingLeft: space.MEDIUM }}
                    >
                      <LinkedInPreview
                        linkedInHandle={linkedInHandle}
                        linkedInPost={linkedInPost}
                        index={index}
                        isReviewScreen={isReviewScreen}
                        showPostCoverPhoto={
                          showPostCoverPhoto || post?.showPostCoverPhoto
                        }
                        thumbnail={
                          thumbnail ||
                          post?.thumbnail ||
                          coverImage ||
                          post?.coverImage
                        }
                        post={post}
                        hostName={hostName}
                        slug={slug}
                        linkedInPostsUTM={linkedInPosts[index].utm}
                        account={this.getLinkedInProfile(
                          linkedInPosts[index]?.account
                        )}
                        postURLWithUTM={postURLWithUTM}
                        toggleUpdateBody={this.toggleUpdateBody}
                        updateBody={updateBody}
                        toggleImageUploadComplete={
                          this.toggleImageUploadComplete
                        }
                        imageUploadComplete={imageUploadComplete}
                      />
                      {linkedInPost.salesCallInsightsData?.body &&
                        this.renderSalesCallMetadata(
                          linkedInPost.salesCallData,
                          linkedInPost.accountName,
                          linkedInPost.acoountPicture
                        )}
                    </div>
                  </Grid>
                </Grid>
                {linkedInPost?.status !== socialMediaStatus.COMPLETED &&
                  !externalApproverId && (
                    <Grid
                      container
                      style={{
                        marginBottom: 20,
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Grid>
                        <StyledButton
                          variant="destructivesecondary"
                          onClick={() =>
                            this.toggleDeleteLinkedInPostConfirmDialog(index)
                          }
                          color="secondary"
                        >
                          Delete draft
                        </StyledButton>

                        {(isReviewScreen || linkedInPost.post) && (
                          <StyledButton
                            variant="textsecondary"
                            onClick={this.showApprove}
                            style={{ marginLeft: space.SMALL }}
                          >
                            {approvalRequested
                              ? "Change Approver"
                              : "Get Approved"}
                          </StyledButton>
                        )}
                      </Grid>

                      <Tooltip title="Copy everywhere">
                        <IconButton
                          onClick={() => this.toggleShowCopyConfirmation(index)}
                          size="large"
                        >
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
              </>
            );
          })}

          {showAIGeneratePostDialog && (
            <AIGeneratePost
              index={generatePostIndex}
              freeInputSource={freeInputSource}
              sourceLink={sourceLink}
              showAIGeneratePostDialog={showAIGeneratePostDialog}
              generatingLinkedInPost={generatingLinkedInPost}
              socialPost={socialPost || []}
              updatePostContent={this.updateLinkedinPostContent}
              setTemplate={this.setTemplate}
              onAIPostGenerate={this.onAIPostGenerate}
              closeAIGeneratePostDialog={this.closeAIGeneratePostDialog}
              publicationId={publication._id}
              saveSamples={this.saveSamples}
              selectedSampleIds={
                linkedInPosts[generatePostIndex]?.samples || []
              }
              linkedInPost={linkedInPosts[generatePostIndex]}
              type={channelTypes.LINKEDIN}
              regenerateLinkedInPost={(
                generatedPostId: string,
                socialPostId: string,
                sample: string
              ) =>
                this.regenerateLinkedInPost(
                  generatePostIndex,
                  generatedPostId,
                  socialPostId,
                  sample
                )
              }
              onGeneratedPostSampleChange={this.onGeneratedPostSampleChange}
              linkedinPostSuggestedTemplateIds={
                linkedinPostSuggestedTemplateIds
              }
              setLinkedinPostSuggestedTemplateIds={(ids) =>
                this.setState({ linkedinPostSuggestedTemplateIds: ids })
              }
            />
          )}

          {!externalApproverId && (
            <>
              <Divider />
              <Grid
                id="add_another_account"
                container
                justifyContent="space-between"
                className={classes.anotherAccount}
              >
                <StyledButton
                  variant="secondary"
                  onClick={this.handlePostToAnotherAccount}
                  startIcon={<AddCircleIcon />}
                  disabled={isMultiAccountPost}
                >
                  Post to {linkedInPosts.length > 0 && "another"} account
                </StyledButton>
              </Grid>
            </>
          )}
          <StyledDialog
            open={showMentionInfo}
            title={"How to use @mentions"}
            body={
              <>
                <Typography variant="bodym">
                  When @mentioning a company, use the company's identifier. For
                  example, it's "@google" for Google and "@williams-sonoma-inc-"
                  for Williams-Sonoma, Inc. You can find the LinkedIn company
                  identifier from the URL of the company page.
                  <br />
                  <br />
                </Typography>

                <img width="100%" src={images.linkedInMentions} />
              </>
            }
            successButtonName="OK"
            successCallback={this.toggleShowMentionInfo}
          />
          <StyledDialog
            open={showDeleteConfirmDialog}
            title="Are you sure?"
            body="Once deleted, you can't recover your post"
            successButtonName=" Yes, I'm sure"
            successCallback={this.deleteLinkedInPost}
            cancelCallback={this.handleDialogClose}
          />
          <Popover
            id="linkedin_image_picker"
            open={openImagePicker}
            anchorEl={<div />}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center"
            }}
            onClose={this.handleImagePickerClose}
          >
            <ImagePicker
              unsplashRef={(ref: any) => {
                this.imagePickerRef = ref;
              }}
              onImageSelect={this.onImageSelected}
              onImageUpload={this.onSelectFile}
              postPictures={[]}
              showUnsplashPicker={true}
              allowMp4Video={uploadingThumbnailForVideo ? false : true}
              allowDocuments={true}
              forLinkedIn={true}
              uploading={uploading}
            />
          </Popover>
          <CustomFlatPicker
            publicationId={publication._id}
            open={showDateTimePicker}
            value={linkedInPosts[linkedInPostIndex]?.scheduledOn}
            disablePast={true}
            timezone={clientTimezone}
            onClose={(date) => {
              this.setState(
                {
                  showDateTimePicker: false
                },

                this.onScheduleDateChange(date, true) // confirm = true
              );
            }}
            type={datePickerDefault.SOCIAL}
            scheduledDates={scheduledPostDates}
            getScheduledPostDates={this.getScheduledPostDates}
          />
        </Paper>

        <StyledDialog
          open={showApproverDialog}
          title="Who needs to approve this?"
          body={
            isMultiAccountPost ? (
              <Typography variant="bodym">
                We'll get each draft approved by the respective account
              </Typography>
            ) : (
              <Autocomplete
                fullWidth
                value={linkedInPostApprovers}
                multiple
                options={this.getApprovers()}
                disableCloseOnSelect
                getOptionDisabled={(option) => {
                  return this.doesApprovalRequested(option);
                }}
                getOptionLabel={(option) => option?.client?.name}
                isOptionEqualToValue={(option, value) => {
                  if (option._id) {
                    return option._id === value._id;
                  }

                  if (option.userId) {
                    return option.userId === value.userId;
                  }

                  return false;
                }}
                onChange={this.onApproverChange}
                renderOption={(props, option, { selected }) => {
                  return (
                    <div key={option._id} {...props}>
                      <StyledCheckbox
                        checked={selected}
                        label={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Avatar
                              src={option?.client?.profilePic}
                              alt="profile pic"
                              style={{
                                width: 20,
                                height: 20,
                                margin: "auto 10px"
                              }}
                            />

                            <Typography>
                              {option?.client?.name || option?.client?.email}
                            </Typography>
                          </div>
                        }
                      />
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <StyledInput
                    autoFocus
                    {...params}
                    variant="outlined"
                    size="large"
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <StyledUserChip
                        key={index}
                        avatar={
                          <Avatar
                            src={option?.client?.profilePic}
                            alt="profile pic"
                          />
                        }
                        label={option?.client?.name}
                        {...getTagProps({ index })}
                        disabled={this.doesApprovalRequested(option)}
                      />
                    );
                  })
                }
              />
            )
          }
          successButtonName="Request review"
          cancelButtonName="Cancel"
          successCallback={
            isMultiAccountPost
              ? this.setApproversForMultiAccount
              : this.setApprovers
          }
          cancelCallback={() => {
            this.setState({
              showApproverDialog: false
            });
          }}
        />

        <StyledDialog
          open={showCopyConfirmation}
          title="Do you want to copy this to other accounts?"
          body="We'll overwrite the body, time, and other details of other accounts."
          closeCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          cancelCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          successCallback={() => this.copyEverywhere()}
          successButtonName="OK"
          maxWidth="sm"
          fullWidth
        />

        <StyledDialog
          open={showConfirmScheduleDateDialog}
          title="Do you want to reschedule your LinkedIn post?"
          body="We'll reschedule the publish date of your LinkedIn post"
          closeCallback={() => {
            this.setState({
              showConfirmScheduleDateDialog: false
            });
          }}
          cancelCallback={() => {
            this.setState({
              showConfirmScheduleDateDialog: false
            });
          }}
          successCallback={() => {
            this.onScheduleDateChange(selectedScheduledDate, false);
          }}
          successButtonName="Reschedule"
          maxWidth="sm"
          fullWidth
        />

        {openGenerateCampaignDialog && isReviewScreen && (
          <StyledDialog
            open={openGenerateCampaignDialog}
            title="Create a LinkedIn campaign"
            body={
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}
              >
                <Grid>
                  <FormControl variant="outlined" size="small">
                    <StyledSelect
                      label="How many posts do you want to create per LinkedIn account?"
                      value={numberOfPostsForCampaign}
                      onChange={(event: Event) => {
                        this.setState({
                          numberOfPostsForCampaign: event.target.value
                        });
                      }}
                      name="numberOfPostsForCampaign"
                    >
                      {[...Array(5)].map((_, index) => {
                        return (
                          <StyledMenuItem key={index} value={index + 1}>
                            {index + 1}
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Grid>

                <Grid
                  style={{
                    marginTop: space.LARGE
                  }}
                >
                  <Grid style={{ minHeight: "150px" }}>
                    <Typography variant="h200">
                      Which accounts do you want to post to?
                    </Typography>

                    <LinkedInAutoComplete
                      value={accountsForCampaign}
                      onChange={this.onAutocompleteChangeForCampaign}
                      accounts={linkedInHandle?.accounts}
                      organizations={linkedInHandle?.organizations}
                      groups={groups}
                      style={{ marginTop: space.XS }}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            closeCallback={() => {
              this.setState({
                openGenerateCampaignDialog: false
              });
            }}
            cancelCallback={() => {
              this.setState({
                openGenerateCampaignDialog: false
              });
            }}
            successCallback={() => {
              this.generateCampaign();
            }}
            disableSuccessButton={!accountsForCampaign.length}
            successButtonName="Generate"
            maxWidth="sm"
            fullWidth
          />
        )}

        {showInstructionDialog && (
          <AIInstructionsDialog
            open={showInstructionDialog}
            value={instructions}
            onSaveInstructions={this.onSaveInstructions}
            onClose={() => this.showInstruction(false)}
            publicationId={publication._id}
            postId={postId}
            index={instructionDialogIndex}
            saveSamples={this.saveSamples}
            selectedSampleIds={
              linkedInPosts?.[instructionDialogIndex]?.samples || []
            }
          />
        )}

        {showPostFromVideoDialog && (
          <LinkedInVideoToPostConfirmDialog
            open={showPostFromVideoDialog}
            linkedinPostIndex={generatePostIndex}
            publicationId={publication._id}
            onSuccess={this.generatePostFromVideo}
            onCancel={() => this.showGeneratePostFromVideoDialog()}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(LinkedInShare));
