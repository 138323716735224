import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router";
import { LinkedinAccount } from "../Components/AccountMonitoring";
import AddEditOutboundTemplate from "../Components/AddEditOutboundTemplate";
import { notify } from "../Components/CustomNotifications";
import Loading from "../Components/Loading";
import { AvatarSize, space } from "../Config/theme";
import Api from "../Services/Api";
import colors from "../Themes/Colors";
import { isModuleDisabled } from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";
import { channelTypes, modulesAccess, Publication } from "../Utils/Types";
import StyledAvatar from "../design/components/StyledAvatar";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import StyledPagination from "../design/components/StyledPagination";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import Page404 from "./Page404";
import styles from "./styles/TemplateSettingsStyle";

const api = Api.create();
const DEFAULT_CHANNEL = channelTypes.LINKEDIN;
const socialChannels = [channelTypes.TWITTER, channelTypes.LINKEDIN];

type TemplateSettingsProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;
type Sample = {
  _id?: string;
  sample: string;
  label: string;
  channel: channelTypes.LINKEDIN | channelTypes.TWITTER;
  associatedVanityName?: string;
};
export type OutboundTemplate = {
  _id?: string;
  label: string;
  role: string;
  template: string;
  channel: channelTypes.LINKEDIN | channelTypes.EMAIL;
};
type TemplateSettingsState = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  publication: Publication | null;
  publicationId: string;
  samples: Sample[];
  samplePage: number;
  sampleLimit: number;
  samplesLoading: boolean;
  sample: string;
  label: string;
  channel: channelTypes.LINKEDIN | channelTypes.TWITTER;
  associatedVanityName?: string;
  showAddEditSampleDialog: boolean;
  selectedSampleId: string;
  templates: OutboundTemplate[];
  templatesLoading: boolean;
  showAddEditTemplateDialog: boolean;
  selectedOutboundTemplate?: OutboundTemplate;
  linkedinAccounts: LinkedinAccount[];
};

class TemplateSettings extends React.Component<
  TemplateSettingsProps,
  TemplateSettingsState
> {
  static contextType = PageAccessContext;
  constructor(props: TemplateSettingsProps) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      errorMessage: "",
      publicationId: props.match.params.id || "",
      publication: null,
      samples: [],
      samplePage: 0,
      sampleLimit: 10,
      samplesLoading: false,
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL,
      associatedVanityName: "",
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      templates: [],
      templatesLoading: false,
      showAddEditTemplateDialog: false,
      selectedOutboundTemplate: undefined,
      linkedinAccounts: []
    };
  }

  componentDidUpdate(
    prevProps: Readonly<TemplateSettingsProps>,
    prevState: Readonly<TemplateSettingsState>
  ): void {
    const { samples, samplePage, sampleLimit } = this.state;
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          publicationId: this.props.match.params.id || "",
          publication: null,
          loading: true,
          error: false,
          errorMessage: "",
          samples: [],
          samplePage: 0,
          sampleLimit: 10,
          samplesLoading: false,
          sample: "",
          label: "",
          channel: DEFAULT_CHANNEL,
          associatedVanityName: "",
          showAddEditSampleDialog: false,
          selectedSampleId: "",
          templates: [],
          templatesLoading: false,
          showAddEditTemplateDialog: false,
          selectedOutboundTemplate: undefined,
          linkedinAccounts: []
        },
        () => {
          this.getPublication();
        }
      );
    }

    if ((samplePage !== 0 && sampleLimit * samplePage > samples?.length) || 0) {
      this.setState({ samplePage: 0 });
    }
  }

  componentDidMount(): void {
    this.getPublication();
    this.getSamples();
    this.getTemplates();
    this.getConnectedLinkedinAccounts();
  }

  getPublication = async () => {
    let { publicationId } = this.state;

    if (!publicationId) {
      this.setState({
        error: true,
        errorMessage: "Workspace not found"
      });
      return;
    }

    api.getPublicationById(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          publication: res.data.publication
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.status === 400 ? res.data : "Unauthorized access"
        });
      }
    });
  };

  getSamples = () => {
    this.setState({ samplesLoading: true });
    api.getSamples(
      this.state.publicationId,
      channelTypes.LINKEDIN,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({ samples: res.data, samplesLoading: false });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting samples",
            "error"
          );
        }
      }
    );
  };

  getTemplates = () => {
    this.setState({ templatesLoading: true });
    api.getOutboundTemplates(
      this.state.publicationId,
      null,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({ templates: res.data, templatesLoading: false });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error getting templates",
            "error"
          );
        }
      }
    );
  };

  getConnectedLinkedinAccounts = () => {
    api.getConnectedLinkedinAccounts(
      this.state.publicationId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            linkedinAccounts:
              res.data?.filter(
                (account: LinkedinAccount) =>
                  account.connected && account.vanityName
              ) || []
          });
        }
      }
    );
  };

  deleteSample = (sampleId: string) => {
    api.deleteSample(
      this.state.publicationId,
      sampleId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.getSamples();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error deleting sample",
            "error"
          );
        }
      }
    );
  };
  deleteTemplate = (templateId: string) => {
    let { publicationId } = this.state;
    api.deleteOutboundTemplate(
      publicationId,
      templateId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.getTemplates();
        } else {
          notify.show(
            res.status === 400 ? res.data : "Error deleting outbound template",
            "error"
          );
        }
      }
    );
  };

  deleteSampleRow = (index: number) => {
    let { samples } = this.state;
    let sampleToDelete = samples[index];
    samples.splice(index, 1);
    this.setState({ samples });

    this.deleteSample(sampleToDelete?._id || "");
  };

  deleteTemplateRow = (index: number) => {
    let { templates } = this.state;
    let templateToDelete = templates[index];
    templates.splice(index, 1);
    this.setState({ templates });

    this.deleteTemplate(templateToDelete?._id || "");
  };

  editSampleRow(index: number) {
    const { samples } = this.state;
    const sample = samples[index];

    this.setState({
      sample: sample.sample,
      label: sample.label,
      channel: sample.channel,
      associatedVanityName: sample.associatedVanityName,
      showAddEditSampleDialog: true,
      selectedSampleId: sample._id || ""
    });
  }

  editTemplateRow(index: number) {
    const { templates } = this.state;
    const template = templates[index];

    this.setState({
      showAddEditTemplateDialog: true,
      selectedOutboundTemplate: template
    });
  }

  openAddEditSampleDialog = () => {
    this.setState({ showAddEditSampleDialog: true });
  };

  openAddEditTemplateDialog = () => {
    this.setState({
      showAddEditTemplateDialog: true,
      selectedOutboundTemplate: undefined
    });
  };

  closeAddEditSampleDialog = () => {
    this.setState({
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL,
      associatedVanityName: ""
    });
  };

  saveSample = (sample: any) => {
    this.setState({ samplesLoading: true });
    sample.isGenericTemplate = false;
    api.saveSample(this.state.publicationId, sample, (res: AxiosResponse) => {
      this.setState({ samplesLoading: false });
      this.getSamples();
      if (res.status !== 200) {
        notify.show(
          res.status === 400 ? res.data : "Error saving sample",
          "error"
        );
      }
    });
  };

  saveOrUpdateSample = () => {
    const {
      label,
      sample,
      channel,
      associatedVanityName,
      selectedSampleId,
      samples
    } = this.state;
    if (!label) {
      notify.show("Please enter label", "error");
      return;
    }
    if (!sample?.trim()) {
      notify.show("Please enter sample", "error");
      return;
    }

    if (selectedSampleId) {
      let sampleIndex = samples.findIndex(
        (sample) => sample._id === selectedSampleId
      );
      let editedSample: Sample = {
        ...samples[sampleIndex],
        sample: sample?.trim(),
        channel: channel || DEFAULT_CHANNEL,
        associatedVanityName,
        label
      };

      samples[sampleIndex] = editedSample;
      this.setState({ samples });
      this.saveSample(editedSample);
    } else {
      let newSample: Sample = {
        sample: sample?.trim(),
        channel: channel || DEFAULT_CHANNEL,
        associatedVanityName,
        label
      };
      if (!samples) {
        this.setState({ samples: [] });
      }

      samples.push(newSample);
      this.setState({ samples });
      this.saveSample(newSample);
    }

    this.setState({
      samples,
      showAddEditSampleDialog: false,
      selectedSampleId: "",
      sample: "",
      label: "",
      channel: DEFAULT_CHANNEL,
      associatedVanityName: ""
    });
  };

  onSampleChange = (e: any) => {
    this.setState({ sample: e.target.value });
  };

  onLabelChange = (e: any) => {
    this.setState({ label: e.target.value });
  };

  onChannelChange = (e: any) => {
    this.setState({ channel: e.target.value });
  };

  onAssociatedVanityNameChange = (e: any) => {
    this.setState({ associatedVanityName: e.target.value });
  };

  render() {
    let {
      loading,
      error,
      errorMessage,
      publication,
      publicationId,
      samplesLoading,
      samples,
      samplePage,
      sampleLimit,
      label,
      sample,
      channel,
      associatedVanityName,
      showAddEditSampleDialog,
      selectedSampleId,
      templates,
      templatesLoading,
      showAddEditTemplateDialog,
      selectedOutboundTemplate,
      linkedinAccounts
    } = this.state;
    let { classes } = this.props;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    const linkedinAccountMap = linkedinAccounts.reduce<{
      [vanityName: string]: string;
    }>((acc, account) => {
      acc[account.vanityName] = account.name;
      return acc;
    }, {});

    const samplesToShow = samples?.slice(
      samplePage * sampleLimit,
      samplePage * sampleLimit + sampleLimit
    );

    return (
      <div>
        <Helmet>
          <title>
            Template Settings {publication && "- " + publication.name} |
            Letterdrop
          </title>
        </Helmet>

        <Container style={{ paddingTop: 24 }}>
          <Typography variant="h600" paragraph>
            Template Settings
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" component="div">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {!isModuleDisabled(
                  this.context,
                  publicationId,
                  modulesAccess.LINKEDIN
                ) && (
                  <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                    <div style={{ marginTop: space.LARGE }}>
                      <Typography variant="h400" paragraph>
                        LinkedIn Content Templates
                      </Typography>

                      <Typography variant="bodym">
                        Add templates to match your tone and voice
                      </Typography>
                    </div>

                    <Grid container direction="column">
                      {samplesLoading && <Loading />}

                      {!samplesLoading && samples?.length > 0 && (
                        <>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Label</TableCell>
                                  <TableCell>Template</TableCell>
                                  {/* <TableCell>Channel</TableCell> */}
                                  <TableCell>User</TableCell>
                                  <TableCell align="right"></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {samplesToShow.map((value, index) => {
                                  return (
                                    <TableRow key={value._id}>
                                      <TableCell
                                        style={{
                                          width: "20%"
                                        }}
                                      >
                                        <Grid
                                          style={{
                                            lineHeight: "20px",
                                            maxHeight: "40px",
                                            overflow: "hidden"
                                          }}
                                        >
                                          {value.label}
                                        </Grid>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "40%"
                                        }}
                                      >
                                        <Grid
                                          style={{
                                            lineHeight: "20px",
                                            maxHeight: "40px",
                                            overflow: "hidden"
                                          }}
                                        >
                                          {value.sample}
                                        </Grid>
                                      </TableCell>

                                      {/* <TableCell style={{ width: "30%" }}>
                                        <Grid container direction="row">
                                          <Grid item xs={12}>
                                            <Typography variant="bodym">
                                              {value.channel}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TableCell> */}

                                      <TableCell style={{ width: "30%" }}>
                                        <Grid container direction="row">
                                          <Grid item xs={12}>
                                            <Typography variant="bodym">
                                              {linkedinAccountMap[
                                                value.associatedVanityName || ""
                                              ] || "-"}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "10%"
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row"
                                          }}
                                        >
                                          <div>
                                            <Tooltip title="Edit">
                                              <IconButton
                                                onClick={() => {
                                                  this.editSampleRow(
                                                    samplePage * sampleLimit +
                                                      index
                                                  );
                                                }}
                                                size="large"
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>

                                          <div>
                                            <Tooltip title="Delete">
                                              <IconButton
                                                onClick={() =>
                                                  this.deleteSampleRow(
                                                    samplePage * sampleLimit +
                                                      index
                                                  )
                                                }
                                                size="large"
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div>
                            <StyledPagination
                              rowsPerPageOptions={[10, 20, 50]}
                              count={samples?.length || 0}
                              rowsPerPage={sampleLimit}
                              page={samplePage}
                              onPageChange={(
                                e: React.ChangeEvent<unknown>,
                                newPage: number
                              ) => {
                                this.setState({ samplePage: newPage });
                              }}
                              onRowsPerPageChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                this.setState({
                                  sampleLimit: parseInt(e.target.value)
                                });
                              }}
                            />
                          </div>
                        </>
                      )}

                      <Grid
                        item
                        container
                        direction="row"
                        className={classes.marginTop20}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <StyledButton
                            variant="textprimary"
                            onClick={this.openAddEditSampleDialog}
                            startIcon={<AddCircleIcon />}
                          >
                            Add Template
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    <hr color={colors.horizontalRule} />
                  </Grid>
                )}
                <Grid item xs={12} style={{ marginTop: space.LARGE }}>
                  <div style={{ marginTop: space.LARGE }}>
                    <Typography variant="h400" paragraph>
                      Outbound Templates
                    </Typography>

                    <Typography variant="bodym">
                      Add templates to use in outbound LinkedIn DMs and Emails
                    </Typography>
                  </div>

                  <Grid container direction="column">
                    {templatesLoading && <Loading />}

                    {!templatesLoading && templates?.length > 0 && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Label</TableCell>
                              <TableCell>Role</TableCell>
                              <TableCell>Template</TableCell>
                              <TableCell>Channel</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {templates.map((value, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      width: "20%"
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        lineHeight: "20px",
                                        maxHeight: "40px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {value.label}
                                    </Grid>
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      width: "20%"
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        lineHeight: "20px",
                                        maxHeight: "40px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {value.role}
                                    </Grid>
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      width: "40%"
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        lineHeight: "20px",
                                        maxHeight: "40px",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {value.template}
                                    </Grid>
                                  </TableCell>

                                  <TableCell style={{ width: "10%" }}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {value.channel}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      width: "10%"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row"
                                      }}
                                    >
                                      <div>
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() => {
                                              this.editTemplateRow(index);
                                            }}
                                            size="large"
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>

                                      <div>
                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() =>
                                              this.deleteTemplateRow(index)
                                            }
                                            size="large"
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    <Grid
                      item
                      container
                      direction="row"
                      className={classes.marginTop20}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StyledButton
                          variant="textprimary"
                          onClick={this.openAddEditTemplateDialog}
                          startIcon={<AddCircleIcon />}
                        >
                          Add Template
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr color={colors.horizontalRule} />
                </Grid>
                {showAddEditTemplateDialog && (
                  <AddEditOutboundTemplate
                    publicationId={publicationId}
                    selectedTemplate={selectedOutboundTemplate}
                    closeDialog={(reload) => {
                      this.setState({
                        showAddEditTemplateDialog: false,
                        selectedOutboundTemplate: undefined
                      });

                      if (reload) {
                        this.getTemplates();
                      }
                    }}
                  />
                )}
                {showAddEditSampleDialog && (
                  <StyledDialog
                    open={showAddEditSampleDialog}
                    title={selectedSampleId ? "Update field" : "Add field"}
                    body={
                      <div style={{ padding: `0 ${space.LARGE}` }}>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Label
                          </Typography>

                          <StyledInput
                            autoFocus
                            type="text"
                            size="large"
                            name="label"
                            fullWidth
                            placeholder="Product Launch"
                            value={label}
                            onChange={this.onLabelChange}
                          />
                        </div>
                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Template
                          </Typography>

                          <StyledInput
                            type="textarea"
                            multiline={true}
                            rows={6}
                            size="large"
                            name="sample"
                            fullWidth
                            value={sample}
                            onChange={this.onSampleChange}
                          />
                        </div>

                        {/* <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            Channel
                          </Typography>

                          <StyledSelect
                            name="channel"
                            fullWidth
                            value={channel}
                            size="medium"
                            onChange={this.onChannelChange}
                          >
                            {Object.values(socialChannels).map(
                              (channel, index) => {
                                return (
                                  <StyledMenuItem value={channel} key={channel}>
                                    <Grid container direction="row">
                                      <Grid item xs={12}>
                                        <Typography variant="bodym">
                                          {channel}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </StyledMenuItem>
                                );
                              }
                            )}
                          </StyledSelect>
                        </div> */}

                        <div style={{ marginBottom: space.MEDIUM }}>
                          <Typography
                            variant="bodym"
                            paragraph
                            style={{ marginBottom: space.SMALL }}
                          >
                            User
                          </Typography>

                          <StyledSelect
                            name="account"
                            fullWidth
                            value={associatedVanityName}
                            size="medium"
                            onChange={this.onAssociatedVanityNameChange}
                          >
                            <StyledMenuItem value={""} key={"all"}>
                              <Grid container direction="row">
                                <Grid
                                  item
                                  xs={12}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <StyledAvatar
                                    alt={""}
                                    src={""}
                                    sx={{ mr: 3 }}
                                    style={{ ...AvatarSize.xs }}
                                  />
                                  <Typography variant="bodym">None</Typography>
                                </Grid>
                              </Grid>
                            </StyledMenuItem>

                            {linkedinAccounts.map((account, index) => {
                              return (
                                <StyledMenuItem
                                  value={account.vanityName}
                                  key={account.vanityName}
                                >
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      xs={12}
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <StyledAvatar
                                        alt={account.name}
                                        src={account.profilePicture}
                                        sx={{ mr: 3 }}
                                        style={{ ...AvatarSize.xs }}
                                      />

                                      <Typography variant="bodym">
                                        {account.name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </StyledMenuItem>
                              );
                            })}
                          </StyledSelect>
                        </div>
                      </div>
                    }
                    successButtonName={selectedSampleId ? "Update" : "Add"}
                    successCallback={this.saveOrUpdateSample}
                    cancelCallback={this.closeAddEditSampleDialog}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(TemplateSettings));
