// @ts-nocheck

import isAlphanumeric from "validator/es/lib/isAlphanumeric";
import { Quill } from "react-quill";
import { v5 as uuidv5 } from "uuid";
import { emojis, Emoji } from "@nutrify/quill-emoji-mart-picker";
import {
  clientPlanTypes,
  customFieldDataType,
  DEFAULT_BLOCKQUOTE_COLOR,
  imageExtensions,
  readTimeTypes,
  THREAD_MAX_LENGTH,
  topicStatus,
  TWEETS_LINK_LENGTH,
  URL_REPLACE_TEXT
} from "./Types";
import qs from "query-string";
import moment from "moment";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { titleCase } from "title-case";
import {
  addBusinessDays,
  formatDateWithYear,
  getDifferenceFromNow,
  getRelativeTime,
  isPastDate,
  reduceBusinessDays
} from "./TimeUtils";
import { designColors } from "../Themes/Colors";
import Api from "../Services/Api";

import isURL from "validator/lib/isURL";

import TurndownService from "turndown";
import { remark } from "remark";
import { uuid } from "uuidv4";

let Delta = Quill.import("delta");
let turndownService = new TurndownService({
  headingStyle: "atx",
  bulletListMarker: "-"
});

const api = Api.create();

var base64toblob = require("base64toblob");

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export function validateUrl(url: any) {
  let regex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
  return regex.test(url);
}

export function isValidEmail(email: any) {
  // const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regexp = /\S+@\S+\.\S+/;
  return regexp.test(email);
}

export function checkValidMailto(str: any) {
  const regex = /^mailto:\S+@\S+\.\S+?/gm;
  return regex.test(str);
}

export function formatNumber(number: any) {
  return (parseInt(number) || 0).toLocaleString();
}

export function generateRandomId(length: any) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

let files = [
  FRONTEND_URL + "/resources/images/avatars/1.png",
  FRONTEND_URL + "/resources/images/avatars/2.png",
  FRONTEND_URL + "/resources/images/avatars/3.png",
  FRONTEND_URL + "/resources/images/avatars/4.png",
  FRONTEND_URL + "/resources/images/avatars/5.png",
  FRONTEND_URL + "/resources/images/avatars/6.png",
  FRONTEND_URL + "/resources/images/avatars/7.png",
  FRONTEND_URL + "/resources/images/avatars/8.png",
  FRONTEND_URL + "/resources/images/avatars/9.png",
  FRONTEND_URL + "/resources/images/avatars/10.png",
  FRONTEND_URL + "/resources/images/avatars/11.png",
  FRONTEND_URL + "/resources/images/avatars/12.png",
  FRONTEND_URL + "/resources/images/avatars/13.png",
  FRONTEND_URL + "/resources/images/avatars/14.png",
  FRONTEND_URL + "/resources/images/avatars/15.png",
  FRONTEND_URL + "/resources/images/avatars/16.png",
  FRONTEND_URL + "/resources/images/avatars/17.png",
  FRONTEND_URL + "/resources/images/avatars/18.png",
  FRONTEND_URL + "/resources/images/avatars/19.png",
  FRONTEND_URL + "/resources/images/avatars/20.png"
];

export function getAvatar() {
  let random = Math.floor(Math.random() * files.length + 1);
  return files[random];
}

export function generateDomainName(publicationName: any) {
  let name = publicationName.toLowerCase();
  let chars = Array.from(name);
  name = "";
  for (let letter of chars) {
    if (letter === " ") {
      name += "-";
    }

    if (isAlphanumeric(letter)) {
      name += letter;
    }
  }

  return name;
}

export function parseJson(value: any, readOnly: any, postId: any) {
  try {
    let deltaValue = new Delta(JSON.parse(value));

    //Adding empty line at the end of the post in edit mode
    //ISSUE: We are trimming white spaces. If post contains image at
    //the end, we are unable to backspace image or move cursor.
    //
    if (
      !readOnly &&
      postId &&
      deltaValue.ops &&
      deltaValue.ops.length &&
      !(deltaValue.ops[deltaValue.ops.length - 1] instanceof HTMLBRElement)
    ) {
      deltaValue.ops.push({
        insert: "\n\n"
      });
    }

    return deltaValue;
  } catch (err) {
    return value;
  }
}

export function hexToRgb(hex: any, transparency: any) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${transparency})`
    : null;
}

export function getBackgroundColor(color: any) {
  return hexToRgb(color, 0.03);
}
export function addBlockquoteStyle(color: any) {
  color = color ? color : DEFAULT_BLOCKQUOTE_COLOR;
  let blockquoteStyle = document.getElementById("blockquote-style");
  if (blockquoteStyle) {
    blockquoteStyle.parentNode.removeChild(blockquoteStyle);
  }
  var style = document.createElement("style");
  style.type = "text/css";
  style.setAttribute("id", "blockquote-style");
  style.innerHTML =
    ".ql-bubble .ql-editor blockquote {  border-left: 4px solid " +
    color +
    ";  padding-left: 16px;line-height: 1.42;}";
  document.getElementsByTagName("head")[0].appendChild(style);
}

export const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

export function dataURItoBlob(base64: any, callback: any) {
  var blob = base64toblob(base64, "image/png");
  var url = window.URL.createObjectURL(blob);
  callback(url);
}

export function getLighterColor(hex: any) {
  let lum = 0.35;
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
}

export async function loadInstagramScript() {
  if (window.instgrm) {
    window.instgrm.Embeds.process();
    return;
  }
  await loadScript("//platform.instagram.com/en_US/embeds.js");
}

export async function loadTikTokScript() {
  await loadScript("//tiktok.com/embed.js");
}

export async function loadTwitterScript() {
  if (window.twttr) {
    window.twttr.widgets.load();
    return;
  }
  await loadScript("//platform.twitter.com/widgets.js");
}

function loadScript(url: any) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function () {
      reject();
    };
    document.head.appendChild(script);
  });
}

export function updateCodeSyntaxHighlighting() {
  setTimeout(() => {
    document.querySelectorAll("pre").forEach((block) => {
      hljs.highlightBlock(block);
    });
  }, 1000);
}

export function getTextColorBasedOnTheme(themeColor: any) {
  if (themeColor.slice(0, 1) === "#") {
    themeColor = themeColor.slice(1);
  }
  var r = parseInt(themeColor.substr(0, 2), 16);
  var g = parseInt(themeColor.substr(2, 2), 16);
  var b = parseInt(themeColor.substr(4, 2), 16);

  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 200 ? "black" : "white";
}

export function isCustomDomain(customDomain: any) {
  return (
    customDomain &&
    customDomain.domain &&
    customDomain.aRecord &&
    customDomain.wwwRecord
  );
}

export function isCustomDirectory(customDirectory: any) {
  return customDirectory && customDirectory.domain;
}

export function isExternalSite(publication: any) {
  return !!publication.externalSite?.blogs?.length;
}

export function isHtmlBufferSizeExceeded(html: any) {
  const KILO_BYTE_SIZE = 1000; // 1 KB
  const EMAIL_LIMIT = 102; // Gmail clips email at 102 KB

  let htmlBuffer = Buffer.from(html, "utf8");
  let bufferSize = htmlBuffer.length / KILO_BYTE_SIZE;

  if (bufferSize > EMAIL_LIMIT) {
    return true;
  }

  return false;
}

export function getQueryParamsFromURL(search: any, defaultBackPage: any) {
  let queryParams = qs.parse(search);
  let {
    backpage = defaultBackPage,
    av: agencyView = "false",
    createdOn = null,
    topicId = null
  } = queryParams;

  agencyView = agencyView === "true" ? true : false;

  if (agencyView) {
    backpage = "agency/" + backpage + "&createdOn=" + createdOn;
  } else {
    if (topicId && topicId !== null && topicId !== undefined) {
      backpage += "?&topicid=" + topicId;
    } else if (createdOn && createdOn !== null && createdOn !== undefined) {
      backpage += "?&createdOn=" + createdOn;
    }
  }

  return { backpage, agencyView, createdOn };
}

export function getScheduleDateFromURL(search: any, defaultValue: any) {
  let queryParams = qs.parse(search);
  let date = defaultValue;

  if (queryParams.d) {
    date = queryParams.d;
  }

  return date;
}

export function getSiteURL(
  publication: any,
  isForPost = false,
  suffix = "",
  blogDestination = {}
) {
  let blogs = publication.externalSite.blogs;

  let externalBlogIndex = 0;

  let { id = "" } = blogDestination || {};
  if (id) {
    let blogIndex = blogs.findIndex((blog: any) => blog.id === id);
    if (blogIndex > 0) {
      externalBlogIndex = blogIndex;
    }
  }
  let domainURL = blogs[externalBlogIndex]?.blogHomePath;

  // handle for blogpost
  if (isForPost) {
    domainURL = blogs[externalBlogIndex]?.blogPostBasePath || domainURL; // if external blog post not available use home itself as path.
  }

  return domainURL + suffix;
}

export function hideOtherChatWidgets() {
  if (document.getElementById("drift-frame-chat")) {
    document.getElementById("drift-frame-chat").style.display = "none";
  }

  if (document.getElementById("drift-frame-controller")) {
    document.getElementById("drift-frame-controller").style.display = "none";
  }
}

export function showOtherChatWidgets() {
  if (document.getElementById("drift-frame-chat")) {
    document.getElementById("drift-frame-chat").style.display = "block";
  }
  if (document.getElementById("drift-frame-controller")) {
    document.getElementById("drift-frame-controller").style.display = "block";
  }
}

export function validateDataType(dataType: any, data: any) {
  const FLOAT_NUMBER_REGEX = /^-?\d{1,}(\.\d{1,})?$/gm;
  if (dataType === customFieldDataType.NUMBER) {
    return FLOAT_NUMBER_REGEX.test(data);
  }
  if (dataType === customFieldDataType.DATE) {
    return moment(data).isValid();
  }
  return true;
}

export function isAccessDisabled(
  publicationId: any,
  accessDetails: any,
  accessFor: any
) {
  if (!publicationId || !accessDetails || !accessDetails[publicationId]) {
    return false;
  }

  return accessDetails[publicationId].includes(accessFor);
}

export function isPlan3(context: any, publicationId: any) {
  let publication = context.publications?.find(
    (publication: any) => publication._id === publicationId
  );

  return publication?.plan === clientPlanTypes.PLAN3;
}

export function isFreePlan(context: any, publicationId: any) {
  let publication = context.publications?.find(
    (publication: any) => publication._id === publicationId
  );

  return publication?.plan === clientPlanTypes.FREE;
}

export function getDatePath(domain: string) {
  domain = domain?.trim() || "";
  return `${moment().utc().year()}/${moment().utc().month() + 1}/${moment()
    .utc()
    .date()}${domain ? `/${domain}` : ""}/${generateRandomId(6)}`;
}

export function appendIdToFileName(fileName: any, fileSize = "") {
  let name = fileName;
  let id = "_" + generateRandomId(4);
  // If filesize is provided append that instead
  if (fileSize) {
    id = "_" + fileSize;
  }
  if (name.includes(".")) {
    let index = name.lastIndexOf(".");
    name = name.slice(0, index) + id + name.slice(index);
  } else {
    name += id;
  }
  return name;
}

export function formatNumberTo2Decimal(number: any) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function handleSingular(value: any) {
  return value === 1 ? "" : "s";
}

//checks if approvers/editors/collabs contains themself
//output can look like: themself or userName1 or userName1, userName2
export function getFormattedUserNames(users: any, clientId: any) {
  if (users.length === 1 && users[0]._id === clientId) {
    return "themself";
  } else {
    return users.map((user: any) => user.name || user.email || "").join(", ");
  }
}

export function chunkString(str: any, length: any) {
  return str.match(new RegExp(".{1," + length + "}", "g"));
}

export function getRedirectURL(domain: any, suffix = "") {
  return `${process.env.REACT_APP_HTTP_PROTOCOL}${domain}.${process.env.REACT_APP_VERCEL_SERVER_URL}${suffix}`;
}

/**
 * Matches the keyword string or any non-space character in the article string.
 * Replace any square brackets in the keyword string with escaped square brackets using a regular expression.
 * Returns an array of words that match the keyword string or any non-space character.
 */
function splitTextWithKeyword(article: any, keyword: any) {
  const pattern = "\\n*" + keyword.replace(/([\[\]])/g, "\\$1") + "|\\n*\\S+";
  const regex = new RegExp(pattern, "g");
  return article.match(regex) || [];
}

/**
 *  function to split tweet to thread, each thread should be max length of 280,
 *  don't break words, and if text contains '[...]' text split to a new thread
 * @param {string} tweet
 * @param {string} link
 * @returns {Array} tweetThreads
 */
export function textToThreads(tweet: any, link = "") {
  let tweetArray = splitTextWithKeyword(tweet.trim(), URL_REPLACE_TEXT); // split the tweet into an array of words
  let index = 0; // variable to keep track of current thread
  let tweetThreads: any = []; // array to hold all threads
  tweetThreads[index] = "";
  let effectiveThreadLength = 0; // to maintain effective thread length considering links are 23 chars.

  // loop through the array of words
  tweetArray.forEach((word: any) => {
    let cleanWord = word.trim();
    // To handle splitter with space around: xxx [...] xxx
    if (word === "[...]") {
      index++;
      tweetThreads[index] = "";
      effectiveThreadLength = 0;
      return;
    }

    // To handle splitter without space around: xxx[...]xxx
    if (word.includes("[...]")) {
      let wordArray = word.split("[...]");
      if (!wordArray || wordArray.length === 0) {
        return;
      }

      let currentThread = `${tweetThreads[index]}${
        tweetThreads[index] ? " " : ""
      }${wordArray[0] || ""}`;
      effectiveThreadLength += wordArray[0].length;

      // check if adding the current word to the current thread exceeds 280 characters
      if (effectiveThreadLength > THREAD_MAX_LENGTH) {
        index++;
        effectiveThreadLength = wordArray[0].length + 1;
        tweetThreads[index] = wordArray[0];
      } else {
        tweetThreads[index] = currentThread;
      }

      wordArray.splice(0, 1);
      wordArray.forEach((value: any) => {
        index++;
        tweetThreads[index] = value;
      });
      effectiveThreadLength = 0;
      return;
    }

    // check if adding the current word to the current thread exceeds 280 characters
    // dont add space if the next word starts with a newline
    let temp = `${tweetThreads[index]}${
      tweetThreads[index] && !/^[\n]/g.test(word) ? " " : ""
    }`;
    if (word === URL_REPLACE_TEXT || isURL(cleanWord)) {
      effectiveThreadLength += TWEETS_LINK_LENGTH + 1;

      // if more than 280, move the link to new thread and set the new thread's effective length.
      if (effectiveThreadLength > THREAD_MAX_LENGTH) {
        index++;
        tweetThreads[index] = word;
      } else {
        temp += word;
        tweetThreads[index] = temp;
      }
      return;
    }

    temp += word;
    effectiveThreadLength += word.length + 1;
    if (effectiveThreadLength < THREAD_MAX_LENGTH) {
      tweetThreads[index] = temp;
    } else {
      // if more than 280, move the link to new thread and set the new thread's effective length.
      index++;
      effectiveThreadLength = word.length + 1;
      tweetThreads[index] = word;
    }
  });
  return tweetThreads;
}

export function splitTwitterPost(text: any) {
  const maxLength = 280; // Maximum length of a Twitter post chunk
  const paragraphs = text.split("\n"); // Split the text into paragraphs
  const chunks = []; // Array to store the resulting chunks
  let currentChunk = ""; // Variable to store the current chunk being built

  // Iterate through each paragraph
  for (const paragraph of paragraphs) {
    // Check if the current chunk can accommodate the paragraph
    if (currentChunk.length + paragraph.length + 1 <= maxLength) {
      // Add the paragraph to the current chunk, with a newline if necessary
      currentChunk += (currentChunk ? "\n" : "") + paragraph;
    } else {
      // If the current chunk is not empty, add it to the chunks array and reset it
      if (currentChunk) {
        chunks.push(currentChunk);
        currentChunk = "";
      }

      // If the paragraph fits within the character limit, add it to the current chunk
      if (paragraph.length <= maxLength) {
        currentChunk = paragraph;
      } else {
        // If the paragraph is too long, split it into words
        const words = paragraph.split(" ");
        let currentLine = ""; // Variable to store the current line being built

        // Iterate through each word in the paragraph
        for (const word of words) {
          // Check if the current line can accommodate the word
          if (currentLine.length + word.length + 1 <= maxLength) {
            // Add the word to the current line, with a space if necessary
            currentLine += (currentLine ? " " : "") + word;
          } else {
            // If the current line is not empty, add it to the chunks array and reset it
            if (currentLine) {
              chunks.push(currentLine);
              currentLine = "";
            }
            // Start a new line with the current word
            currentLine = word;
          }
        }

        // If the current line is not empty, add it to currentChunk to continue
        if (currentLine) {
          currentChunk = currentLine;
        }
      }
    }
  }

  // If the current chunk is not empty, add it to the chunks array
  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
}

export function truncateStringAndAddEllipses(text: any, length: any) {
  if (!text) {
    return "";
  }
  if (text.trim?.().length > length - 3) {
    text = text.trim().slice(0, length).trim();
    // Last word may or may not be incomplete
    // Removing last word
    text = text.slice(0, text.lastIndexOf(" ")) + "...";
  }
  return text.trim?.() || "";
}

export function validateImageType(file: any) {
  let fName = file.name;
  var fExtension = fName
    .substr(fName.lastIndexOf(".") + 1, fName.length)
    .toLowerCase();
  if (
    !(
      fExtension === "jpg" ||
      fExtension === "jpeg" ||
      fExtension === "png" ||
      fExtension === "gif" ||
      fExtension === "webp"
    )
  ) {
    return false;
  }
  return true;
}

export function isJPGorPNG(file: any) {
  let fName = file.name;
  var fExtension = fName
    .substr(fName.lastIndexOf(".") + 1, fName.length)
    .toLowerCase();
  if (
    !(fExtension === "jpg" || fExtension === "jpeg" || fExtension === "png")
  ) {
    return false;
  }
  return true;
}

export function getShortLocalNumber(number: any, digits = 1) {
  if (!number) {
    return 0;
  }

  if (isNaN(number)) {
    return number;
  }

  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: digits,
    notation: "compact",
    compactDisplay: "short"
  }).format(number);
}

export function replaceAll(string: any, search: any, replace: any) {
  if (!string) {
    return string;
  }
  try {
    return string.split(search).join(replace);
  } catch (error) {}

  return string;
}

export function unescape(text = "") {
  return new DOMParser().parseFromString(text, "text/html").documentElement
    .textContent;
}

export function convertTextToTitleCase(text: any) {
  let words = text.split(" ");

  words = words.map((word: any) => {
    return word === word.toUpperCase() && word.length > 1
      ? word
      : word.toLowerCase();
  });

  text = words.join(" ");
  return titleCase(text);
}

export async function getVideoFileMetaData(file: any) {
  return new Promise((resolve, reject) => {
    let video = document.createElement("video");
    video.setAttribute("src", window.URL.createObjectURL(file));
    video.onloadeddata = function (event) {
      return resolve(event.srcElement);
    };
  });
}

export function capitalizeFirstLetter(text: any) {
  if (!text) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function addHTTPSToURL(url: any) {
  const URL_REGEX = /^https?:\/\//;
  if (!URL_REGEX.test(url)) {
    url = "https://" + url;
  }
  return url;
}

export function trimURL(url: any) {
  if (url.endsWith("/")) {
    // trim if ends with /
    url = url.substring(0, url.length - 1);
  }
  return url;
}

export function getTextFromHtml(htmlString: any, addNewline = false) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to recursively extract text with preserved spacing and newlines
  function extractText(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue; // Use nodeValue for original spacing
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      let text = "";
      for (let child of node.childNodes) {
        const childText = extractText(child);
        if (childText.trim() !== "") {
          // Add space only if childText is not empty
          text += (text ? " " : "") + childText;
        } else {
          text += childText;
        }
      }
      // Add newlines for specific elements
      if (addNewline && (node.tagName === "P" || node.tagName === "BR")) {
        text += "\n";
      }
      return text;
    } else {
      return "";
    }
  }

  return extractText(doc.body).trim();
}

export function generateStatus(text = "") {
  const EMPTY_SPACE = /\s+/g;
  try {
    return text?.trim().toLowerCase().replace(EMPTY_SPACE, "_");
  } catch (error) {}
  return text;
}

export async function getApolloSequences(publicationId: string) {
  const APOLLO_LOCAL_STORAGE_KEY = getApolloSequenceStorageKey(publicationId);
  const APOLLO_SEQUENCES_RENTION_PERIOD = 2;
  let item = (await localStorage.getItem(APOLLO_LOCAL_STORAGE_KEY)) || "{}";
  item = JSON.parse(item);
  if (item && item.storedDate) {
    let storedDate = moment(item.storedDate);
    if (moment().diff(storedDate, "days") < APOLLO_SEQUENCES_RENTION_PERIOD) {
      return item.data;
    }
  }
  return new Promise((resolve) => {
    api.getApolloSequences(publicationId, (response) => {
      if (response.status === 200) {
        localStorage.setItem(
          APOLLO_LOCAL_STORAGE_KEY,
          JSON.stringify({
            data: response.data,
            storedDate: new Date()
          })
        );
        resolve(response.data);
      } else {
        console.log("Error fetching apollo sequences", response);
        resolve([]);
      }
    });
  });
}

export function getApolloSequenceStorageKey(publicationId: string) {
  return `${publicationId}-apolloSequences`;
}

export function getApolloEmailAccountStorageKey(publicationId: string) {
  return `${publicationId}-apolloEmailAccounts`;
}

export function getOutreachSequenceStorageKey(publicationId: string) {
  return `${publicationId}-outreachSequences`;
}

export function getOutreachEmailAccountStorageKey(publicationId: string) {
  return `${publicationId}-outreachEmailAccounts`;
}

export async function getOutreachSequences(publicationId: string) {
  const OUTREACH_LOCAL_STORAGE_KEY =
    getOutreachSequenceStorageKey(publicationId);
  const OUTREACH_SEQUENCES_RENTION_PERIOD = 1;
  let item = (await localStorage.getItem(OUTREACH_LOCAL_STORAGE_KEY)) || "{}";
  item = JSON.parse(item);
  if (item && item.storedDate) {
    let storedDate = moment(item.storedDate);
    if (moment().diff(storedDate, "days") < OUTREACH_SEQUENCES_RENTION_PERIOD) {
      return item.data;
    }
  }
  return new Promise((resolve) => {
    api.getOutreachSequences(publicationId, (response) => {
      if (response.status === 200) {
        localStorage.setItem(
          OUTREACH_LOCAL_STORAGE_KEY,
          JSON.stringify({
            data: response.data,
            storedDate: new Date()
          })
        );
        resolve(response.data);
      } else {
        console.log("Error fetching outreach sequences", response);
        resolve([]);
      }
    });
  });
}

export async function getOutreachEmailAccounts(publicationId: string) {
  const OUTREACH_LOCAL_STORAGE_KEY =
    getOutreachEmailAccountStorageKey(publicationId);
  const OUTREACH_EMAIL_ACCOUNTS_RENTION_PERIOD = 1;
  let item = (await localStorage.getItem(OUTREACH_LOCAL_STORAGE_KEY)) || "{}";
  item = JSON.parse(item);
  if (item && item.storedDate) {
    let storedDate = moment(item.storedDate);
    if (
      moment().diff(storedDate, "days") < OUTREACH_EMAIL_ACCOUNTS_RENTION_PERIOD
    ) {
      return item.data;
    }
  }
  return new Promise((resolve) => {
    api.getOutreachEmailAccounts(publicationId, (response) => {
      if (response.status === 200) {
        localStorage.setItem(
          OUTREACH_LOCAL_STORAGE_KEY,
          JSON.stringify({
            data: response.data,
            storedDate: new Date()
          })
        );
        resolve(response.data);
      } else {
        console.log("Error fetching outreach email accounts", response);
        resolve([]);
      }
    });
  });
}

export async function getApolloEmailAccounts(publicationId: string) {
  const APOLLO_LOCAL_STORAGE_KEY =
    getApolloEmailAccountStorageKey(publicationId);
  const APOLLO_SEQUENCES_RENTION_PERIOD = 2;
  let item = (await localStorage.getItem(APOLLO_LOCAL_STORAGE_KEY)) || "{}";
  item = JSON.parse(item);
  if (item && item.storedDate) {
    let storedDate = moment(item.storedDate);
    if (moment().diff(storedDate, "days") < APOLLO_SEQUENCES_RENTION_PERIOD) {
      return item.data;
    }
  }
  return new Promise((resolve) => {
    api.getApolloEmailAccounts(publicationId, (response) => {
      if (response.status === 200) {
        localStorage.setItem(
          APOLLO_LOCAL_STORAGE_KEY,
          JSON.stringify({
            data: response.data,
            storedDate: new Date()
          })
        );
        resolve(response.data);
      } else {
        console.log("Error fetching apollo sequences", response);
        resolve([]);
      }
    });
  });
}

export function getDateFromDeadlineDays(
  date: any,
  deadlineDays: any,
  isPrevStage = true
) {
  if (!date) {
    return null;
  }

  if (deadlineDays < 0) {
    return moment();
  }

  const ZERO_DEADLINE_INTERVAL = 8;

  if (deadlineDays === 0) {
    //If deadline interval is zero, set default interval as 8 hours
    if (!isPrevStage) {
      date = moment(date).add(ZERO_DEADLINE_INTERVAL, "hours");
      if (!moment(date, "DD-MM-YYYY").isBusinessDay()) {
        date = moment(date).nextBusinessDay()._d;
      }
    } else {
      date = moment(date).subtract(ZERO_DEADLINE_INTERVAL, "hours");
      if (!moment(date, "DD-MM-YYYY").isBusinessDay()) {
        date = moment(date).prevBusinessDay()._d;
      }
    }
  } else {
    if (!isPrevStage) {
      // Add deadline days from selected date
      date = addBusinessDays(moment(date), deadlineDays);
    } else {
      // Reduce deadline days from selected date
      date = reduceBusinessDays(moment(date), deadlineDays);
    }
  }
  // Check the date is in past
  if (!isPastDate(date)) {
    return date;
  }

  return null;
}

export function validateMinimumAndMaximumValue(
  value: any,
  minimumValue: any,
  maximumValue: any
) {
  try {
    if (
      !isNaN(parseInt(minimumValue)) &&
      parseInt(value) < parseInt(minimumValue)
    ) {
      return {
        valid: false,
        error: "This is smaller than the minimum allowed value"
      };
    }

    if (
      !isNaN(parseInt(maximumValue)) &&
      parseInt(value) > parseInt(maximumValue)
    ) {
      return {
        valid: false,
        error: "This is larger than the maximum allowed value"
      };
    }
  } catch (error) {}

  return {
    valid: true
  };
}

export function isJSON(str: any) {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) {}
  return false;
}

/**Allow user to use shift key to select mutiple checkboxes
 * @param items - list of all items
 * @param selectedItems -  list of all selected items
 * @param prevSelectedItemIndex - index of first selected item
 * @param currentSelectedItemIndex - index of last selected item
 * @param isChecked - if the last selected checkbox checked
 * @param generateItemValue - function to generate value of checkbox
 * @returns selectedItems - list of all selected items in range along with exiting values
 **/
export function multiSelect(
  items: any,
  selectedItems: any,
  prevSelectedItemIndex: any,
  currentSelectedItemIndex: any,
  isChecked: any,
  generateItemValue: any
) {
  let startIndex = Math.min(currentSelectedItemIndex, prevSelectedItemIndex);
  let endIndex = Math.max(currentSelectedItemIndex, prevSelectedItemIndex);
  if (isChecked) {
    for (let index = startIndex; index <= endIndex; index++) {
      let item = items[index];
      let position = selectedItems.findIndex(
        (selectedItem: any) => selectedItem._id === item._id
      );
      if (position > -1) {
        continue;
      }
      selectedItems.push(generateItemValue(item));
    }
  } else {
    for (let index = startIndex; index <= endIndex; index++) {
      let item = items[index];
      let position = selectedItems.findIndex(
        (selectedItem: any) => selectedItem._id === item._id
      );
      if (position > -1) {
        selectedItems.splice(position, 1);
      }
    }
  }
  return selectedItems;
}

export function isMacOs() {
  const userAgent = window.navigator.userAgent;
  return /Mac OS|macOS/i.test(userAgent);
}

export function cleanFileNameForUrl(
  sentence: any,
  regex: any,
  replaceText = "_"
) {
  try {
    // Match all characters with regex, replace mismatched character with replace text
    return Array.from(sentence)

      .map((character) => (character.match(regex) ? character : replaceText))
      .join("");
  } catch (error) {}
  return sentence;
}

export function removeImageExtensionFromName(name) {
  try {
    if (!name || !name.includes(".")) {
      return name;
    }

    if (imageExtensions.includes(`.${name.split(".").pop()}`)) {
      let nameArr = name.split(".");
      nameArr.pop();
      return nameArr.join(".");
    }
  } catch (error) {
    console.log(error);
  }

  return name;
}

export function redirectToLoginPage() {
  window.open(`/login?next_url=${window.location.href}`, "_self");
}

export function processEmojiCompletion(
  searchTerm: any,
  renderList: any,
  mentionChar: any
) {
  if (!searchTerm) {
    return;
  }
  var filtered = emojis.filter(function (emoji) {
    return (
      (emoji.keywords && emoji.keywords.includes(searchTerm.toLowerCase())) ||
      emoji.name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
      emoji.shortName.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  });
  filtered = filtered.slice(0, 10);
  let emojiList = [];
  for (let element of filtered) {
    let emoji = Emoji.unifiedToNative(element.unified);
    emojiList.push({
      id: element.name,
      value: emoji + " : " + element.name,
      emoji: emoji
    });
  }
  renderList(emojiList, searchTerm);
}

export function getAvatarName(name: string = "") {
  if (!name) {
    return "";
  }

  return (
    name
      .replace(/[^A-Za-z ]/g, "")
      .split(" ")
      .filter((word) => word)
      .map((word) => word[0])
      .slice(0, 2)
      .join("") || ""
  );
}

export function sentenceCase(str: any) {
  if (str === null || str === "") {
    return false;
  } else {
    str = str.toString();
  }

  return str.replace(/\w\S*/g, function (txt: any) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getStatusColor(status: any) {
  switch (status.toLowerCase()) {
    case topicStatus.DRAFT: {
      return designColors.grayScale[60];
    }
    case topicStatus.PUBLISHED: {
      return designColors.success[50];
    }
    case topicStatus.SCHEDULED: {
      return designColors.primary[50];
    }
    case topicStatus.OVERDUE: {
      return designColors.alert[50];
    }
    default: {
      return designColors.grayScale[60];
    }
  }
}

// Smooth scroll to a specified position with a given duration
export function scrollToPositionWithDuration(
  targetPosition: any,
  duration: any
) {
  var startPosition = window.pageYOffset;
  var distance = targetPosition - startPosition;
  var startTime: any = null;

  // Animation function for smooth scroll
  function animation(currentTime: any) {
    if (startTime === null) startTime = currentTime;
    var timeElapsed = currentTime - startTime;
    var scrollAmount = easeInOutQuad(
      timeElapsed,
      startPosition,
      distance,
      duration
    );
    window.scrollTo(0, scrollAmount);

    // Continue animation until duration is reached
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  // Easing function for smooth scroll
  function easeInOutQuad(
    currentTime: any,
    startValue: any,
    changeInValue: any,
    duration: any
  ) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return (changeInValue / 2) * currentTime * currentTime + startValue;
    }
    currentTime--;
    return (
      (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue
    );
  }

  // Start the smooth scroll animation
  requestAnimationFrame(animation);
}

export function scrollToBottom(element: HTMLElement | null, delay = 400) {
  if (!element) {
    return;
  }
  setTimeout(() => {
    element.scrollTo({
      top: element.scrollHeight,
      behavior: "smooth"
    });
  }, delay);
}

export function getDomain(url: any) {
  try {
    return new URL(url).hostname.replace("www.", "");
  } catch (error) {
    return "";
  }
}

export function getCRMLink(crmId: string, dealId: string) {
  if (!crmId || !dealId) {
    return "#";
  }
  if (crmId.indexOf("salesforce.com") >= 0) {
    return `${crmId}/lightning/r/Opportunity/${dealId}/view`;
  }
  return `https://app.hubspot.com/contacts/${crmId}/record/0-3/${dealId}`;
}

// Function to compare if two arrays are the same, handling objects and strings
export function isBothArraysSame(arr1: any, arr2: any) {
  try {
    arr1 = arr1 || [];
    arr2 = arr2 || [];

    if (arr1.length !== arr2.length) {
      return false;
    }

    // If array values are object, and object fields are not in the same order, comparison is difficult
    // Convert objects to sorted string representations for comparison
    const sortedArr1 = arr1
      .map((element: any) => {
        if (typeof element === "object") {
          // if value is an object
          // Get keys from object, sort all keys and construct an object by order of sorted keys using reduce
          return JSON.stringify(
            Object.keys(element)
              .sort()
              .reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: element[key]
                }),
                {}
              )
          );
        }
        return element;
      })
      .sort();

    const sortedArr2 = arr2
      .map((element: any) => {
        if (typeof element === "object") {
          return JSON.stringify(
            Object.keys(element)
              .sort()
              .reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: element[key]
                }),
                {}
              )
          );
        }
        return element;
      })
      .sort();

    // Check if every value in sortedArr1 is included in sortedArr2
    return sortedArr1.every((value: any, index: any) =>
      sortedArr2.includes(value)
    );
  } catch (error) {
    console.log(error);
  }
}

export function convertHTMLToMarkdown(html: any) {
  let markdown = turndownService.turndown(html);
  return markdown;
}

export function isMarkdownOnlyHeadingsAndLists(text: any) {
  try {
    const parsed = remark().parse(text);
    let isHeadingPresent = false;

    // Iterate over the children of the parsed markdown
    for (let i = 0; i < parsed?.children?.length; i++) {
      isHeadingPresent =
        isHeadingPresent || parsed.children[i].type === "heading";
      // If the type of the child is neither 'heading' nor 'list', return false
      if (
        parsed.children[i].type !== "heading" &&
        parsed.children[i].type !== "list" &&
        parsed.children[i].type !== "paragraph"
      ) {
        return false;
      }
    }
    return isHeadingPresent;
  } catch (error) {
    return false;
  }
}

export function convertTextIntoUUID(inputString: any) {
  return uuidv5(inputString, uuidv5.DNS);
}

export function getTextLengthOfHTML(html: any) {
  // We use formatter at backend to add new line after each paragraph
  // Here we need to manually add new line after each paragraph
  html = html?.replaceAll?.("</p>", "</p>\n") || html;
  return (
    new DOMParser()
      ?.parseFromString(html, "text/html")
      ?.body?.textContent?.trim()?.length || 0
  );
}

export function convertLinkedInPostTextToPreviewText(post: string) {
  // This regular expression is used to match LinkedIn mentions in the post text.
  // Mentions are identified by the 'mention' class and a series of data attributes.
  // The regex will match any span element with the 'mention' class and the specified data attributes.
  const regex =
    /<span class="mention"[^>]*data-denotation-char="@"[^>]*data-id="[^"]*"[^>]*data-value="[^"]*"[^>]*>.*?<\/span>﻿<\/span>/gi;
  return post.replace(regex, (match) => {
    return match
      .replace(/<\/span>(?!@)/, "</span></span>")
      .replace(/<\/span>@/, '</span><span style="color:blue">');
  });
}

/**
 * Generates a color based on a given string.
 * @param {string} str - The string to generate the color from.
 * @returns {string} The generated color in HSL format.
 */
export function stringToColor(str: string) {
  str = str || "";
  let hash = 0;
  let index;
  // Loop through each character in the string and calculate the hash value.
  for (index = 0; index < str.length; index += 1) {
    hash = str.charCodeAt(index) + ((hash << 5) - hash);
  }
  const lightness = 40; // Set the lightness value to 40%.
  let color = "hsl(";
  color += hash % 360; // Use the hash value to determine the hue (0-359 degrees).
  color += ", 100%, " + lightness + "%)"; // Set the saturation to 100% and the lightness to the previously defined value.

  return color; // Return the generated color.
}

export function trimTrailingSlash(url: any) {
  try {
    return url.endsWith("/") ? url.slice(0, -1) : url;
  } catch (error) {}
  return url;
}

export function removeHashFromUrl(url: any) {
  try {
    if (!url?.trim()) {
      return "";
    }
    let parsedUrl = new URL(url);
    parsedUrl.hash = "";
    parsedUrl.pathname = trimTrailingSlash(parsedUrl.pathname);
    return parsedUrl.toString();
  } catch (error) {}
  return url;
}

export function countOccurrences(text, keyword, similarKeywords = []) {
  // Use a regular expression to split the text by the keyword and count the occurrences
  if (!similarKeywords.includes(keyword)) {
    similarKeywords.push(keyword);
  }
  //change all to lowercase
  similarKeywords = similarKeywords.map((keyword) => keyword.toLowerCase());
  let count = 0;
  similarKeywords.forEach((similarKeyword) => {
    let similarKeywordCount = (
      text.match(new RegExp("\\b" + similarKeyword + "\\b", "g")) || []
    )?.length;
    count += similarKeywordCount;
  });

  return count;
}

export function getRelativeDate(modifiedOn: string) {
  try {
    return getDifferenceFromNow(modifiedOn) < 30
      ? getRelativeTime(modifiedOn)
      : formatDateWithYear(modifiedOn);
  } catch (error) {}
  return modifiedOn;
}

export function getYoutubeEmbedUrl(youtubeUrl) {
  // Extract the video ID using a regular expression
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = youtubeUrl.match(regExp);

  // Check if the video ID was found
  const videoId = match && match[2].length === 11 ? match[2] : null;

  // Return the embed URL if the video ID is valid, otherwise return null
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
}

export function getShortVersionOfText(text: string, length: number) {
  try {
    if (!text) {
      return "";
    }
    if (text.trim().length > length - 3) {
      text = text.trim().slice(0, length).trim();
      text = text.slice(0, text.lastIndexOf(" ")) + "..."; // Last word may or may not be incomplete, Removing last word
    }
    return text.trim();
  } catch (error) {}

  return text;
}

export function extractTextFromHTML(html: string) {
  return html
    .replace(/<[^>]*>?/gm, "")
    .replace(/&nbsp;/g, " ")
    .trim();
}

/**
 * This function cleans a URL by removing query parameters and trailing slashes.
 *
 * @param {string} url - The URL to be cleaned.
 * @returns {string} - The cleaned URL without query parameters and trailing slashes.
 */
export function cleanURL(url) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.origin}${parsedUrl.pathname.replace(/\/+$/, "")}`;
  } catch (err) {
    console.log(err);
  }
  return url;
}

export function validateCustomField(
  type: string,
  value: string,
  required: boolean,
  minimumValue: number,
  maximumValue: number
) {
  try {
    if (!type) {
      return "";
    }

    if (required && type === customFieldDataType.NUMBER && isNaN(value)) {
      return "Please enter a number";
    }

    if (
      required &&
      [customFieldDataType.DATE, customFieldDataType.IMAGE].includes(type) &&
      !value
    ) {
      return "Please upload an image";
    }

    if (
      required &&
      type === customFieldDataType.RICH_TEXT &&
      isDeltaEmpty(value)
    ) {
      return "Please fill this out. It's required.";
    }

    if (
      required &&
      ![
        customFieldDataType.DATE,
        customFieldDataType.IMAGE,
        customFieldDataType.NUMBER,
        customFieldDataType.RICH_TEXT,
        customFieldDataType.BOOLEAN
      ].includes(type) &&
      !value?.length
    ) {
      return "Please fill this out. It's required.";
    }

    if ((minimumValue || minimumValue === 0) && value?.length < minimumValue) {
      return `This needs to be at least ${minimumValue}${
        type === customFieldDataType.NUMBER ? "." : " characters long."
      }`;
    }

    if ((maximumValue || maximumValue === 0) && value?.length > maximumValue) {
      return `This can be at most ${maximumValue}${
        type === customFieldDataType.NUMBER ? "." : " characters long."
      }`;
    }
  } catch (error) {}

  return "";
}

function isDeltaEmpty(delta: string) {
  try {
    if (!delta?.trim()) {
      return true;
    }

    if (typeof delta === "string" && isJSON(delta)) {
      delta = JSON.parse(delta);
    }

    if (typeof delta === "object") {
      const plainTextContent = delta?.ops.map((op) => op.insert).join("");
      return !plainTextContent?.trim()?.length;
    }

    let text = getTextFromHtml(delta);
    return !text?.trim()?.length;
  } catch (error) {}
  return false;
}

export function extractTableFromPastedText(text: string) {
  const markdownTableRegEx = /\|(.+)\|\n\|(.+)\|/g;
  const markdownMatch = markdownTableRegEx.exec(text);

  if (markdownMatch) {
    const lines = text.split("\n");
    const headers = lines[0]
      .split("|")
      .map((header) => ({ value: header.trim() }))
      .slice(1, -1);

    const data = lines.slice(2).map((line) => ({
      id: uuid(),
      data: line
        .split("|")
        .slice(1, -1)
        .map((value) => ({ value: value.trim() }))
    }));

    return { headers, data };
  }

  const tsvTableRegEx = /(.+)\t(.+)\n(.+)\t(.+)/g;
  const tsvMatch = tsvTableRegEx.exec(text);

  if (tsvMatch) {
    const lines = text.split("\n");
    const headers = lines[0]
      .split("\t")
      .map((header) => ({ value: header.trim() }));
    const data = lines.slice(1).map((line) => ({
      id: uuid(),
      data: line.split("\t").map((value) => ({ value: value.trim() }))
    }));

    return { headers, data };
  }
}

// Function to generate a unique ObjectId string
export function objectId() {
  // Get the current timestamp in seconds and convert it to hexadecimal
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);

  // Create the ObjectId by concatenating the timestamp with a random hexadecimal string
  const objectId =
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, () => {
        // Replace each 'x' with a random hexadecimal digit
        return Math.floor(Math.random() * 16).toString(16);
      })
      .toLowerCase();

  return objectId;
}

export function roundToNearest30Seconds(timestampInSeconds: number): number {
  if (!timestampInSeconds) {
    return 0;
  }
  return Math.ceil(timestampInSeconds / 30) * 30;
}

export function secondsToRelativeTime(seconds: number) {
  if (seconds < 0) {
    return "30s";
  }

  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [];
  if (days > 0) {
    parts.push(`${days}d`);
  }
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (remainingSeconds > 0) {
    parts.push(`${remainingSeconds}s`);
  }

  return parts.join(" ");
}

export function formatReadTime(
  readTime: number,
  returnType: string = readTimeTypes.STRING
): number | string {
  readTime = Math.ceil(readTime);
  if (returnType === readTimeTypes.NUMBER) {
    return readTime || 0;
  }

  if (readTime < 60) {
    return `${readTime} min${readTime === 1 ? "" : "s"}`;
  }

  const hours = Math.floor(readTime / 60);
  const minutes = readTime % 60;

  if (minutes === 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} min${
    minutes > 1 ? "s" : ""
  }`;
}

export function getHTMLMetadataForSummary(htmlText: string) {
  const HEADER_TAG_REGEX = /^h[1-6]$/i;
  try {
    let htmlDocument = new DOMParser().parseFromString(htmlText, "text/html");
    htmlDocument = htmlDocument.body;

    const hasHeaderTags =
      htmlDocument.querySelectorAll("h1, h2, h3, h4, h5, h6").length > 0;
    const startsWithHeaderTag = HEADER_TAG_REGEX.test(
      htmlDocument.firstChild?.tagName || ""
    );
    const startsWithParagraphTag = htmlDocument.firstChild?.tagName === "P";
    const hasParagraphTags = htmlDocument.querySelectorAll("p").length > 0;
    const onlyHasParagraph =
      htmlDocument.childNodes.length === 1 && startsWithParagraphTag;
    const onlyHasHeader =
      htmlDocument.childNodes.length === 1 && startsWithHeaderTag;
    const hasMoreElements = htmlDocument.childNodes.length > 1;

    return {
      hasHeaderTags,
      startsWithHeaderTag,
      startsWithParagraphTag,
      hasParagraphTags,
      onlyHasParagraph,
      onlyHasHeader,
      hasMoreElements
    };
  } catch (error) {
    return null;
  }
}

export function deepCloneObject(obj: unknown) {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {}

  return obj;
}

export function normalizeDomain(domain) {
  if (!domain) {
    return "";
  }
  return domain
    .toLowerCase()
    .trim()
    .replace(/^https?:\/\//, "") // Remove protocol (http://, https://)
    .replace(/^www\./, "") // Remove www. prefix if it exists
    .split("/")[0] // Remove pathname by splitting. ex: google.com/example to google.com
    .split("?")[0] // Remove search params by splitting. ex: google.com?q=search to google.com
    .split("#")[0]; // Remove hash by splitting. ex: google.com#search-bar to google.com
}

export function deepCloneAndModifyObject(
  obj: unknown,
  key: string,
  value: unknown
) {
  const clonedObj = deepCloneObject(obj);
  if (typeof clonedObj === "object" && clonedObj !== null) {
    clonedObj[key] = value;
  }
  return clonedObj;
}

export function isValidLinkedInProfileUrl(profile: string) {
  const profileRegex = /^(https?:\/\/(www\.)?linkedin\.com\/in\/)/;

  try {
    if (!profileRegex.test(profile)) {
      return false;
    }

    // Parse the url, if url is invalid this will throw an error
    new URL(profile);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
