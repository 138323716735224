// @ts-nocheck
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import firebase from "firebase/app";
import {
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  ListSubheader,
  Paper,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";

import CopyToClipboard from "react-copy-to-clipboard";

import { Helmet } from "react-helmet";
import { notify } from "../Components/CustomNotifications";

import ImagePicker from "../Components/ImagePicker";

import Loading from "../Components/Loading";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import colors, { designColors } from "../Themes/Colors";
import Images from "../Themes/Images";
import PageAccessContext from "../Utils/PageAccessContext";
import { uploadBlobImage } from "../Utils/Storage";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  datePickerDefault,
  facebookPostStatus,
  facebookPublishTypes,
  pageAccess,
  postStatus,
  socialMediaType,
  socialMediaStatus,
  generateSocialPostStatus,
  URL_REPLACE_TEXT,
  FACEBOOK_ALLOWED_DOMAINS
} from "../Utils/Types";
import {
  getQueryParamsFromURL,
  getScheduleDateFromURL,
  hideOtherChatWidgets,
  isAccessDisabled,
  showOtherChatWidgets
} from "../Utils/UserUtils";
import styles from "./styles/FacebookShareStyle";

import CustomFlatPicker from "../Components/CustomFlatPicker";
import { ReactComponent as AskAIIcon } from "../Images/icon24/ask-ai.svg";
import { faTerminal } from "@fortawesome/free-solid-svg-icons";
import { space } from "../Config/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import AIInstructionsDialog from "../Components/AIInstructionsDialog";

const Page404 = React.lazy(() => import("./Page404"));

const _ = require("lodash");
const api = API.create();
const DEFAULT_INDEX = -1;

class FacebookShare extends React.Component {
  static contextType = PageAccessContext;
  fetchSocialPost: any;
  imagePickerRef: any;
  saveFacebookPostBodyDetailsDebounce: any;
  constructor(props: any) {
    super(props);
    let { backpage, agencyView } = getQueryParamsFromURL(
      window.location.search,
      "facebook/drafts"
    );
    this.state = {
      id:
        props.match?.params?.facebookid ||
        props.match?.params?.id ||
        props.postId,
      facebookPostId: null,
      loading: true,
      error: false,
      errorMessage: "",
      openImagePicker: false,
      facebookHandle: null,
      facebookPosts: [],
      postUrl: null,
      publication: null,
      post: null,
      facebookPostIndex: null,
      shareInitialized: false,
      showDeleteConfirmDialog: false,
      deleteInitialized: false,
      backpage,
      agencyView,
      showDateTimePicker: false,
      scheduledOn: getScheduleDateFromURL(window.location.search, null),
      socialPostText: "",
      prevSocialPostText: [],
      showCopyConfirmation: false,
      copyIndex: DEFAULT_INDEX,
      scheduledPostDates: [],
      instructions: "",
      showInstructionDialog: false
    };
    this.imagePickerRef = React.createRef();
    this.fetchSocialPost = [];
  }

  componentDidMount = () => {
    this.load();
    this.saveFacebookPostBodyDetailsDebounce = _.debounce(
      this.saveFacebookPostDetails,
      1000
    );
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillMount() {
    showOtherChatWidgets();
  }

  componentWillUnmount() {
    this.fetchSocialPost?.forEach((interval: any) => {
      this.clearIntervalToFetchSocialPost(interval);
    });
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          facebookPostId: null,
          loading: true,
          error: false,
          errorMessage: "",
          openImagePicker: false,
          facebookHandle: null,
          facebookPosts: [],
          postUrl: null,
          publication: null,
          post: null,
          facebookPostIndex: null,
          shareInitialized: false,
          showDeleteConfirmDialog: false,
          deleteInitialized: false,
          showDateTimePicker: false,
          scheduledOn: getScheduleDateFromURL(window.location.search, null),
          instructions: "",
          showInstructionDialog: false
        },
        this.load
      );
    }
  }

  load = () => {
    api.getFacebookShareDetails(this.state.id, (res: any) => {
      if (res.status === 200) {
        let {
          publication,
          post,
          postUrl,
          facebookHandle,
          facebookPosts,
          facebookPostId,
          draftPostsCount
        } = res.data;

        if (facebookPosts.length === 0) {
          facebookPosts.push(
            this.addAccountToFacebookPosts(post, postUrl, publication)
          );
        }

        this.setState({
          publication,
          post,
          postUrl,
          facebookHandle,
          facebookPosts,
          facebookPostId,
          loading: false,
          draftPostsCount,
          instructions: publication?.linkedInCustomPrompt || ""
        });
      } else {
        this.setState({ error: true, errorMessage: res.data, loading: false });
      }
    });
  };

  addAccountToFacebookPosts = (post: any, postUrl: any, publication: any) => {
    let { scheduledOn, socialPostText } = this.state;
    let utm = nanoid(10);
    let newFacebookPost = {
      text:
        socialPostText ||
        this.getFacebookBodyText(post, postUrl, publication, utm),
      images: [],
      scheduledOn: scheduledOn,
      account: null,
      accountName: "",
      publishType: scheduledOn
        ? facebookPublishTypes.LATER
        : post
        ? facebookPublishTypes.WITH_POST
        : facebookPublishTypes.NOW,
      utm,
      socialPostLoading: false
    };

    return newFacebookPost;
  };

  getFacebookBodyText = (
    post: any,
    postUrl: any,
    publication: any,
    utm: any
  ) => {
    let text = "";
    if (post && postUrl) {
      text = `We just published a blog post on ${post.title} at ${publication.name}.\nRead the full post here: ${URL_REPLACE_TEXT}`;
    }
    return text;
  };

  handleFacebookConnect = () => {
    let { publication, post, scheduledOn } = this.state;
    api.connectToFacebook(
      publication._id,
      post ? "review" : "facebook",
      post?._id,
      { scheduledOn: scheduledOn }
    );
  };

  handleAccountChange = (event: any, facebookPostIndex: any) => {
    let { facebookPosts } = this.state;
    let account = event.target.value;

    let accountName = this.getAccountNameByUserId(account);
    facebookPosts[facebookPostIndex].account = account;
    facebookPosts[facebookPostIndex].accountName = accountName;

    this.setState({ facebookPosts }, () => {
      this.saveFacebookPostDetails(facebookPostIndex);
    });
  };

  getAccountNameByUserId = (account: any) => {
    let { facebookHandle } = this.state;
    let accountIndex = facebookHandle.accounts.findIndex(
      (facebookAccount: any) => facebookAccount.id === account
    );
    if (accountIndex < 0) {
      return "";
    }
    return facebookHandle.accounts[accountIndex].name;
  };

  handleFacebookPostBodyChange = (value: any, facebookPostIndex: any) => {
    let { facebookPosts } = this.state;
    facebookPosts[facebookPostIndex].text = value;
    facebookPosts[facebookPostIndex].isTextDirty = true;
    facebookPosts[facebookPostIndex].socialPostLoading = false;

    //stop AI post generation if user types
    this.setState({ facebookPosts }, () => {
      this.saveFacebookPostBodyDetailsDebounce(facebookPostIndex);
    });
  };

  getScheduledPostDates = () => {
    let { facebookPosts, publication, facebookPostIndex } = this.state;
    api.getScheduledPostDates(
      publication._id,
      facebookPosts[facebookPostIndex]?._id,
      facebookPosts[facebookPostIndex]?.account,
      socialMediaType.FACEBOOK,
      (res: any) => {
        if (res.status === 200) {
          this.setState(
            { scheduledPostDates: res.data?.scheduledPostDates || [] },
            () => this.setState({ showDateTimePicker: true })
          );
        }
      }
    );
  };

  handlePublishTypeChange = (event: any, facebookPostIndex: any) => {
    let { facebookPosts } = this.state;
    if (event.target.value === facebookPublishTypes.LATER) {
      // handled by onClick - handlePublishLater
      return;
    } else {
      facebookPosts[facebookPostIndex].publishType = event.target.value;
      facebookPosts[facebookPostIndex].scheduledOn = null;
    }
    this.setState({ facebookPosts, facebookPostIndex }, () => {
      this.saveFacebookPostDetails(facebookPostIndex);
    });
  };

  handlePublishLater = (index: any) => {
    let { facebookPosts } = this.state;
    this.setState({ facebookPostIndex: index }, () =>
      this.getScheduledPostDates(index)
    );
  };

  getScheduleDate = (scheduledOn: any) => {
    let formattedScheduledDate = "";
    if (scheduledOn) {
      formattedScheduledDate = formatForTimeWithTimeZone(moment(scheduledOn));
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  onScheduleDateChange = (date: any) => {
    let { facebookPosts, facebookPostIndex } = this.state;
    let currentDate = new Date();
    if (currentDate > date) {
      return;
    }

    facebookPosts[facebookPostIndex].scheduledOn = date;
    facebookPosts[facebookPostIndex].publishType = facebookPublishTypes.LATER;
    this.setState({ facebookPosts }, () =>
      this.saveFacebookPostDetails(facebookPostIndex)
    );
  };

  saveFacebookPostDetails = (facebookPostIndex = null) => {
    let { facebookPosts, post, publication } = this.state;
    if (!facebookPostIndex && facebookPostIndex !== 0) {
      return;
    }

    let {
      _id,
      text,
      images,
      account,
      publishType,
      scheduledOn,
      isTextDirty,
      accountName,
      utm
    } = facebookPosts[facebookPostIndex];

    api.saveFacebookPostDetails(
      _id,
      publication._id,
      post?._id || "",
      text,
      images,
      account,
      publishType,
      scheduledOn,
      isTextDirty,
      accountName,
      utm,
      (res: any) => {
        if (res.status === 200) {
          facebookPosts[facebookPostIndex]._id = res.data.facebookPostId;
          this.setState({ facebookPosts });
        }
      }
    );
  };

  handlePostToAnotherAccount = () => {
    let { facebookPosts, post, postUrl, publication } = this.state;
    if (facebookPosts.length > 0 && facebookPosts[0]) {
      let lastPostIndex = facebookPosts.length - 1; //Fetch details from last post
      let utm = nanoid(10);
      let { images, publishType, text, scheduledOn } =
        facebookPosts[lastPostIndex];
      let facebookPost = {
        images: [...images],
        text: text || this.getFacebookBodyText(post, postUrl, publication, utm),
        publishType,
        scheduledOn,
        postUrl,
        status: socialMediaStatus.DRAFT,
        utm
      };

      facebookPosts.push(facebookPost);
    } else {
      facebookPosts.push(
        this.addAccountToFacebookPosts(post, postUrl, publication)
      );
    }
    this.setState({
      facebookPosts
    });
  };

  handleShareToFacebook = () => {
    let { facebookPosts, publication, post } = this.state;
    let valid = true;
    let isPublishAccessDisabled = isAccessDisabled(
      publication._id,
      this.context,
      pageAccess.PUBLISH_POST
    );
    if (isPublishAccessDisabled) {
      notify.show("You don’t have the right permissions to publish", "error");
      return;
    }

    if (this.state.shareInitialized) {
      return;
    }

    this.setState({ shareInitialized: true }, () => {
      for (let facebookPost of facebookPosts) {
        if (!facebookPost.account) {
          valid = false;
          notify.show("Select Facebook account to share", "error");
          break;
        }
        if (!facebookPost.text) {
          valid = false;
          notify.show("Write something to share", "error");
          break;
        }
      }

      if (!valid) {
        this.setState({
          shareInitialized: false
        });
        return;
      }

      api.shareToFacebook(
        publication._id,
        post?._id || "",
        facebookPosts,
        (res: any) => {
          if (res.status === 200) {
            let facebookPostResponses = res.data;
            let hasScheduled = false;
            for (let facebookPostResponse of facebookPostResponses) {
              if (facebookPostResponse.scheduledOn && !hasScheduled) {
                hasScheduled = true;
              }
              if (facebookPostResponse?.url) {
                setTimeout(window.open(facebookPostResponse.url), 1000);
              }
            }
            if (hasScheduled) {
              notify.show("Your Facebook post was scheduled", "success");
            }
            this.gotoBackPageORClearFields(facebookPostResponses);
            return;
          } else {
            this.setState({ shareInitialized: false });
            notify.show("Oops, something went wrong.", "error");
          }
        }
      );
    });
  };

  gotoBackPageORClearFields = (facebookPostResponses = []) => {
    let { isReviewScreen } = this.props;

    let { publication, backpage, agencyView, facebookPostId } = this.state;

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    // open facebook event in calendar once posted when in dedicated share screen
    let scheduledPosts = facebookPostResponses.filter(
      (post) => post.scheduledOn
    );

    if (scheduledPosts[0]?._id && !isReviewScreen) {
      let toUrl = `/${publication._id}/create/calendar/?mediaId=${
        scheduledPosts[0]._id
      }&createdOn=${new Date(scheduledPosts[0].scheduledOn).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (facebookPostId) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ shareInitialized: false }, this.load);
  };

  toggleImagePicker = (facebookPostIndex = null) => {
    let { openImagePicker } = this.state;
    this.setState({
      facebookPostIndex,
      openImagePicker: !openImagePicker
    });
  };

  onImageSelected = (imageUrl: any) => {
    let { facebookPosts, facebookPostIndex } = this.state;

    let images = facebookPosts[facebookPostIndex].images || [];
    images.push(imageUrl);
    facebookPosts[facebookPostIndex].images = images;

    this.setState(
      { facebookPosts, facebookPostIndex: null, openImagePicker: false },
      () => {
        this.saveFacebookPostDetails(facebookPostIndex);
      }
    );
  };

  onSelectFile = (file: any) => {
    const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB Limit for image
    const GIF_SIZE_LIMIT = 15 * 1024 * 1024; // 15MB Limit for image
    if (!file.type.includes("image")) {
      notify.show("Only images are allowed", "error");
    }
    if (file.type === "image/gif" && file.size > GIF_SIZE_LIMIT) {
      notify.show("Upload a gif image that's smaller than 15 MB", "error");
      return;
    }
    if (file.size > IMAGE_SIZE_LIMIT) {
      notify.show("Upload a image that's smaller than 5 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.state.publication.domain
        );
      }
      if (!imageUrl) {
        return;
      }

      let { facebookPosts, facebookPostIndex } = this.state;

      let images = facebookPosts[facebookPostIndex].images || [];
      images.push(imageUrl);
      facebookPosts[facebookPostIndex].images = images;
      this.setState(
        { facebookPosts, openImagePicker: false, facebookPostIndex: null },
        () => {
          this.saveFacebookPostDetails(facebookPostIndex);
        }
      );
    });
    reader.readAsDataURL(file);
  };

  removeImageFromFacebookPost = (facebookPostIndex: any, imageIndex: any) => {
    let { facebookPosts } = this.state;
    facebookPosts[facebookPostIndex].images.splice(imageIndex, 1);
    this.setState({ facebookPosts }, () => {
      this.saveFacebookPostDetails(facebookPostIndex);
    });
  };

  toggleDeleteFacebookPostConfirmDialog = (facebookPostIndex: any) => {
    let { showDeleteConfirmDialog } = this.state;
    this.setState({
      showDeleteConfirmDialog: !showDeleteConfirmDialog,
      facebookPostIndex: facebookPostIndex
    });
  };

  deleteFacebookPost = () => {
    let {
      facebookPosts,

      publication,

      facebookPostIndex,

      facebookPostId,

      backpage
    } = this.state;

    let { postId } = this.props;

    if (!facebookPosts[facebookPostIndex]._id) {
      facebookPosts.splice(facebookPostIndex, 1);
      this.setState({
        facebookPosts,
        showDeleteConfirmDialog: false,
        facebookPostIndex: null
      });
      return;
    }

    this.setState({ deleteInitialized: true }, () => {
      api.deleteFacebookPost(
        publication._id,
        facebookPosts[facebookPostIndex]._id,
        postId,
        (res: any) => {
          if (res.status === 200) {
            facebookPosts.splice(facebookPostIndex, 1);
            this.setState({
              facebookPosts,
              showDeleteConfirmDialog: false,
              facebookPostIndex: null,
              deleteInitialized: false
            });
            if (facebookPostId) {
              this.props.history.push(`/${publication._id}/${backpage}`);
              return;
            }
            return;
          }
          this.setState({
            facebookPostIndex: null,
            showDeleteConfirmDialog: false,
            deleteInitialized: false
          });
          notify.show(res.data, "error");
          return;
        }
      );
    });
  };

  setIntervalToFetchSocialPost = (index: any) => {
    if (this.fetchSocialPost[index]) {
      return;
    }
    this.generateSocialPost(index, true);

    this.fetchSocialPost[index] = setInterval(() => {
      this.generateSocialPost(index, false);
    }, 5000);
  };

  clearIntervalToFetchSocialPost = (index: any) => {
    clearInterval(this.fetchSocialPost[index]);
    this.fetchSocialPost[index] = null;
  };

  generateSocialPost = (index: any, regenerate: any) => {
    let { post, facebookPosts, prevSocialPostText, instructions, publication } =
      this.state;
    facebookPosts[index].socialPostLoading = true;
    this.setState({
      facebookPosts
    });
    api.generateSocialPostFromBlog(
      publication._id,
      post._id,
      post.htmlText,
      regenerate,
      socialMediaType.LINKED_IN,
      instructions,
      facebookPosts[index]?.account || "",
      [], // samples,
      (res: any) => {
        if (res.status === 200) {
          let socialPost = res.data.socialPost;

          if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
            return;
          }

          this.clearIntervalToFetchSocialPost(index);
          facebookPosts[index].socialPostLoading = false;
          this.setState({
            facebookPosts
          });
          if (!socialPost?.body) {
            return;
          }

          this.setState({
            prevSocialPostText: [
              ...prevSocialPostText,
              facebookPosts[index]?.text || ""
            ],
            socialPostText: socialPost.body
          });
          this.handleFacebookPostBodyChange(socialPost.body, index);
        } else {
          facebookPosts[index].socialPostLoading = false;
          this.setState({
            facebookPosts
          });
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Try again.",
            "error"
          );
        }
      }
    );
  };

  handleDialogClose = () => {
    this.setState({
      showDeleteConfirmDialog: false,
      facebookPostIndex: null
    });
  };

  handleImagePickerClose = () => {
    if (this.imagePickerRef?.checkImageUploading?.()) {
      return;
    }
    this.setState({
      openImagePicker: false
    });
  };
  copyEverywhere = () => {
    let { facebookPosts, copyIndex } = this.state;
    for (let index = 0; index < facebookPosts.length; index++) {
      if (facebookPosts[index].status !== socialMediaStatus.COMPLETED) {
        facebookPosts[index].text = facebookPosts[copyIndex].text;
        facebookPosts[index].images = facebookPosts[copyIndex].images;
        facebookPosts[index].publishType = facebookPosts[copyIndex].publishType;
        facebookPosts[index].scheduledOn = facebookPosts[copyIndex].scheduledOn;
        facebookPosts[index].isTextDirty = true;
        this.setState({ facebookPosts }, () => {
          this.saveFacebookPostDetails(index);
        });
      }
    }
    this.toggleShowCopyConfirmation(DEFAULT_INDEX);
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  showInstruction = (showInstructionDialog: any) => {
    this.setState({
      showInstructionDialog
    });
  };

  onSaveInstructions = (instructions: any) => {
    this.setState({
      instructions: instructions.trim(),
      showInstructionDialog: false
    });
  };

  render() {
    let { classes, isReviewScreen = false, postId } = this.props;
    let {
      loading,

      error,

      errorMessage,

      openImagePicker,

      facebookHandle,

      facebookPosts,

      postUrl,

      post,

      showDateTimePicker,

      shareInitialized,

      publication,

      showDeleteConfirmDialog,

      facebookPostId,

      facebookPostIndex,

      draftPostsCount,

      showCopyConfirmation,

      scheduledPostDates,

      showInstructionDialog,

      instructions
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    let showAdditionalPublishType =
      post &&
      ((post.status && post.status !== postStatus.PUBLISHED) ||
        post.scheduledOn);

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                Facebook {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            isReviewScreen ? classes.paper : classes.reducedPaperMargin
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.headerTitle}>
                <IconButton disabled size="large">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className={classes.facebookIcon}
                  />

                  <Typography variant="h500" className={classes.headerText}>
                    Create a Facebook Post
                  </Typography>
                </IconButton>
              </div>

              <div className={classes.header}>
                <div className={classes.draftDiv}>
                  <StyledButton
                    className={classes.facebookDraft}
                    variant="textsecondary"
                    size="small"
                    onClick={() => {
                      this.props.history.push(
                        `/${publication._id}/facebook/drafts`
                      );
                    }}
                  >
                    Drafts{" "}
                    <Typography
                      variant="h100"
                      style={{
                        marginLeft: 5,
                        color: designColors.grayScale[80]
                      }}
                    >
                      {draftPostsCount}
                    </Typography>
                  </StyledButton>
                </div>

                {facebookHandle.accounts?.length > 0 && (
                  <div className={classes.shareToFacebook}>
                    <StyledButton
                      onClick={this.handleShareToFacebook}
                      className={classes.shareToFacebookButton}
                      startIcon={
                        shareInitialized ? "" : <SendIcon size="small" />
                      }
                    >
                      {shareInitialized && (
                        <CircularProgress
                          size={20}
                          color="inherit"
                          style={{ marginRight: 10 }}
                        />
                      )}
                      {shareInitialized ? "Sharing" : "Share to Facebook"}
                    </StyledButton>
                  </div>
                )}
              </div>
            </>
          )}
          {facebookHandle.accounts?.length === 0 &&
          !FACEBOOK_ALLOWED_DOMAINS.includes(publication?.domain) ? (
            <Grid
              container
              direction="row"
              className={classes.connectAccountSection}
            >
              <img
                src={Images.socialMedia}
                className={classes.connectAccountImage}
                alt="Connect facebook account"
              />

              <Typography className={classes.connectAccountText}>
                Connect a Facebook account to start posting
              </Typography>

              <StyledButton
                onClick={this.handleFacebookConnect}
                style={{
                  background: colors.facebook,
                  color: colors.white,
                  marginTop: 25
                }}
                disabled={firebase.auth().currentUser ? false : true}
                startIcon={
                  <FontAwesomeIcon
                    icon={faFacebook}
                    title="Facebook"
                    style={{
                      color: colors.white
                    }}
                  />
                }
              >
                Connect to facebook
              </StyledButton>
            </Grid>
          ) : (
            <div>
              {facebookPosts.map(
                (facebookPost: any, facebookPostIndex: any) => {
                  return (
                    <Fragment>
                      <div
                        container
                        style={{
                          width: "100%",
                          display: "flex-end",
                          textAlign: "right"
                        }}
                      >
                        {facebookPostIndex !== 0 && (
                          <Divider style={{ margin: 20 }} />
                        )}
                        {facebookPost.status ===
                          facebookPostStatus.COMPLETED && (
                          <div className={classes.editableLabelDiv}>
                            <Typography className={classes.editableLabel}>
                              You can update this post on Facebook by publishing
                              again
                            </Typography>
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          isReviewScreen
                            ? classes.sectionReviewScreen
                            : classes.section
                        }
                      >
                        <Grid
                          container
                          item
                          id="Account"
                          xs={12}
                          className={classes.accountSection}
                        >
                          <Typography className={classes.cardTitle}>
                            Account
                          </Typography>

                          <Typography className={classes.cardSubtitle}>
                            Which Facebook group or page do you want to post to?
                          </Typography>

                          <Grid item>
                            <FormControl
                              variant="outlined"
                              size="medium"
                              style={{ width: "50%" }}
                              className={classes.input}
                            >
                              <StyledSelect
                                value={facebookPost.account}
                                onChange={(event: any) =>
                                  this.handleAccountChange(
                                    event,
                                    facebookPostIndex
                                  )
                                }
                                size="large"
                                disabled={
                                  facebookPost.status ===
                                  facebookPostStatus.COMPLETED
                                }
                                className={classes.input}
                              >
                                {facebookHandle.accounts?.map(
                                  (account: any, index: any) => {
                                    if (account.type === "category") {
                                      return (
                                        <ListSubheader
                                          key={index}
                                          className={classes.listSubheader}
                                        >
                                          {account.name}
                                        </ListSubheader>
                                      );
                                    }
                                    if (account.type === "item") {
                                      return (
                                        <StyledMenuItem
                                          key={index}
                                          value={account.id}
                                          className={classes.menuItem}
                                        >
                                          {account.name}
                                        </StyledMenuItem>
                                      );
                                    }
                                    return (
                                      <StyledMenuItem
                                        key={-1}
                                        value={null}
                                        className={classes.menuItem}
                                      >
                                        NONE
                                      </StyledMenuItem>
                                    );
                                  }
                                )}
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.accountSection}
                        >
                          <Typography className={classes.cardTitle}>
                            Body
                          </Typography>

                          <Grid item>
                            <Grid container>
                              <Grid item style={{ width: "100%" }}>
                                <StyledInput
                                  placeholder="Start typing your post"
                                  multiline
                                  maxRows={8}
                                  minRows={5}
                                  name="body"
                                  value={facebookPost.text}
                                  onChange={(event: any) =>
                                    this.handleFacebookPostBodyChange(
                                      event.target.value,
                                      facebookPostIndex
                                    )
                                  }
                                  className={classes.textAreaOutline}
                                  onKeyDown={(e: any) => {
                                    // if ctrl + z pressed
                                    if (e.ctrlKey && e.keyCode === 90) {
                                      let { prevSocialPostText } = this.state;
                                      if (prevSocialPostText.length) {
                                        let oldValue = prevSocialPostText.pop();
                                        this.setState({ prevSocialPostText });
                                        this.handleFacebookPostBodyChange(
                                          oldValue,
                                          facebookPostIndex
                                        );
                                      }
                                    }
                                  }}
                                />
                              </Grid>
                              {isReviewScreen && (
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  {facebookPost?.socialPostLoading ? (
                                    <CircularProgress
                                      style={{
                                        marginLeft: 20,
                                        marginRight: -60
                                      }}
                                      size={25}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: space.SMALL,
                                        marginRight: -60
                                      }}
                                    >
                                      <Tooltip title="Generate Social Post">
                                        <IconButton
                                          size="large"
                                          onClick={() =>
                                            this.setIntervalToFetchSocialPost(
                                              facebookPostIndex
                                            )
                                          }
                                        >
                                          <AskAIIcon />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Instructions">
                                        <IconButton
                                          size="large"
                                          style={{
                                            marginTop: space.XXS
                                          }}
                                          onClick={() =>
                                            this.showInstruction(true)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTerminal}
                                            color={colors.iconColor}
                                            style={{ width: 24, height: 24 }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>

                          <Grid item container direction="row">
                            {facebookPost.images.map(
                              (image: any, imageIndex: any) => {
                                //Displaying images if any exists
                                return (
                                  <div
                                    className={
                                      classes.facebookPostImageContainer
                                    }
                                    key={imageIndex}
                                  >
                                    <img
                                      key={imageIndex}
                                      alt="post pic"
                                      src={image}
                                      className={classes.facebookPostImage}
                                    />
                                    {facebookPost.status !==
                                      facebookPostStatus.COMPLETED && (
                                      <IconButton
                                        className={classes.closeIcon}
                                        onClick={() =>
                                          this.removeImageFromFacebookPost(
                                            facebookPostIndex,
                                            imageIndex
                                          )
                                        }
                                        size="large"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </Grid>
                          {facebookPost.images.length < 4 &&
                            facebookPost.status !==
                              facebookPostStatus.COMPLETED && (
                              <Grid item className={classes.imageIconContainer}>
                                <Tooltip title="Upload image">
                                  <IconButton
                                    onClick={() =>
                                      this.toggleImagePicker(facebookPostIndex)
                                    }
                                    size="large"
                                  >
                                    <ImageIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                        </Grid>

                        {postUrl && (
                          <Grid
                            container
                            item
                            id="Post"
                            xs={6}
                            className={classes.accountSection}
                          >
                            <Typography className={classes.cardTitle}>
                              Link to your post
                            </Typography>

                            <Typography className={classes.cardSubtitle}>
                              Copy this link into the comments of your post, or
                              use {URL_REPLACE_TEXT}, and we'll replace it with
                              the final URL.
                            </Typography>

                            <Grid item>
                              <InputBase
                                className={classes.postUrl}
                                value={
                                  postUrl +
                                  "?utm=" +
                                  (facebookPost.utm || nanoid(10))
                                }
                                multiline={true}
                                contentEditable={false}
                                onFocus={(event) => event.target.select()}
                                fullWidth
                                endAdornment={
                                  <InputAdornment position="end">
                                    <CopyToClipboard
                                      onCopy={() =>
                                        notify.show(
                                          "Copied to clipboard",
                                          "success"
                                        )
                                      }
                                      text={
                                        postUrl +
                                        "?utm=" +
                                        (facebookPost.utm
                                          ? facebookPost.utm
                                          : nanoid(10))
                                      }
                                    >
                                      <IconButton size="large">
                                        <FileCopyOutlinedIcon />
                                      </IconButton>
                                    </CopyToClipboard>
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          item
                          id="Post"
                          xs={6}
                          className={classes.accountSection}
                          style={{ marginBottom: 20 }}
                        >
                          <Typography className={classes.cardTitle}>
                            When
                          </Typography>

                          <Typography className={classes.cardSubtitle}>
                            Schedule when your Facebook post should go out
                          </Typography>

                          <Grid item>
                            <FormControl
                              variant="outlined"
                              size="large"
                              fullWidth
                              className={classes.input}
                            >
                              <StyledSelect
                                value={facebookPost.publishType}
                                onChange={(event: any) =>
                                  this.handlePublishTypeChange(
                                    event,
                                    facebookPostIndex
                                  )
                                }
                                size="large"
                                className={classes.input}
                              >
                                {!isReviewScreen && (
                                  <StyledMenuItem
                                    value={facebookPublishTypes.NOW}
                                  >
                                    Now
                                  </StyledMenuItem>
                                )}

                                <StyledMenuItem
                                  value={facebookPublishTypes.LATER}
                                  onClick={() => {
                                    this.handlePublishLater(facebookPostIndex);
                                  }}
                                >
                                  {facebookPost.publishType ===
                                    facebookPublishTypes.LATER &&
                                  facebookPost.scheduledOn
                                    ? this.getScheduleDate(
                                        facebookPost.scheduledOn
                                      )
                                    : "Later"}
                                </StyledMenuItem>
                                {post && (
                                  <StyledMenuItem
                                    value={facebookPublishTypes.WITH_POST}
                                  >
                                    Same time as the blog post
                                  </StyledMenuItem>
                                )}

                                {(showAdditionalPublishType ||
                                  facebookPost.publishType ===
                                    facebookPublishTypes.TEN_MINUTES_LATER) && (
                                  <StyledMenuItem
                                    value={
                                      facebookPublishTypes.TEN_MINUTES_LATER
                                    }
                                  >
                                    10 minutes later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  facebookPost.publishType ===
                                    facebookPublishTypes.THIRTY_MINUTES_LATER) && (
                                  <StyledMenuItem
                                    value={
                                      facebookPublishTypes.THIRTY_MINUTES_LATER
                                    }
                                  >
                                    30 minutes later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  facebookPost.publishType ===
                                    facebookPublishTypes.HOUR_LATER) && (
                                  <StyledMenuItem
                                    value={facebookPublishTypes.HOUR_LATER}
                                  >
                                    1 hour later
                                  </StyledMenuItem>
                                )}
                                {(showAdditionalPublishType ||
                                  facebookPost.publishType ===
                                    facebookPublishTypes.DAY_LATER) && (
                                  <StyledMenuItem
                                    value={facebookPublishTypes.DAY_LATER}
                                  >
                                    A day later
                                  </StyledMenuItem>
                                )}
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {facebookPost.status !==
                          facebookPostStatus.COMPLETED && (
                          <Grid
                            container
                            style={{
                              marginBottom: 20,
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <StyledButton
                              variant="destructivesecondary"
                              onClick={() =>
                                this.toggleDeleteFacebookPostConfirmDialog(
                                  facebookPostIndex
                                )
                              }
                              color="secondary"
                            >
                              Delete draft
                            </StyledButton>

                            <Tooltip title="Copy everywhere">
                              <IconButton
                                onClick={() =>
                                  this.toggleShowCopyConfirmation(
                                    facebookPostIndex
                                  )
                                }
                                style={{ marginRight: `${space.SMALL}` }}
                                size="large"
                              >
                                <ContentCopyOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </div>
                    </Fragment>
                  );
                }
              )}

              <Divider />
              {!facebookPostId && (
                <Grid
                  id="add_another_account"
                  container
                  justifyContent="space-between"
                  className={classes.anotherAccount}
                >
                  <StyledButton
                    variant="secondary"
                    onClick={this.handlePostToAnotherAccount}
                    startIcon={<AddCircleIcon />}
                  >
                    Post to {facebookPosts.length > 0 && "another"} account
                  </StyledButton>
                </Grid>
              )}
            </div>
          )}
        </Paper>

        <StyledDialog
          open={showDeleteConfirmDialog}
          title="Are you sure?"
          body="Once deleted, you can't recover your post"
          successButtonName="Yes, I'm sure"
          successCallback={this.deleteFacebookPost}
          cancelCallback={this.handleDialogClose}
        />

        <Popover
          id="facebookPost_image_picker"
          open={openImagePicker}
          anchorEl={<div />}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          onClose={this.handleImagePickerClose}
        >
          <ImagePicker
            unsplashRef={(ref: any) => {
              this.imagePickerRef = ref;
            }}
            onImageSelect={this.onImageSelected}
            onImageUpload={this.onSelectFile}
            postPictures={[]}
            showUnsplashPicker={true}
          />
        </Popover>

        <CustomFlatPicker
          publicationId={publication._id}
          open={showDateTimePicker}
          value={facebookPosts[facebookPostIndex]?.scheduledOn}
          disablePast={true}
          onClose={(date) => {
            this.setState(
              {
                showDateTimePicker: false
              },

              this.onScheduleDateChange(date)
            );
          }}
          type={datePickerDefault.SOCIAL}
          scheduledDates={scheduledPostDates.map((date: any) => new Date(date))}
        />

        <StyledDialog
          open={showCopyConfirmation}
          title="Do you want to copy this to other accounts?"
          body="We'll overwrite the body, time, and other details of other accounts."
          closeCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          cancelCallback={() => this.toggleShowCopyConfirmation(DEFAULT_INDEX)}
          successCallback={() => this.copyEverywhere()}
          successButtonName="OK"
          maxWidth="sm"
          fullWidth
        />
        {showInstructionDialog && (
          <AIInstructionsDialog
            open={showInstructionDialog}
            value={instructions}
            onSaveInstructions={this.onSaveInstructions}
            onClose={() => this.showInstruction(false)}
            publicationId={publication._id}
            postId={postId}
          />
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(FacebookShare);
