// @ts-nocheck
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import { designColors } from "../../Themes/Colors";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Typography } from "@mui/material";
import { labelSize, space } from "../../Config/theme";

type StyledInputProps = Omit<TextFieldProps, "size"> & {
  size: "small" | "medium" | "large";
};

const StyledInput = forwardRef(function (
  { size, height, label, inputRef, ...other }: StyledInputProps,
  ref
) {
  let paddingSizes = {
    large: `12px 16px 12px 16px`,
    medium: `10px 16px 10px 16px`,
    small: `8px 12px 8px 12px`
  };

  const typography = {
    large: "bodyl",
    medium: "bodym",
    small: "bodys"
  };

  return (
    <>
      {label && (
        <Typography
          paragraph
          style={{ marginBottom: space.XS }}
          variant={labelSize[size]}
        >
          {label}
        </Typography>
      )}

      <TextField
        variant="outlined"
        ref={ref}
        inputRef={inputRef ? inputRef : null}
        sx={{
          "& .MuiFocused": {
            border: "1px solid " + designColors.primary[100]
          },
          "& .Mui-disabled": {
            backgroundColor: designColors.grayScale[5],
            color: designColors.grayScale[60]
          },
          "& .MuiInputBase-multiline": {
            padding: "0px"
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "6px",
            "& input": {
              padding: `${paddingSizes[size]} !important`,

              typography: typography[size],
              color: designColors.grayScale[100],
              height: height || "auto",
              "&::placeholder": {
                color: designColors.grayScale[80]
              }
            },
            "& textarea": {
              padding: paddingSizes[size],

              typography: typography[size],
              color: designColors.grayScale[100],
              minHeight: "20px",
              height: height || "auto",
              "&::placeholder": {
                color: designColors.grayScale[80]
              },
              resize: "vertical"
            },
            "& fieldset": {
              borderColor: designColors.grayScale[40],
              borderWidth: "1px"
            },
            "&:hover fieldset": {
              borderColor: designColors.primary[100]
            },
            "&.Mui-focused fieldset": {
              borderColor: designColors.primary[100],
              borderWidth: "1px"
            },
            "&.MuiInputBase-adornedStart": {
              "& input": {
                paddingLeft: "0px"
              }
            },
            "&.MuiInputBase-adornedEnd": {
              "& input": {
                paddingRight: "0px"
              }
            }
          }
        }}
        {...other}
      ></TextField>
    </>
  );
});

StyledInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"])
};

StyledInput.defaultProps = {
  size: "medium"
};

export default StyledInput;
