// @ts-nocheck
import { Container, Divider, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component, Suspense } from "react";
import { notify } from "../Components/CustomNotifications";
import { withRouter } from "react-router-dom";
import Loading from "../Components/Loading";
import PublicationSettings from "../Components/PublicationSettings";
import API from "../Services/Api";
import styles from "./styles/SettingsStyles";
import "firebase/auth";
import HomePageCustomization from "../Components/HomePageCustomization";
import SocialLinks from "../Components/SocialLinks";
import qs from "query-string";
import {
  customFieldsTypes,
  pageAccess,
  apiFetchStatus,
  modulesAccess
} from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";
import ContentWorkflow from "../Components/ContentWorkflow";
import ExternalSites from "../Components/ExternalSites";
import { Helmet } from "react-helmet";
import UpgradePlanDialog from "../design/components/UpgradePlanDialog";
import { isAccessDisabled } from "../Utils/UserUtils";
import CustomFields from "../Components/CustomFields";
import KeywordForPages from "../Components/KeywordForPages";
import { isModuleDisabled } from "../Utils/AccessUtils";

const api = API.create();

const Page404 = React.lazy(() => import("./Page404"));
class Settings extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      publication: null,
      referral: false,
      price: {
        montly: 1,
        annual: 1
      },
      enablePayment: false,
      emailDetails: null,
      publicationId: props.match.params.id ? props.match.params.id : "",
      error: false,
      errorMessage: "",
      clientId: "",
      isPaidPostAvailable: false,
      sessionId: "",
      clientPublicationsDomain: [],
      showUpgradeDialog: false,
      haveAccessToEdit: false,
      customFields: [],
      ideaTemplates: [],
      fetchedCustomFields: apiFetchStatus.IDLE,
      savedCustomFields: apiFetchStatus.IDLE,
      sendBlogNewsletter: false,
      domain: ""
    };
  }

  componentDidMount() {
    let qsParse = qs.parse(this.props.location.search);
    if (qsParse.message && qsParse.type) {
      notify.show(qsParse.message, qsParse.type);
    }
    this.load();
    this.getCustomPostFields();
    this.getIdeaTemplates();
    setTimeout(() => {
      let hashId = window.location.hash.replace("#", "");
      if (hashId) {
        document.getElementById(hashId)?.scrollIntoView();
      }
    }, 1000);
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      window.location.reload();
    }
  }

  getCustomPostFields = () => {
    let { publicationId } = this.state;
    this.setState({
      savedCustomFields: apiFetchStatus.FETCH
    });
    api.getCustomFields(
      publicationId,
      customFieldsTypes.BLOG_CUSTOM_FIELDS,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            customFields: res.data?.customFields ? res.data.customFields : [],
            fetchedCustomFields: apiFetchStatus.SUCCESS
          });
        } else {
          this.setState({
            fetchedCustomFields: apiFetchStatus.FAIL
          });
        }
      }
    );
  };

  getIdeaTemplates = () => {
    let { publicationId } = this.state;
    api.getIdeaTemplates(publicationId, (res: any) => {
      if (res.status === 200) {
        let { ideaTemplates } = res.data;
        this.setState({
          ideaTemplates
        });
      }
    });
  };

  deleteIdeaTemplate = (ideaTemplateId: any) => {
    api.deleteIdeaTemplate(ideaTemplateId, (res: any) => {
      if (res.status === 200) {
        this.getIdeaTemplates();
      }
    });
  };

  setDefaultIdeaTemplate = (ideaTemplateId: any, isDefault: any) => {
    let { publicationId } = this.state;
    api.setDefaultIdeaTemplate(
      publicationId,
      ideaTemplateId,
      isDefault,
      (res: any) => {
        if (res.status === 200) {
          this.getIdeaTemplates();
        }
      }
    );
  };

  saveCustomFields = (customFields: any) => {
    let { publicationId } = this.state;
    this.setState({
      savedCustomFields: apiFetchStatus.FETCH
    });
    api.saveCustomFields(
      publicationId,
      customFields,
      customFieldsTypes.BLOG_CUSTOM_FIELDS,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            customFields: res.data,
            savedCustomFields: apiFetchStatus.SUCCESS
          });
        } else {
          this.setState({
            savedCustomFields: apiFetchStatus.FAIL
          });
        }
      }
    );
  };

  load = () => {
    let { publicationId } = this.state;
    api.getPublicationById(publicationId, (res: any) => {
      if (res.status === 200) {
        let publication = res.data.publication;
        this.setState({
          loading: false,
          clientId: res.data.clientId,
          referral: res.data.referral,
          price: res.data.price,
          enablePayment: res.data.enablePayment,
          emailDetails: publication.emailDetails,
          publication: publication,
          isPaidPostAvailable: res.data.isPaidPostAvailable,
          sendBlogNewsletter: res.data.sendBlogNewsletter,
          domain: res.data.domain,
          clientPublicationsDomain: res.data.clientPublicationsDomain,
          haveAccessToEdit: !isAccessDisabled(
            publicationId,
            this.context,
            pageAccess.EDIT_SETTINGS
          )
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  toggleUpgradeDialog = () => {
    let { showUpgradeDialog } = this.state;
    this.setState({
      showUpgradeDialog: !showUpgradeDialog
    });
  };

  refresh = () => {
    this.load();
  };

  updateDetails = (publication: any) => {
    let { hideSite, externalSite } = publication;

    let newPublication = { ...this.state.publication };
    newPublication.hideSite = hideSite;
    newPublication.externalSite = externalSite;

    this.setState({
      publication: newPublication
    });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,

      error,

      errorMessage,

      publication,

      clientPublicationsDomain,

      showUpgradeDialog,

      haveAccessToEdit,

      customFields,

      fetchedCustomFields,

      savedCustomFields,

      ideaTemplates,
      sendBlogNewsletter,
      domain
    } = this.state;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            Blog Settings {publication && "- " + publication.name} | Letterdrop
          </title>
        </Helmet>

        <Container>
          <Typography variant="h600" paragraph style={{ paddingTop: 24 }}>
            Blog Settings
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" justify="center">
              <Grid
                item
                xs={12}
                style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
              >
                <ExternalSites
                  publication={publication}
                  updateDetails={this.updateDetails}
                />

                <Divider className={classes.divider} />
              </Grid>

              {!isModuleDisabled(
                this.context,
                publication._id,
                modulesAccess.SEO
              ) && (
                <Grid
                  item
                  xs={12}
                  style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
                >
                  <ContentWorkflow
                    publication={publication}
                    ideaTemplates={ideaTemplates}
                    fetchedCustomFields={fetchedCustomFields}
                    getIdeaTemplates={this.getIdeaTemplates}
                    deleteIdeaTemplate={this.deleteIdeaTemplate}
                    setDefaultIdeaTemplate={this.setDefaultIdeaTemplate}
                  />

                  <Divider className={classes.divider} />
                </Grid>
              )}

              {!isModuleDisabled(
                this.context,
                publication._id,
                modulesAccess.SEO
              ) && (
                <Grid item xs={12} id="custom-fields">
                  <div className={classes.heading}>
                    <Typography variant="h400" paragraph>
                      Custom Fields for Blog Posts
                    </Typography>

                    <Typography
                      variant="bodym"
                      className={classes.cardSubtitle}
                    >
                      Define your own fields for each blog post. These will be
                      sent to Webflow, webhooks, and API for your custom blog
                      frontend.
                    </Typography>
                  </div>

                  <CustomFields
                    publication={publication}
                    customFields={customFields}
                    fetchedCustomFields={fetchedCustomFields}
                    savedCustomFields={savedCustomFields}
                    saveCustomFields={this.saveCustomFields}
                    showDestinationSelection={true}
                    showRichTextType={true}
                    isBlogCustomFields={true}
                    customFieldsType={customFieldsTypes.BLOG_CUSTOM_FIELDS}
                  />

                  <Divider className={classes.divider} />
                </Grid>
              )}

              {sendBlogNewsletter && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
                  >
                    <HomePageCustomization publication={publication} />

                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
                  >
                    <SocialLinks publication={publication} />

                    <Divider className={classes.divider} />
                  </Grid>
                </>
              )}

              {!isModuleDisabled(
                this.context,
                publication._id,
                modulesAccess.SEO
              ) && (
                <Grid
                  item
                  xs={12}
                  style={{ pointerEvents: haveAccessToEdit ? true : "none" }}
                >
                  <KeywordForPages
                    publicationId={publication._id}
                    domain={domain}
                  />
                  <Divider className={classes.divider} />
                </Grid>
              )}
            </Grid>
          </div>
          {showUpgradeDialog && (
            <UpgradePlanDialog
              open={showUpgradeDialog}
              cancelCallback={() => this.setState({ showUpgradeDialog: false })}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Settings));
