// @ts-nocheck
import FileCopy from "@mui/icons-material/FileCopy";
import { Grid, IconButton, InputBase, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";

import CopyToClipboard from "react-copy-to-clipboard";

import isEmail from "validator/lib/isEmail";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { teamRoles } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import style from "./styles/InviteFreelancerDialogStyle";

const api = API.create();
let FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

class InviteFreelancerDialog extends Component {
  constructor(props: any) {
    super(props);
    let inviteLink = FRONTEND_URL + "/freelancerinvite/" + props.publicationId;
    if (props.topicId) {
      inviteLink += "?topicId=" + props.topicId;
    }
    this.state = {
      email: "",
      emailError: "",
      sendingInvite: false,
      publicationId: props.publicationId,
      topicId: props.topicId,
      inviteLink: inviteLink
    };
  }

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value, emailError: "" });
  };

  handleClickInvite = () => {
    let { email, publicationId, topicId } = this.state;
    if (!isEmail(email)) {
      this.setState({ emailError: "Enter a valid email address" });
      return;
    }

    for (let team of this.props.teammates) {
      if (team.email === email.trim()) {
        this.setState({ emailError: "This person is already on your team" });
        return;
      }
    }
    this.setState({ sendingInvite: true, emailError: "" }, () => {
      api.sendTeamInvitation(
        publicationId,
        email,
        teamRoles.FREELANCER,
        topicId,
        false,
        (res: any) => {
          if (res.status === 200) {
            this.props.setFreelancerInvited();
            this.setState(
              {
                showInviteFreelancer: false,
                email: "",
                sendingInvite: false
              },

              this.props.handleSucess(res.data)
            );
            notify.show(
              "We sent them an email invite. Ask them to check their inbox.",
              "success"
            );
          } else {
            notify.show(
              res.status === 400
                ? res.data
                : "Oops, we were unable to send the invite. Please try again.",
              "error"
            );
          }
        }
      );
    });
  };

  handleCopyInviteLink = () => {
    this.props.setFreelancerInvited();
  };

  render() {
    let { email, emailError, sendingInvite, inviteLink } = this.state;

    let { showInvite, handleCancel, classes } = this.props;

    return (
      <StyledDialog
        open={showInvite}
        title="Invite freelance writer"
        body={
          <Grid container direction="column">
            <Typography variant="body1">
              Invite a freelancer to write a single post. They'll receive an
              email to join your workspace and collaborate with you on a draft
              post.
            </Typography>

            <StyledInput
              autoFocus
              error={emailError ? true : false}
              helperText={emailError ? emailError : ""}
              style={{ marginTop: 20 }}
              size="medium"
              value={email}
              name="email"
              onChange={this.handleChange}
              placeholder="Email"
              fullwidth
            />

            <Grid
              item
              container
              direction="row"
              className={classes.copyContainer}
            >
              <Grid item xs={12}>
                <Typography variant="body1">
                  Alternatively, send the freelancer this link to join this
                  project
                </Typography>
              </Grid>

              <Grid item xs={11} style={{ marginTop: 10 }}>
                <InputBase
                  className={classes.input}
                  value={inviteLink}
                  contentEditable={false}
                  onFocus={(event) => event.target.select()}
                  fullWidth
                />
              </Grid>

              <Grid item xs={1} style={{ marginTop: 10, paddingLeft: 10 }}>
                <CopyToClipboard text={inviteLink}>
                  <IconButton onClick={this.handleCopyInviteLink} size="large">
                    <FileCopy />
                  </IconButton>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </Grid>
        }
        successButtonName={sendingInvite ? "Sending invite..." : "Invite"}
        successCallback={this.handleClickInvite}
        cancelCallback={handleCancel}
      />
    );
  }
}

export default withStyles(style)(InviteFreelancerDialog);
