import {
  capitalize,
  Grid,
  Theme,
  ThemeProvider,
  Typography
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { teamRoles } from "../Utils/Types";
import { generateDomainName } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";
import Loading from "./Loading";
import styles from "./styles/OnboardingBusinessPublicationStyle";

const psl = require("psl");
const api = API.create();
const REGX_TO_REPLACE_URL_PROTOCOL = /(^\w+:|^)\/\//;

type ClassProps = {
  publicationId: string;
  website: string;
  next: (publicationId: string) => void;
  isDialog: boolean;
  value: number;
  theme: Theme;
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

type ClassState = {
  publicationId: string;
  website: string;
  inviteEmails: string;
  emailError: string;
  loading: boolean;
};

class OnboardingBusinessPublication extends Component<ClassProps, ClassState> {
  constructor(props: ClassProps) {
    super(props);
    this.state = {
      publicationId: this.props.publicationId,
      website: this.props.website,
      inviteEmails: "",
      emailError: "",
      loading: false
    };
  }

  onWebsiteChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({ website: event.target.value });
  };

  saveBusiness = () => {
    let { publicationId, website } = this.state;

    if (!isURL(website?.trim())) {
      notify.show("Enter a valid URL", "error");
      return;
    }
    //To remove the protocol(ex. https://) from the given website URL.
    website = website.replace(REGX_TO_REPLACE_URL_PROTOCOL, "");
    website =
      website.indexOf("/") > 0
        ? website.substring(0, website.indexOf("/"))
        : website;

    //To get the main domain of given website ex: b.example.uk.com - example
    let domain =
      website.lastIndexOf(".") > 0 ? psl.parse(website).sld : website;
    if (!domain) {
      notify.show("Enter a valid URL", "error");
      return;
    }

    let publicationName = capitalize(domain);
    domain = generateDomainName(domain);

    this.setState({ loading: true });
    api.savePublication(
      publicationId,
      publicationName,
      domain,
      "",
      false,
      website,
      true,
      (res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          if (res?.data?._id) {
            this.setState({ publicationId: res?.data?._id }, () => {
              this.sendInvite();
            });
          }

          this.props.next(this.state.publicationId);
        } else {
          notify.show(
            "Something went wrong. Please contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  onInviteEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let { value } = event.target;
    this.setState({
      inviteEmails: value,
      emailError: ""
    });
    value
      ?.split(",")
      ?.filter((email) => email?.trim())
      ?.map((email) => {
        email = email.trim();
        if (!isEmail(email)) {
          this.setState({
            emailError: `${email} is not a valid email`
          });
        }
        return email;
      });
  };

  sendInvite = () => {
    let { inviteEmails } = this.state;
    if (inviteEmails) {
      api.sendTeamInvitation(
        this.state.publicationId,
        inviteEmails,
        teamRoles.TEAMMATE,
        null,
        false,
        (res) => {
          if (res.status === 200) {
          }
        }
      );
    }
  };

  render() {
    const { classes, isDialog, value, theme } = this.props;
    let { website, inviteEmails, emailError, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={isDialog ? classes.dialogContainer : classes.gridContainer}
      >
        {!isDialog && (
          <>
            <Grid item>
              <Typography variant="body1" className={classes.heading}>
                Tell us more about your business
              </Typography>
            </Grid>

            <Grid item className={classes.marginTop20}>
              <ThemeProvider theme={theme}>
                <LinearProgress
                  variant="determinate"
                  value={value}
                  className="primary"
                />
              </ThemeProvider>
            </Grid>
          </>
        )}

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.dataContainer}
        >
          <Grid item xs={9}>
            <Typography variant="bodym" className={classes.label}>
              Your company's website URL *
            </Typography>
          </Grid>

          <Grid item xs={9} style={{ marginTop: space.SMALL }}>
            <StyledInput
              className={classes.input}
              size="medium"
              placeholder="https://letterdrop.com"
              name="name"
              value={website}
              onChange={this.onWebsiteChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={9} style={{ marginTop: space.SMALL }}>
            <Typography variant="bodym" className={classes.label}>
              Invite team members
            </Typography>
          </Grid>

          <Grid
            item
            xs={9}
            style={{ marginTop: space.SMALL, minHeight: "100" }}
          >
            <StyledInput
              size="medium"
              name="inviteEmails"
              value={inviteEmails}
              placeholder="richard@piedpiper.com, dinesh@piedpiper.com"
              onChange={this.onInviteEmailChange}
              fullWidth
              error={Boolean(emailError)}
              helperText={emailError || " "}
            />
          </Grid>
        </Grid>
        {!isDialog && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            className={classes.dataContainer}
          >
            <StyledButton
              className={classes.nextButton}
              onClick={this.saveBusiness}
              disabled={Boolean(inviteEmails && emailError)}
            >
              Next
            </StyledButton>
          </Grid>
        )}

        {isDialog && (
          <Grid item style={{ paddingTop: 20, marginLeft: "auto" }}>
            <StyledButton
              onClick={this.saveBusiness}
              disabled={Boolean(inviteEmails && emailError)}
            >
              Save
            </StyledButton>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(OnboardingBusinessPublication));
