import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import Papa from "papaparse";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AvatarSize, space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledInput from "../design/components/StyledInput";
import Api from "../Services/Api";
import { linkedinSearchSeniorities, Publication } from "../Utils/Types";
import { notify } from "./CustomNotifications";
import Loading from "./Loading";
import styles from "./styles/AccountMonitoringStyle";
import BetaText from "./BetaText";
import _ from "lodash";
import Uploader from "./Uploader";

const api = Api.create();

const INDIVIDUAL_MONITORING_CSV_TEMPLATE = `https://docs.google.com/spreadsheets/d/1x1v6di_y0-csPdUFUwdQpo0JpkUpBRklV8GwyB2jNXc/edit?gid=0#gid=0`;
const ACCOUNT_MONITORING_CSV_TEMPLATE = `https://docs.google.com/spreadsheets/d/1yzIOGxdVVkOm5doCnn3vdgsX65IgY7VoJxC0wpNT4Eo/edit?gid=0#gid=0`;

const monitoringTypes = {
  ACCOUNT: "account",
  INDIVIDUAL: "individual"
} as const;

type AccountMonitoringProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
  publication: Publication | null;
} & RouteComponentProps<{ id: string }>;

type AccountMonitoringState = {
  loading: boolean;
  saveInProgress: boolean;
  companies: Companies;
  roles: string[];
  accounts: LinkedinAccount[];
  selectedAccounts: LinkedinAccount[];
  seniorities: Seniority[];
  geographies: Geography[];
  allLocations: Geography[];
  geographySearch: string;
  selectedType: (typeof monitoringTypes)[keyof typeof monitoringTypes];
};

const ROLES_LIMIT = 40;
export type Geography = {
  id: string;
  name: string;
};

export type Seniority = {
  name: string;
  value: string;
};

export type Companies = {
  [key: string]: string[];
};

export type LinkedinAccount = {
  connected: boolean;
  name: string;
  userId: string;
  vanityName: string;
  profilePicture: string;
  email: string;
  accountEmails: string;
  chromeExtension?: {
    installed: boolean;
    isScrapingEnabled: boolean;
  };
};

class AccountMonitoring extends React.Component<
  AccountMonitoringProps,
  AccountMonitoringState
> {
  searchLocationsDebounce: any;
  fileInputRef: any;

  constructor(props: AccountMonitoringProps) {
    super(props);
    this.state = {
      loading: true,
      saveInProgress: false,
      accounts: [],
      selectedAccounts: [],
      companies: {},
      roles: [],
      seniorities: [],
      geographies: [],
      allLocations: [],
      geographySearch: "",
      selectedType: monitoringTypes.ACCOUNT
    };

    this.searchLocationsDebounce = null;
  }

  componentDidMount(): void {
    this.getConnectedLinkedinAccounts();
    this.searchLocationsDebounce = _.debounce(this.searchLocations, 1000);
  }

  getConnectedLinkedinAccounts = () => {
    let { publication } = this.props;

    api.getConnectedLinkedinAccounts(
      publication?._id || "",
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            accounts:
              res.data?.filter(
                (account: LinkedinAccount) =>
                  account.connected && account.vanityName
              ) || []
          });
        } else {
          notify.show(
            res.status === 400 ? res.data : "Oops, something went wrong.",
            "error"
          );
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  onFileChange = async (file: any) => {
    let { accounts, selectedType } = this.state;

    const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 2MB Limit for thumbnail image
    if (file.size > FILE_SIZE_LIMIT) {
      notify.show("Upload a CSV file that's smaller than 10 MB", "error");
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        if (!results.data?.length) {
          notify.show("CSV file is empty", "error");
          return;
        }

        let data = results.data;

        if (
          (selectedType === monitoringTypes.ACCOUNT &&
            !data[0].hasOwnProperty("company")) ||
          (selectedType === monitoringTypes.INDIVIDUAL &&
            !data[0].hasOwnProperty("person")) ||
          !data[0].hasOwnProperty("owner email")
        ) {
          let message =
            selectedType === monitoringTypes.INDIVIDUAL
              ? `Missing required column: ${
                  !data[0].hasOwnProperty("person") ? "person" : "owner email"
                }`
              : `Missing required column: ${
                  !data[0].hasOwnProperty("company") ? "company" : "owner email"
                }`;

          notify.show(message, "error");
          return;
        }

        // No limit for individual monitoring
        if (selectedType === monitoringTypes.ACCOUNT && data.length > 1000) {
          data.length = 1000;
        }

        let companies: Companies = {};

        for (let { company, person, "owner email": email } of data) {
          if (
            selectedType === monitoringTypes.ACCOUNT &&
            (!company || company?.includes("linkedin.com/in/"))
          ) {
            continue;
          }

          if (
            selectedType === monitoringTypes.INDIVIDUAL &&
            (!person || !person?.includes("linkedin.com/in/"))
          ) {
            continue;
          }

          if (!email?.trim()) {
            notify.show(
              `Missing email for account ${
                selectedType === monitoringTypes.INDIVIDUAL ? person : company
              }`,
              "error"
            );
            return;
          }

          companies[email] = companies[email] || [];
          companies[email].push(
            selectedType === monitoringTypes.INDIVIDUAL ? person : company
          );
        }

        if (!this.getCompaniesCount(companies)) {
          notify.show("No accounts found in the CSV file", "error");
          return;
        }

        let connectedAccountsEmails =
          accounts
            ?.map((account) => {
              return account.accountEmails || [];
            })
            ?.flat() || [];

        let invalidEmails = [];

        for (const email of Object.keys(companies)) {
          if (!connectedAccountsEmails.includes(email)) {
            invalidEmails.push(email);
          }
        }

        if (invalidEmails.length) {
          notify.show(
            `The following emails are not connected to any LinkedIn account: ${invalidEmails.join(
              ", "
            )}`,
            "error"
          );
          return;
        }

        this.setState({
          companies
        });
      },
      error: (error) => {
        console.log(error);
      }
    });
  };

  saveAccountsForMonitoring = () => {
    let {
      selectedAccounts,
      companies,
      roles,
      saveInProgress,
      seniorities,
      geographies,
      selectedType
    } = this.state;
    let { publication } = this.props;

    if (saveInProgress) {
      return;
    }

    if (selectedType === monitoringTypes.ACCOUNT && !selectedAccounts.length) {
      notify.show("Select at least one LinkedIn account", "error");
      return;
    }

    if (!this.getCompaniesCount(companies)) {
      notify.show(
        `Upload ${
          selectedType === monitoringTypes.ACCOUNT
            ? "company accounts"
            : "individual accounts"
        }`,
        "error"
      );
      return;
    }

    this.setState({ saveInProgress: true }, () => {
      api.saveAccountsForMonitoring(
        publication?._id || "",
        selectedAccounts?.map((account) => account.vanityName) || [],
        companies,
        roles,
        seniorities,
        geographies,
        selectedType,
        (res: AxiosResponse) => {
          if (res.status === 200) {
            this.setState({
              saveInProgress: false,
              companies: {},
              geographies: [],
              seniorities: [],
              selectedAccounts: [],
              roles: []
            });

            notify.show("Accounts saved successfully", "success");
          } else {
            notify.show(
              res.status === 400 ? res.data : "Oops, something went wrong.",
              "error"
            );
            this.setState({
              saveInProgress: false
            });
          }
        }
      );
    });
  };

  searchLocations = (location: string) => {
    if (!location) {
      return;
    }

    let { publication } = this.props;
    let { accounts, selectedAccounts } = this.state;

    let account =
      selectedAccounts?.[0]?.vanityName || accounts?.[0]?.vanityName;
    if (!account) {
      return;
    }

    api.searchLinkedInLocation(
      publication?._id || "",
      account,
      location,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          this.setState({
            allLocations: res.data.locations || []
          });
        } else {
          this.setState({
            allLocations: []
          });
        }
      }
    );
  };

  getCompaniesCount = (companies: Companies) => {
    let companiesCount = 0;

    Object.keys(companies || {}).forEach((key) => {
      companiesCount += companies[key]?.length || 0;
    });

    return companiesCount;
  };

  getCompaniesAndRolesLabel = () => {
    let { companies, selectedType } = this.state;

    let companiesCount = this.getCompaniesCount(companies) || 0;
    if (!companiesCount) {
      return "";
    }

    let typeText =
      selectedType === monitoringTypes.INDIVIDUAL
        ? "individual account"
        : "company";

    if (companiesCount !== 1) {
      typeText = typeText =
        selectedType === monitoringTypes.INDIVIDUAL
          ? "individual accounts"
          : "companies";
    }

    return (
      <Typography
        variant="bodym"
        paragraph
        style={{
          margin: "0 10px",
          marginTop: space.XXS,
          display: "flex",
          alignItems: "center",
          gap: space.XXS
        }}
      >
        <b>{companiesCount}</b> {typeText}
      </Typography>
    );
  };

  getAccountMonitoringTitle = () => {
    let { selectedType } = this.state;

    if (selectedType === monitoringTypes.INDIVIDUAL) {
      return (
        <>
          <Typography
            variant="h400"
            paragraph
            style={{ marginBottom: space.XS }}
          >
            Upload Individual Profiles
          </Typography>
          <Typography variant="bodym" paragraph>
            Upload your target individuals. Here is a{" "}
            <a
              href={INDIVIDUAL_MONITORING_CSV_TEMPLATE}
              target="_blank"
              rel="no-referrer"
            >
              CSV template
            </a>{" "}
            you can copy. Limit of 5,000 individuals.
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="h400" paragraph style={{ marginBottom: space.XS }}>
          Upload Company Accounts
        </Typography>
        <Typography variant="bodym" paragraph>
          Upload your target company accounts. Here is a{" "}
          <a
            href={ACCOUNT_MONITORING_CSV_TEMPLATE}
            target="_blank"
            rel="no-referrer"
          >
            CSV template
          </a>{" "}
          you can copy. Limit of 1,000 companies.
        </Typography>
      </>
    );
  };

  changeMonitorType = (
    value: (typeof monitoringTypes)[keyof typeof monitoringTypes]
  ) => {
    let { selectedType } = this.state;

    if (selectedType === value) {
      return;
    }

    this.setState({ selectedType: value, companies: {} });
  };

  render() {
    let { classes } = this.props;
    let {
      loading,
      selectedAccounts,
      accounts,
      saveInProgress,
      selectedType,
      allLocations,
      seniorities,
      roles,
      geographies,
      geographySearch
    } = this.state;

    if (loading) {
      return <Loading marginTop={space.L} />;
    }

    return (
      <Box className={classes.container}>
        <Typography variant="h400" paragraph>
          Monitor and Engage with Target Accounts
          <BetaText />
        </Typography>
        <Typography variant="bodym" paragraph>
          Upload target accounts and sellers who own them. When they post, engage, or talk about a topic, the account owner will be notified so they can engage and nurture.
        </Typography>

        <ToggleButtonGroup size="large" value={selectedType}>
          <ToggleButton
            onClick={() => this.changeMonitorType(monitoringTypes.ACCOUNT)}
            size="medium"
            value={monitoringTypes.ACCOUNT}
          >
            By Account
          </ToggleButton>
          <ToggleButton
            onClick={() => this.changeMonitorType(monitoringTypes.INDIVIDUAL)}
            size="medium"
            value={monitoringTypes.INDIVIDUAL}
          >
            By Individual
          </ToggleButton>
        </ToggleButtonGroup>
        <Divider style={{ marginTop: space.SMALL }} />

        <Grid
          container
          item
          xs={11}
          md={11}
          lg={7}
          style={{
            marginTop: space.MEDIUM,
            marginLeft: space.MEDIUM,
            display: "flex",
            flexDirection: "column"
            // width: "50%"
          }}
        >
          {this.getAccountMonitoringTitle()}
          {selectedType === monitoringTypes.ACCOUNT && (
            <>
              <div style={{ marginTop: space.L }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.XS }}
                >
                  Geography
                </Typography>
                <Autocomplete
                  value={geographies?.length ? geographies : []}
                  multiple
                  inputValue={geographySearch || ""}
                  onInputChange={(
                    event: any,
                    newValue: string,
                    reason: string
                  ) => {
                    if (reason === "reset") {
                      return;
                    }

                    this.setState({ geographySearch: newValue }, () => {
                      this.searchLocationsDebounce(event?.target?.value);
                    });
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <StyledInput
                      placeholder="United States, Canada"
                      variant="outlined"
                      {...params}
                      size="medium"
                    />
                  )}
                  options={allLocations || []}
                  onChange={(_: unknown, geographies: Geography[]) => {
                    this.setState({ geographies });
                  }}
                  getOptionLabel={(option: Geography) => option.name}
                />
              </div>

              <div style={{ marginTop: space.LARGE }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.XS }}
                >
                  Seniority
                </Typography>
                <Autocomplete
                  multiple={true}
                  options={linkedinSearchSeniorities}
                  value={seniorities}
                  onChange={(_, seniorities: Seniority[]) => {
                    this.setState({
                      seniorities
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      placeholder="CXO, Vice President"
                      variant="outlined"
                      size="medium"
                    />
                  )}
                />
              </div>

              <div style={{ marginTop: space.LARGE }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.XS }}
                >
                  Roles
                </Typography>
                <Autocomplete
                  freeSolo
                  multiple
                  options={[] as string[]}
                  value={roles || []}
                  style={{
                    width: "100%"
                  }}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      placeholder="finance, partnerships"
                      size="medium"
                    />
                  )}
                  onChange={(_, roles: string[]) => {
                    if (roles.length > ROLES_LIMIT) {
                      notify.show(
                        `You can only add ${ROLES_LIMIT} roles`,
                        "error"
                      );
                      return;
                    }
                    this.setState({ roles });
                  }}
                />
              </div>
              <div style={{ marginTop: space.LARGE }}>
                <Typography
                  variant="bodym"
                  paragraph
                  style={{ marginBottom: space.XS }}
                >
                  Account
                </Typography>
                <Autocomplete
                  options={accounts}
                  value={selectedAccounts}
                  multiple
                  fullWidth
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(
                    option: LinkedinAccount,
                    value: LinkedinAccount
                  ) => {
                    return option.userId === value.userId;
                  }}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      placeholder="John Smith, Julie Adams"
                      variant="outlined"
                      size="medium"
                    />
                  )}
                  onChange={(event: unknown, newValues: LinkedinAccount[]) => {
                    this.setState({ selectedAccounts: newValues });
                  }}
                  renderOption={(props, option: LinkedinAccount, index) => {
                    return (
                      <Box
                        component="li"
                        key={option.userId}
                        {...props}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Avatar
                          sx={{ ...AvatarSize.sm, display: "flex" }}
                          src={option?.profilePicture}
                        />
                        <Typography
                          variant="bodym"
                          style={{ marginLeft: space.SMALL }}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  }}
                />
              </div>
            </>
          )}

          <div style={{ marginTop: space.LARGE }}>
            <Typography
              variant="bodym"
              paragraph
              style={{ marginBottom: space.XS }}
            >
              CSV File
            </Typography>
            <Uploader csvOnly={true} onImageUpload={this.onFileChange} />
            {this.getCompaniesAndRolesLabel()}
          </div>
          <div
            style={{
              marginTop: space.LARGE,
              width: "100%",
              display: "flex",
              justifyContent: "end"
            }}
          >
            <StyledButton
              variant="primary"
              size="medium"
              onClick={() => {
                this.saveAccountsForMonitoring();
              }}
            >
              {saveInProgress && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginRight: space.XS }}
                />
              )}
              {saveInProgress ? "Saving..." : "Save"}
            </StyledButton>
          </div>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(withStyles(styles)(AccountMonitoring));
