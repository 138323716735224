import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { withRouter } from "react-router-dom";
import { Seniority } from "../Components/AccountMonitoring";
import { notify } from "../Components/CustomNotifications";
import "../Components/styles/react-dates-override.css";
import { space } from "../Config/theme";
import StyledAutocomplete from "../design/components/StyledAutocomplete";
import StyledButton from "../design/components/StyledButton";
import StyledChipInput from "../design/components/StyledChipInput";
import StyledInput from "../design/components/StyledInput";
import StyledInputLabel from "../design/components/StyledInputLabel";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import { countries } from "../Utils/Countries";
import { LINKEDIN_SENIORITY_OPTIONS, Publication } from "../Utils/Types";
import { AUTOCOMPLETE_ALL_OPTION_TEXT } from "./LinkedInShare";
import styles from "./styles/KnowledgeBaseSettingsStyles";

const api = API.create();

type CompanySizeOption = {
  name: string;
  value: string | number;
};

export const COMAPANY_SIZE_OPTIONS: Array<CompanySizeOption> = [
  { name: "1-10", value: "1-10" },
  { name: "11-50", value: "11-50" },
  { name: "51-200", value: "51-200" },
  { name: "201-500", value: "201-500" },
  { name: "501-1000", value: "501-1000" },
  { name: "1001-5000", value: "1001-5000" },
  { name: "5001-10000", value: "5001-10000" },
  { name: "10001+", value: "10001+" }
];

export type BuyerFiltersType = {
  jobTitle?: string[];
  companySize?: CompanySizeOption[];
  seniorities?: Seniority[];
  location?: typeof countries;
  hqLocation?: typeof countries;
  isAllCompanySizeChecked?: boolean;
};

type BuyerFiltersProps = {
  publicationId: string;
  filters: BuyerFiltersType;
  onChange: (newFilters: BuyerFiltersType) => void;
};

const BuyerFilters: React.FC<BuyerFiltersProps> = ({
  publicationId,
  filters,
  onChange
}) => {
  const [jobTitle, setJobTitle] = useState<string[]>(filters?.jobTitle || []);
  const [companySize, setCompanySize] = useState<CompanySizeOption[]>(
    filters?.companySize || []
  );
  const [seniorities, setSeniorities] = useState<Seniority[]>(
    filters?.seniorities || []
  );
  const [location, setLocation] = useState<typeof countries>(
    filters?.location || []
  );
  const [hqLocation, setHqLocation] = useState<typeof countries>(
    filters?.hqLocation || []
  );
  const [isAllCompanySizeChecked, setIsAllCompanySizeChecked] =
    useState<boolean>(filters?.isAllCompanySizeChecked || false);

  const [showGenerateJobTitleButton, setShowGenerateJobTitleButton] =
    useState(false);
  const [generateJobTitleInputText, setGenerateJobTitleInputText] =
    useState("");
  const [generateJobTitleLoading, setGenerateJobTitleLoading] = useState(false);

  const onSaveClick = () => {
    onChange({
      jobTitle,
      companySize,
      seniorities,
      location,
      hqLocation,
      isAllCompanySizeChecked
    });
  };

  const isCompanySizeSelected = (
    option: CompanySizeOption,
    optionIndex: number
  ) => {
    if (isAllCompanySizeChecked) {
      return true;
    }
    return Boolean(companySize.find((source) => source.value === option.value));
  };

  const onCompanySizeChange = (
    e: React.SyntheticEvent,
    values: Array<CompanySizeOption>
  ) => {
    let allFlagSelected = Boolean(
      values.find((value) => value.name === AUTOCOMPLETE_ALL_OPTION_TEXT)
    );
    if (allFlagSelected) {
      setIsAllCompanySizeChecked(!isAllCompanySizeChecked);
      setCompanySize(isAllCompanySizeChecked ? [] : COMAPANY_SIZE_OPTIONS);
    } else {
      setIsAllCompanySizeChecked(
        values.length === COMAPANY_SIZE_OPTIONS.length
      );
      setCompanySize(values);
    }
  };

  const suggestBuyerTitles = () => {
    setGenerateJobTitleLoading(true);
    api.suggestBuyerTitles(
      publicationId,
      generateJobTitleInputText,
      (res: AxiosResponse) => {
        let suggestedTitles = res.data?.suggestedTitles || [];
        if (!suggestedTitles.length) {
          suggestedTitles = [generateJobTitleInputText];
        }
        setGenerateJobTitleInputText("");
        setGenerateJobTitleLoading(false);
        setJobTitle([...jobTitle, ...suggestedTitles]);
      }
    );
  };

  return (
    <Grid item xs={12} style={{ marginTop: space.LARGE }}>
      <hr color={colors.horizontalRule} />

      <Box style={{ marginTop: space.LARGE }}>
        <Typography variant="h400" paragraph>
          Buyer Filters
        </Typography>

        <Typography variant="bodym" paragraph>
          Define your ideal buyer using firmographic filters. We'll use this
          everywhere when reporting metrics on your ICP buyer and filtering
          contacts to send to CRM.
        </Typography>
      </Box>

      <Grid>
        <StyledInputLabel label="Company Size" />
        <StyledAutocomplete
          value={companySize}
          options={COMAPANY_SIZE_OPTIONS}
          isCheckboxSelected={isCompanySizeSelected}
          onChange={onCompanySizeChange}
          size="medium"
          showAvatar={false}
        />

        <br />

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: space.SMALL
          }}
        >
          <StyledInputLabel label="Job Title" style={{ marginBottom: 0 }} />
          <StyledButton
            size="medium"
            variant="textprimary"
            onClick={() =>
              setShowGenerateJobTitleButton(!showGenerateJobTitleButton)
            }
          >
            Generate Job Titles
            {showGenerateJobTitleButton ? <ExpandLess /> : <ExpandMore />}
          </StyledButton>
        </Box>
        {showGenerateJobTitleButton && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginBottom: space.MEDIUM
            }}
          >
            <Box width={"50%"}>
              <StyledInput
                type="text"
                size="medium"
                fullWidth
                name="baseBuyerTitle"
                label="Base Title"
                placeholder="Account Evangelist"
                value={generateJobTitleInputText}
                onChange={(e) => setGenerateJobTitleInputText(e.target.value)}
              />
            </Box>
            <StyledButton
              variant="primary"
              size="medium"
              disabled={!generateJobTitleInputText}
              onClick={() => suggestBuyerTitles()}
            >
              {generateJobTitleLoading && (
                <CircularProgress
                  size={16}
                  color="inherit"
                  style={{ marginRight: space.XS }}
                />
              )}
              {generateJobTitleLoading ? "Generating..." : "Generate"}
            </StyledButton>
          </Box>
        )}

        <StyledChipInput
          value={jobTitle}
          onChange={(value: string[]) => {
            setJobTitle(value);
          }}
          size="medium"
          fullWidth
        />

        <br />

        <StyledInputLabel label="Seniority" />
        <Autocomplete
          options={LINKEDIN_SENIORITY_OPTIONS}
          getOptionLabel={(seniority) => seniority.name}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={seniorities}
          multiple
          onChange={(e, values) => setSeniorities(values)}
          renderInput={(params) => (
            <StyledInput {...params} size="small" variant="outlined" />
          )}
        />

        <br />

        <StyledInputLabel label="Location" />
        <Autocomplete
          options={countries}
          getOptionLabel={(country) => country.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          value={location}
          multiple
          onChange={(e, values) => setLocation(values)}
          renderInput={(params) => (
            <StyledInput {...params} size="small" variant="outlined" />
          )}
        />

        <br />

        <StyledInputLabel label="HQ Location" />
        <Autocomplete
          options={countries}
          getOptionLabel={(country) => country.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          value={hqLocation}
          multiple
          onChange={(e, values) => setHqLocation(values)}
          renderInput={(params) => (
            <StyledInput {...params} size="small" variant="outlined" />
          )}
        />
      </Grid>

      <Box display={"flex"} justifyContent={"flex-end"} mt={space.MEDIUM}>
        <StyledButton size="small" variant="primary" onClick={onSaveClick}>
          Save
        </StyledButton>
      </Box>
    </Grid>
  );
};

type KnowledgeBaseBuyerFiltersProps = {
  publicationId: string;
  publication: Publication | null;
  classes: Record<keyof ReturnType<typeof styles>, string>;
};

const KnowledgeBaseBuyerFilters: React.FC<KnowledgeBaseBuyerFiltersProps> = ({
  publicationId,
  publication,
  classes
}) => {
  const saveBuyerFilters = (newFilters: BuyerFiltersType) => {
    api.saveBuyerFilters(publicationId, newFilters, (res) => {
      if (res.status === 200) {
        notify.show("Filters saved successfully", "success");
        if (publication) {
          publication.buyerFilters = newFilters;
        }
      } else {
        notify.show("Error saving filters", "error");
      }
    });
  };

  if (!publication) return <></>;

  return (
    <BuyerFilters
      publicationId={publicationId}
      filters={publication.buyerFilters}
      onChange={saveBuyerFilters}
    />
  );
};

export default withRouter(withStyles(styles)(KnowledgeBaseBuyerFilters));
