import logo from "../Images/logo.svg";
import lost from "../Images/lost.svg";
import googleLogo from "../Images/google.svg";
import twitterLogo from "../Images/twitter.svg";
import publicationIllustraion from "../Images/newsletter_illustration.svg";
import getStarted from "../Images/get_started.svg";
import empty from "../Images/empty_bag.png";
import noPost from "../Images/typing_post.png";
import coverPhotoBG from "../Images/cover_photo_bg.svg";
import trophy from "../Images/trophy.png";
import linkedInMentions from "../Images/linkedin_mentions.png";
import gift from "../Images/gift.svg";
import stripe from "../Images/stripe.svg";
import notion from "../Images/notion.svg";
import notionHover from "../Images/notion_hover.svg";
import lightBulb from "../Images/light_bulb.svg";
import posts from "../Images/posts.svg";
import user from "../Images/user.svg";
import square from "../Images/square.svg";
import rectangle from "../Images/rectangle.svg";
import web from "../Images/web.svg";
import errorCircle from "../Images/error_circle.svg";
import spaceDashboard from "../Images/space_dashboard.svg";
import getStartedBusiness from "../Images/get_started_business.jpeg";
import map from "../Images/contentmap.svg";
import scheduleSend from "../Images/schedule_send.svg";
import firstDraft from "../Images/firstDraft.svg";
import publish from "../Images/publish.svg";
import readmore from "../Images/readmore.svg";
import research from "../Images/research.jpg";
import socialMedia from "../Images/socialMedia.svg";
import tableView from "../Images/table_view.svg";
import viewAgenda from "../Images/view_agenda.svg";
import emailOpen from "../Images/email_open.svg";
import contentOutline from "../Images/content_outline.svg";
import retweet from "../Images/retweet.svg";
import blogOrNewsletter from "../Images/blogornewsletter.svg";
import placeHolder from "../Images/placeholder.svg";
import twitterGrayscale from "../Images/twitter_grayscale.svg";
import linkedIn from "../Images/linkedin.svg";
import facebook from "../Images/facebook.svg";
import slack from "../Images/slack.svg";
import reddit from "../Images/reddit.svg";
import table1 from "../Images/design_assets/1.svg";
import table2 from "../Images/design_assets/2.svg";
import table3 from "../Images/design_assets/3.svg";
import close from "../Images/icon24/close.svg";
import highEngagement from "../Images/high.svg";
import mediumEngagement from "../Images/medium.svg";
import lowEngagement from "../Images/low.svg";
import calendarColorPrimary from "../Images/icon16/calendarColorPrimary.svg";
import calendarGray80Size20 from "../Images/icon20/calendarColorGray40.svg";
import edit from "../Images/icon24/edit.svg";
import add from "../Images/icon24/add.svg";
import enterIcon from "../Images/enter_icon.png";
import devtoIcon from "../Images/devto.svg";
import clearBitIcon from "../Images/clearbit.png";
import apolloIcon from "../Images/apollo.png";
import outreachIcon from "../Images/outreach.svg";
import zoomIcon from "../Images/zoom.svg";
import zoomInfoIcon from "../Images/zoominfo.svg";
import hashnodeIcon from "../Images/hashnode.svg";
import circleCheck from "../Images/check_circle.svg";
import arrowUp from "../Images/arrowUpSolid.svg";
import arrowDown from "../Images/arrowDownSolid.svg";
import loginBackground from "../Images/letterdrop_login_background.jpg";
import loginImage1 from "../Images/letterdrop_webflow_app_directory_1.png";
import loginImage2 from "../Images/letterdrop_webflow_app_directory_2.png";
import loginImage3 from "../Images/letterdrop_webflow_app_directory_3.png";
import loginImage4 from "../Images/letterdrop_webflow_app_directory_4.png";
import gmeetIcon from "../Images/gmeet.png";
import trash_24 from "../Images/icon24/trash.svg";

const images = {
  logo,
  lost,
  googleLogo,
  twitterLogo,
  publicationIllustraion,
  getStarted,
  empty,
  noPost,
  coverPhotoBG,
  trophy,
  linkedInMentions,
  gift,
  stripe,
  notion,
  notionHover,
  lightBulb,
  posts,
  user,
  square,
  rectangle,
  web,
  errorCircle,
  spaceDashboard,
  getStartedBusiness,
  map,
  scheduleSend,
  firstDraft,
  publish,
  readmore,
  research,
  socialMedia,
  tableView,
  viewAgenda,
  emailOpen,
  contentOutline,
  retweet,
  blogOrNewsletter,
  placeHolder,
  twitterGrayscale,
  linkedIn,
  facebook,
  slack,
  reddit,
  table1,
  table2,
  table3,
  close,
  highEngagement,
  mediumEngagement,
  lowEngagement,
  calendarColorPrimary,
  calendarGray80Size20,
  edit,
  add,
  enterIcon,
  devtoIcon,
  clearBitIcon,
  apolloIcon,
  outreachIcon,
  zoomIcon,
  zoomInfoIcon,
  hashnodeIcon,
  circleCheck,
  arrowUp,
  arrowDown,
  loginBackground,
  loginImage1,
  loginImage2,
  loginImage3,
  loginImage4,
  trash_24,
  gmeetIcon,
};

export default images;
