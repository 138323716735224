import React, { Suspense } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styles from "./styles/SocialSellingSettingsStyles";
import Loading from "../Components/Loading";
import { Helmet } from "react-helmet";
import { Container, Divider, Grid, Typography } from "@mui/material";
import Page404 from "./Page404";
import Api from "../Services/Api";
import { AxiosResponse } from "axios";
import { withStyles } from "@mui/styles";
import AccountMonitoring from "../Components/AccountMonitoring";
import { Publication } from "../Utils/Types";

const api = Api.create();

type SocialSellingSettingsProps = {
  classes: Record<keyof ReturnType<typeof styles>, string>;
} & RouteComponentProps<{ id: string }>;

type SocialSellingSettingsState = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  publication: Publication | null;
  publicationId: string;
};

class SocialSellingSettings extends React.Component<
  SocialSellingSettingsProps,
  SocialSellingSettingsState
> {
  constructor(props: SocialSellingSettingsProps) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      errorMessage: "",
      publicationId: props.match.params.id || "",
      publication: null
    };
  }

  componentDidUpdate(
    prevProps: Readonly<SocialSellingSettingsProps>,
    prevState: Readonly<SocialSellingSettingsState>
  ): void {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          publicationId: this.props.match.params.id || "",
          publication: null,
          loading: true,
          error: false,
          errorMessage: ""
        },
        () => {
          this.getPublication();
        }
      );
    }
  }

  componentDidMount(): void {
    this.getPublication();
  }

  getPublication = async () => {
    let { publicationId } = this.state;

    if (!publicationId) {
      this.setState({
        error: true,
        errorMessage: "Workspace not found"
      });
      return;
    }

    api.getPublicationById(publicationId, (res: AxiosResponse) => {
      if (res.status === 200) {
        this.setState({
          loading: false,
          publication: res.data.publication
        });
      } else {
        this.setState({
          error: true,
          errorMessage: res.status === 400 ? res.data : "Unauthorized access"
        });
      }
    });
  };

  render() {
    let { loading, error, errorMessage, publication } = this.state;
    let { classes } = this.props;

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Helmet>
          <title>
            Account Monitoring {publication && "- " + publication.name} |
            Letterdrop
          </title>
        </Helmet>

        <Container style={{ paddingTop: 24 }}>
          <Typography variant="h600" paragraph>
            Account Monitoring
          </Typography>

          <div className={classes.innerContainer}>
            <Grid container direction="row" component="div">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AccountMonitoring publication={publication} />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider className={classes.divider} />
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(SocialSellingSettings));
