// @ts-nocheck
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountCircle from "@mui/icons-material/AccountCircle";
import BallotIcon from "@mui/icons-material/Ballot";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SendIcon from "@mui/icons-material/Send";
import { Alert, Avatar, FormControl, Tooltip, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { withStyles } from "@mui/styles";
import firebase from "firebase/app";
import * as React from "react";

import { useHistory } from "react-router-dom";
import { AvatarSize, space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import UpgradeAlert from "../design/components/UpgradeAlert";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import UnstyledLink from "../design/components/UnstyledLink";

import { ReactComponent as ArrowLeft } from "../Images/icon16/arrowleft.svg";

import { ReactComponent as MessageIcon } from "../Images/icon24/message.svg";

import { ReactComponent as NotificationIcon } from "../Images/icon24/notification.svg";
import colors, { designColors } from "../Themes/Colors";
import PageAccessContext from "../Utils/PageAccessContext";
import { setItemInLocalStorage } from "../Utils/StorageHelper";

import AppNotification from "./AppNotification";
import API from "../Services/Api";
import {
  editorTopBarType,
  pageAccess,
  postPublishType,
  postStatus,
  customFieldDataType,
  PROFILE_PIC,
  topicStatus,
  blogDestinations
} from "../Utils/Types";
import useLocalStorage from "../Utils/useLocalStorage";
import { isAccessDisabled, isFreePlan } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";
import styles from "./styles/EditorTopBarStyle";

import StyledSwitch from "../design/components/StyledSwitch";
import { CometChat } from "@cometchat-pro/chat";
import { TranscriptsDialog } from "./TranscriptsDialog";

const api = API.create();
const COMETCHAT_APP_ID = process.env.REACT_APP_COMETCHAT_APP_ID;
const COMETCHAT_AUTH_KEY = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion("us")
  .build();

function EditorTopBar({
  screen,
  publicationId,
  requiresApproval,
  publicationInReview,
  statusLabel,
  status,
  saveLabel,
  publish,
  handleApprovePost,
  openCalenderEvent,
  approvalLabel,
  backLink,
  backLabel = "Back",
  onPreambleAndFooterInsert,
  showReviewBanner = false,
  hideScheduleSelector,
  post,
  changePostToInReview,
  isApprover,
  scheduledDate,
  sendReviewCommentToAuthor,
  schedule,
  onPostScheduleChange,
  onScheduleDateSelect,
  publishTypeSelectRef = null,
  handleUndeletePost,
  classes,
  suggestionMode,
  toggleSuggestionMode,
  isWebflowPreviewLoaderVisible,
  topicId,
  contentStages,

  // The stage before approval stage,
  lastApprovalStage,
  previewPost,
  isNewsletterSettingsSelected,
  isCommentOnlyAccess,
  isBlogPostSettingsSelected,
  disableScheduleButton,
  openPostShare
}: any) {
  // lastApprovalStage: Default IN_REVIEW is the last approval stage and in this stage only we allow to publish the post
  // Now if use have custom stages after IN_REVIEW, then in this case we need to find that stage, In this stage only we should allow to publish the post, not in IN_REVIEW

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [showAppNotification, setShowAppNotification] = React.useState(false);
  const [appNotificationElement, setAppNotificationElement] =
    React.useState(null);
  const [cometChatUnreadMessages, setCometChatUnreadMessages] = React.useState(
    []
  );
  const [profilePic, setProfilePic] = useLocalStorage("profile_pic", "");
  const [isTranscriptOpen, setIsTranscriptOpen] =
    React.useState<boolean>(false);
  const [transcriptDetails, setTranscriptDetails] = React.useState<{
    type: string;
    url: string;
  }>({});
  const history = useHistory();
  const isUserLoggedIn = firebase.auth().currentUser ? true : false;
  let context = React.useContext(PageAccessContext);
  let haveAccess = !isFreePlan(context, publicationId);

  React.useEffect(() => {
    updateNotificationCount();
  }, []);

  async function updateNotificationCount() {
    api.getAppNotificationCount(publicationId, async (res: any) => {
      if (res.status === 200) {
        setCount(res.data.count);
        await getUnreadMessages(
          res.data.cometchatId,
          res.data.teammates,
          res.data.count
        );
      }
    });
  }
  async function getUnreadMessages(
    cometchatId: any,
    teammates: any,
    notificationCount: any
  ) {
    let unreadCount = 0;

    let unreadChats = [];
    try {
      if (!cometchatId) {
        return;
      }

      if (!CometChat.isInitialized()) {
        CometChat.init(COMETCHAT_APP_ID, appSetting).then(
          () => {},
          (error) => {
            return;
          }
        );
      }
      try {
        await CometChat.login(cometchatId, COMETCHAT_AUTH_KEY);
      } catch (err) {
        return;
      }

      // get all publication client's cometchat ids
      let clients = new Set();
      teammates.map(async (teammate: any) => {
        if (teammate?.client?.cometchatId) {
          clients.add(teammate.client);
        }
      });

      // get unread messages from each teammate
      for (let client of clients) {
        let messages = await CometChat.getUnreadMessageCountForUser(
          client.cometchatId
        );

        if (messages[`${client.cometchatId}`] > 0) {
          unreadChats.push({
            count: messages[client.cometchatId],

            name: client.name
          });
          unreadCount++;
        }
      }

      setCometChatUnreadMessages(unreadChats);
    } catch (error) {}
    setCount(notificationCount + unreadCount);
  }

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const closeAppNotification = () => {
    setAppNotificationElement(null);
    setShowAppNotification(false);
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.clear();
        setItemInLocalStorage(PROFILE_PIC, "");
        history.push("/login");
      });
  };

  function goToProject() {
    if (!post.topicId?._id) {
      notify.show("Project not found", "error");
      return;
    }
    history.push(`/${publicationId}/projects/${post.topicId._id}`);
  }

  // Get topic stage name
  function getStageName() {
    // contentStages will be like [[{name: "Draft", internalContentStageName: "DRAFT"}], [{name: "Review", internalContentStageName: "REVIEW"}]
    // Flat the array to get [{name: "Draft", internalContentStageName: "DRAFT"}, {name: "Review", internalContentStageName: "REVIEW"}]
    let stages = post.publication?.contentStages
      ?.map((eachStage: any) => eachStage.stages)
      .flat();

    // Find the name
    return (
      stages?.find(
        (eachStage: any) =>
          eachStage.internalContentStageName === post.topicId?.status
      )?.name || ""
    );
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "10px"
        },
        "& .MuiMenu-list": { padding: "10px !important" },
        "& .MuiMenuItem-root.Mui-focusVisible": {
          backgroundColor: "transparent"
        },
        "& .MuiMenuItem-root": {
          "&:hover": {
            backgroundColor: colors.buttonHoverBG
          }
        }
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem selected={false}>
        <UnstyledLink to={"/account"}>
          <AccountCircle
            style={{
              color: grey[700],
              height: "18px",
              margin: "auto 0px",
              marginRight: "7px"
            }}
          />

          <Typography>My Account</Typography>
        </UnstyledLink>
      </StyledMenuItem>

      <StyledMenuItem onClick={logout} selected={false}>
        <ExitToAppIcon
          style={{
            color: grey[700],
            height: "18px",
            margin: "auto 0px",
            marginRight: "7px"
          }}
        />

        <Typography>Logout</Typography>
      </StyledMenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <StyledMenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MessageIcon />
          </Badge>
        </IconButton>

        <p>Messages</p>
      </StyledMenuItem>

      <StyledMenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationIcon />
          </Badge>
        </IconButton>

        <p>Notifications</p>
      </StyledMenuItem>

      <StyledMenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar src={profilePic} />
        </IconButton>

        <p>Profile</p>
      </StyledMenuItem>
    </Menu>
  );

  function getActionButtons() {
    if (!haveAccess) {
      return (
        <StyledButton size="small" onClick={openPostShare}>
          Share
        </StyledButton>
      );
    }

    if (publicationInReview) {
      return (
        <Typography
          variant="bodys"
          style={{ color: designColors.grayScale[80] }}
        >
          You can publish once your account is approved
        </Typography>
      );
    }

    if (status === postStatus.DELETED) {
      return (
        <StyledButton
          onClick={handleUndeletePost}
          className={classes.nextButton}
        >
          Undelete
        </StyledButton>
      );
    }
    if (
      !isAccessDisabled(publicationId, context, pageAccess.PUBLISH_POST) &&
      topicId?.status === lastApprovalStage &&
      isApprover &&
      requiresApproval
    ) {
      return (
        <StyledButton
          onClick={handleApprovePost}
          className={classes.nextButton}
        >
          Review and approve
        </StyledButton>
      );
    }
    if (
      !isAccessDisabled(publicationId, context, pageAccess.PUBLISH_POST) &&
      !topicId
    ) {
      return (
        <StyledButton
          size="small"
          className={classes.nextButton}
          onClick={publish}
        >
          Next: metadata and scheduling
        </StyledButton>
      );
    }
    if (
      !isAccessDisabled(
        publicationId,
        context,
        pageAccess.SUBMIT_FOR_POST_APPROVAl
      ) &&
      topicId
    ) {
      return (
        <StyledButton
          size="small"
          className={classes.nextButton}
          onClick={publish}
        >
          Review and submit
        </StyledButton>
      );
    }

    return (
      <Typography variant="bodys" style={{ color: designColors.grayScale[80] }}>
        Only admins can publish this post
      </Typography>
    );
  }

  function postScreen() {
    return (
      <>
        <Box className={classes.leftSection}>
          {backLink && (
            <UnstyledLink to={backLink}>
              <StyledButton
                size="small"
                variant="tertiary"
                startIcon={<ArrowLeft />}
                className={classes.backButton}
              >
                {backLabel}
              </StyledButton>
            </UnstyledLink>
          )}

          {statusLabel && (
            <Typography
              variant="bodys"
              style={{
                color: designColors.grayScale[80],
                marginRight: 16
              }}
            >
              {statusLabel}
            </Typography>
          )}
          {approvalLabel && (
            <Typography
              variant="bodys"
              className={classes.approvalLabel}
              onClick={openCalenderEvent}
            >
              {approvalLabel}
            </Typography>
          )}
          {saveLabel && (
            <Typography variant="bodys" className={classes.saveLabel}>
              {saveLabel}
            </Typography>
          )}
        </Box>

        <Box className={classes.rightSection}>
          <Box>
            <StyledSwitch
              checked={suggestionMode}
              onChange={toggleSuggestionMode}
              disabled={!isUserLoggedIn && isCommentOnlyAccess}
              label="Suggesting"
              labelStyle={{ marginRight: 0, color: colors.black }}
            />
          </Box>
          {renderAppNotification()}
          {renderAvatar()}

          <Box style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.verticalLine} />
            {getActionButtons()}
          </Box>
        </Box>
      </>
    );
  }

  function preambleAndFooterScreen() {
    return (
      <>
        <Box className={classes.leftSection}>
          {backLink && (
            <UnstyledLink to={backLink}>
              <StyledButton
                size="small"
                variant="tertiary"
                startIcon={<ArrowLeft />}
                className={classes.backButton}
              >
                {backLabel}
              </StyledButton>
            </UnstyledLink>
          )}
          {saveLabel && (
            <Typography variant="bodys" className={classes.saveLabel}>
              {saveLabel}
            </Typography>
          )}
        </Box>

        <Box className={classes.rightSection}>
          {renderAvatar()}

          <Box style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.verticalLine} />

            <StyledButton
              className={classes.nextButton}
              onClick={onPreambleAndFooterInsert}
              size="small"
            >
              Insert
            </StyledButton>
          </Box>
        </Box>
      </>
    );
  }

  function isCustomStage(status: any) {
    return ![
      topicStatus.CONTENT_MAP_DRAFT,
      topicStatus.CONTENT_MAP_IN_REVIEW,
      topicStatus.FIRST_DRAFT,
      topicStatus.IN_REVIEW,
      topicStatus.SCHEDULED,
      topicStatus.PUBLISHED
    ].includes(status);
  }

  // Check if next stage is in review
  function isNextStageIsInReview(currentStage: any) {
    try {
      if (!contentStages?.length) {
        return true;
      }

      let currentStageIndex = contentStages.findIndex(
        (stage: any) => stage.internalContentStageName === currentStage
      );

      if (!contentStages[currentStageIndex + 1]?.internalContentStageName) {
        return true;
      }

      // Next stage with deadline and assigned to
      let nextValidStage = topicStatus.IN_REVIEW;

      for (
        let stageIndex = currentStageIndex + 1;
        stageIndex < contentStages.length;
        stageIndex++
      ) {
        if (
          contentStages[stageIndex]?.deadline &&
          contentStages[stageIndex]?.assignedTo?.length
        ) {
          nextValidStage = contentStages[stageIndex].internalContentStageName;
          break;
        }
      }

      return nextValidStage === topicStatus.IN_REVIEW;
    } catch (error) {
      console.log(error);
    }
    return true;
  }

  function getLastStageActionButtonName(
    isPostUpdating: any,
    scheduledDate: any
  ) {
    if (isPostUpdating) {
      return "Update post";
    }
    return scheduledDate
      ? post.status === postStatus.PUBLISHED
        ? "Change Schedule"
        : "Schedule"
      : "Publish";
  }

  function postReviewScreen() {
    // postApprovedNotScheduled - Topic related post is approved but without scheduled date and is not published yet
    let postApprovedNotScheduled =
      post.status === postStatus.PUBLISHED &&
      !post.publishedOn &&
      !scheduledDate;
    //Post is in draft status and not yet scheduled
    let draftNotScheduled = post.status === postStatus.DRAFT && !scheduledDate;
    let publishButtonLabel = "";
    let canModify = true;
    if (post?.topicId?.status === lastApprovalStage && !isApprover) {
      canModify = false;
    }

    let previewButtonLabel = `Preview ${
      isNewsletterSettingsSelected && "post"
    }`;

    function handlePreviewButtonClick(event: any) {
      if (isBlogPostSettingsSelected) {
        previewPost?.(event);
      }
    }

    function isPreviewButtonDisable() {
      // temp fix
      // if (isNewsletterSettingsSelected) {
      //   return showReviewBanner && isNewsletterSettingsSelected;
      // } else if (process.env.REACT_APP_STAGING === "true") {
      //   return false;
      // } else {
      //   return true;
      // }

      let doNotPublishWebflowSite = Boolean(
        post.publication?.doNotPublishWebflowSite &&
          (post?.destination?.type === blogDestinations.WEBFLOW ||
            (post.publication?.externalSite?.blogs?.length === 1 &&
              post.publication.externalSite.blogs[0].destination ===
                blogDestinations.WEBFLOW))
      );

      return (
        doNotPublishWebflowSite ||
        (isWebflowPreviewLoaderVisible && isBlogPostSettingsSelected) ||
        (showReviewBanner && isNewsletterSettingsSelected)
      );
    }

    let stageName = getStageName();

    if (postApprovedNotScheduled || draftNotScheduled) {
      publishButtonLabel = "Publish";
    } else if (scheduledDate) {
      if (post.status === postStatus.PUBLISHED) {
        publishButtonLabel = "Change Schedule";
      } else {
        publishButtonLabel = "Schedule";
      }
    } else if (post.publishedOn) {
      publishButtonLabel = "Update post";
    } else {
      publishButtonLabel = "Schedule";
    }

    if (post.requiresApproval) {
      let isTopicPublishedOrApproved = [
        topicStatus.PUBLISHED,
        topicStatus.SCHEDULED
      ].includes(post.topicId?.status);

      if (
        !isTopicPublishedOrApproved &&
        lastApprovalStage !== post.topicId?.status
      ) {
        return (
          <>
            <Box className={classes.leftSection}>
              {backLink && (
                <UnstyledLink to={backLink}>
                  <StyledButton
                    size="small"
                    variant="tertiary"
                    startIcon={<ArrowLeft />}
                    className={classes.backButton}
                  >
                    {backLabel}
                  </StyledButton>
                </UnstyledLink>
              )}
            </Box>

            <Box className={classes.rightSection}>
              {renderAvatar()}
              {haveAccess &&
                (isBlogPostSettingsSelected ||
                  isNewsletterSettingsSelected) && (
                  <StyledButton
                    onClick={handlePreviewButtonClick}
                    className={classes.marginLeft10}
                    disabled={isPreviewButtonDisable()}
                    variant="secondary"
                  >
                    {previewButtonLabel}
                  </StyledButton>
                )}
              {isUserLoggedIn &&
                (haveAccess ? (
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.verticalLine} />

                    <Typography
                      variant="bodym"
                      className={classes.reviewHeaderText}
                    >
                      In {<b>{stageName}</b>}
                    </Typography>

                    <StyledButton onClick={changePostToInReview}>
                      Submit for review
                    </StyledButton>
                  </Box>
                ) : (
                  <UpgradeAlert />
                ))}
            </Box>
          </>
        );
      }

      // Show "Approve" button if topic is in review status or last approval stage
      // and user is approver
      if (
        (post.topicId?.status === topicStatus.IN_REVIEW ||
          post.topicId?.status === lastApprovalStage) &&
        isApprover
      ) {
        return (
          <>
            <Box className={classes.leftSection}>
              {backLink && (
                <UnstyledLink to={backLink}>
                  <StyledButton
                    size="small"
                    variant="tertiary"
                    startIcon={<ArrowLeft />}
                    className={classes.backButton}
                  >
                    {backLabel}
                  </StyledButton>
                </UnstyledLink>
              )}
            </Box>

            <Box className={classes.rightSection}>
              {renderAvatar()}

              <Box style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.verticalLine} />
                {getScheduleSelector(true)}
                {haveAccess &&
                  (isBlogPostSettingsSelected ||
                    isNewsletterSettingsSelected) && (
                    <StyledButton
                      onClick={handlePreviewButtonClick}
                      className={classes.marginLeft10}
                      disabled={isPreviewButtonDisable()}
                      variant="secondary"
                    >
                      {previewButtonLabel}
                    </StyledButton>
                  )}
                {isUserLoggedIn &&
                  (haveAccess ? (
                    <>
                      <Typography
                        variant="bodym"
                        className={classes.reviewHeaderText}
                        style={{ marginLeft: space.MEDIUM }}
                      >
                        In {<b>{stageName}</b>}
                      </Typography>

                      <StyledButton
                        variant="textprimary"
                        onClick={sendReviewCommentToAuthor}
                      >
                        Request change
                      </StyledButton>

                      <StyledButton
                        className={classes.marginLeft10}
                        onClick={() => {
                          schedule(true);
                        }}
                        startIcon={<SendIcon />}
                      >
                        {getLastStageActionButtonName(
                          post?.isPostUpdating,
                          scheduledDate
                        )}
                      </StyledButton>
                    </>
                  ) : (
                    <UpgradeAlert />
                  ))}
              </Box>
            </Box>
          </>
        );
      }

      if (
        (post.status === postStatus.DRAFT && isApprover) ||
        (post?.topicId?.status === topicStatus.PUBLISHED &&
          !isAccessDisabled(publicationId, context, pageAccess.PUBLISH_POST))
      ) {
        return (
          <>
            <Box className={classes.leftSection}>
              {backLink && (
                <UnstyledLink to={backLink}>
                  <StyledButton
                    size="small"
                    variant="tertiary"
                    startIcon={<ArrowLeft />}
                    className={classes.backButton}
                  >
                    {backLabel}
                  </StyledButton>
                </UnstyledLink>
              )}
            </Box>

            <Box className={classes.rightSection}>
              {renderAvatar()}

              <Box style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.verticalLine} />
                {!hideScheduleSelector && (
                  <>
                    {getScheduleSelector(
                      post?.topicId?.status !== topicStatus.PUBLISHED ||
                        post.scheduledOn
                    )}
                  </>
                )}
                {haveAccess &&
                  (isBlogPostSettingsSelected ||
                    isNewsletterSettingsSelected) && (
                    <StyledButton
                      onClick={handlePreviewButtonClick}
                      className={classes.marginLeft10}
                      disabled={isPreviewButtonDisable()}
                      variant="secondary"
                    >
                      {previewButtonLabel}
                    </StyledButton>
                  )}
                {isUserLoggedIn &&
                  (haveAccess ? (
                    <StyledButton
                      onClick={publish}
                      disabled={isWebflowPreviewLoaderVisible}
                      className={classes.marginLeft10}
                      startIcon={<SendIcon />}
                    >
                      {publishButtonLabel}
                    </StyledButton>
                  ) : (
                    <UpgradeAlert />
                  ))}
              </Box>
            </Box>
          </>
        );
      }
    }

    if (!isAccessDisabled(publicationId, context, pageAccess.PUBLISH_POST)) {
      return (
        <>
          <Box className={classes.leftSection}>
            {backLink && (
              <UnstyledLink to={backLink}>
                <StyledButton
                  size="small"
                  variant="tertiary"
                  startIcon={<ArrowLeft />}
                  className={classes.backButton}
                >
                  {backLabel}
                </StyledButton>
              </UnstyledLink>
            )}
          </Box>

          <Box className={classes.rightSection}>
            {renderAvatar()}

            <Box style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.verticalLine} />
              {!hideScheduleSelector && canModify && (
                <>
                  {getScheduleSelector(
                    post.status !== postStatus.PUBLISHED || post.scheduledOn
                  )}
                </>
              )}
              {haveAccess &&
                (isBlogPostSettingsSelected ||
                  isNewsletterSettingsSelected) && (
                  <StyledButton
                    onClick={handlePreviewButtonClick}
                    className={classes.marginLeft10}
                    disabled={isPreviewButtonDisable()}
                    variant="secondary"
                  >
                    {previewButtonLabel}
                  </StyledButton>
                )}
              {isUserLoggedIn &&
                (haveAccess ? (
                  <div>
                    {!canModify ? (
                      <div>
                        <Typography
                          variant="bodym"
                          className={`${classes.reviewHeaderText} ${classes.marginLeft10}`}
                        >
                          In {<b>{stageName}</b>}
                        </Typography>

                        <StyledButton
                          onClick={goToProject}
                          className={classes.marginLeft10}
                        >
                          Check status
                        </StyledButton>
                      </div>
                    ) : (
                      <StyledButton
                        onClick={publish}
                        disabled={
                          isWebflowPreviewLoaderVisible || disableScheduleButton
                        }
                        className={classes.marginLeft10}
                        startIcon={<SendIcon />}
                      >
                        {publishButtonLabel}
                      </StyledButton>
                    )}
                  </div>
                ) : (
                  <UpgradeAlert />
                ))}
            </Box>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box className={classes.leftSection}>
          {backLink && (
            <UnstyledLink to={backLink}>
              <StyledButton
                size="small"
                variant="tertiary"
                startIcon={<ArrowLeft />}
                className={classes.backButton}
              >
                {backLabel}
              </StyledButton>
            </UnstyledLink>
          )}
        </Box>

        <Box className={classes.rightSection}>
          {renderAvatar()}

          <Box style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.verticalLine} />
          </Box>
        </Box>
      </>
    );
  }

  function getScheduleSelector(showLater: any) {
    return (
      <FormControl
        variant="outlined"
        size="small"
        style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
      >
        <AccessTimeIcon
          style={{ color: designColors.grayScale[80], marginRight: 8 }}
        />

        <StyledSelect
          ref={publishTypeSelectRef}
          size="medium"
          value={scheduledDate ? postPublishType.LATER : postPublishType.NOW}
          onChange={onPostScheduleChange}
        >
          <StyledMenuItem value={postPublishType.NOW}>now</StyledMenuItem>
          {showLater && (
            <StyledMenuItem
              value={postPublishType.LATER}
              onClick={onScheduleDateSelect}
            >
              {scheduledDate ? scheduledDate : "later"}
            </StyledMenuItem>
          )}
        </StyledSelect>
      </FormControl>
    );
  }

  function blogGenerator() {
    return (
      <>
        <Box className={classes.leftSection} />

        <Box className={classes.rightSection}>{renderAvatar()}</Box>
      </>
    );
  }

  function renderAvatar() {
    if (!isUserLoggedIn) {
      return <div />;
    }

    return (
      <Avatar
        sx={{ ...AvatarSize.sm }}
        style={{ cursor: "pointer" }}
        src={profilePic}
        onClick={handleProfileMenuOpen}
      />
    );
  }

  function renderAppNotification() {
    return (
      <Tooltip
        title={count > 0 ? "You have unread notifications" : "Notifications"}
      >
        <IconButton
          size="large"
          style={{ marginRight: firebase.auth().currentUser ? "16px" : 0 }}
          onClick={(event) => {
            setAppNotificationElement(event.currentTarget);
            setShowAppNotification(true);
          }}
        >
          <Badge badgeContent={count} color="error">
            <NotificationIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    );
  }

  function openTranscript(type: string, url: string) {
    setTranscriptDetails({ type, url });
    setIsTranscriptOpen(true);
  }

  function closeTranscript() {
    setTranscriptDetails({});
    setIsTranscriptOpen(false);
  }

  return (
    <Box style={{ width: "100%" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FCFCFC",
          boxShadow: "inset 1px 0px 0px #F4F4F4"
        }}
      >
        {showReviewBanner && (
          <Box item className={classes.paddingBottom10}>
            <Alert severity="warning">
              You imported a lot of emails! We'll take a look and approve your
              account within 8 hours. We do this to prevent spammers from
              hurting your email deliverability.
            </Alert>
          </Box>
        )}

        <Toolbar
          sx={{
            "&.MuiToolbar-root": {
              margin: "0px 40px",
              padding: 0
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {screen === editorTopBarType.POST && postScreen()}
          {screen === editorTopBarType.PREAMBLE_AND_FOOTER &&
            preambleAndFooterScreen()}

          {screen === editorTopBarType.POST_REVIEW && postReviewScreen()}
          {screen === editorTopBarType.BLOG_GENERATOR && blogGenerator()}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {showAppNotification && (
        <AppNotification
          messages={cometChatUnreadMessages}
          showAppNotification={showAppNotification}
          setShowAppNotification={setShowAppNotification}
          publicationId={publicationId}
          appNotificationElement={appNotificationElement}
          closeAppNotification={closeAppNotification}
          setCount={setCount}
          updateNotificationCount={updateNotificationCount}
          openTranscript={openTranscript}
        />
      )}
      {isTranscriptOpen && (
        <TranscriptsDialog
          type={transcriptDetails.type}
          url={transcriptDetails.url}
          onClose={closeTranscript}
        />
      )}
    </Box>
  );
}

export default withStyles(styles)(EditorTopBar);
